import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ChangeInitial } from "../../redux/Main/action";
import MaximizedEvents from '../Events/MaximizedEvents';
import { useParams } from 'react-router-dom';

const { RangePicker } = DatePicker;
const MaximizedGivingCentral = () => {

    const dispatch = useDispatch();
    let { dynamicSegment, activeTab, dropdown, dropdownValue } = useParams();
    const [firstDate, secondDate] = dropdownValue.split('_');

    const [dates, setDates] = useState({
        startDate: dayjs(firstDate),
        endDate: dayjs(secondDate)
    })
    const [datePopup, setDatePopup] = useState(true);
    const [isSelect, setIsSelect] = useState(false);
    const [btnActive, setBtnActive] = useState(activeTab);
    const [isDropDown, setIsDropDown] = useState(dropdown);
    const [showComponent, setShowComponent] = useState("");
    const [isDateToggle, setIsDateToggle] = useState(false);
    const dropdownRef = useRef(null);
    const [date, setDate] = useState({
        startDate: '',
        endDate: ''
    })

    useEffect(() => {
        setDates({
            startDate: dayjs(firstDate),
            endDate: dayjs(secondDate)
        })
        setShowComponent(dynamicSegment);
    }, []);

    useEffect(() => {
        dispatch(ChangeInitial("Giving Central"));
    }, [dispatch]);

    useEffect(() => {
        if (activeTab === "custom-date" && dropdown === "Select") {
            setDate({
                startDate: dayjs(firstDate),
                endDate: dayjs(secondDate)
            })
        }
    }, [dropdown, dropdownValue]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            var target = event.target;
            var className = target.classList[0];
            if (className !== "ant-picker-cell-inner" && className !== "date-range-btn" && className !== undefined && className !== "ant-picker-header-prev-btn"
                && className !== "ant-picker-super-prev-icon" && className !== "ant-picker-header-next-btn" && className !== "ant-picker-header-super-next-btn"
                && className !== "ant-picker-prev-icon" && className !== "ant-picker-header-super-prev-btn" && className !== "ant-picker-next-icon"
                && className !== "ant-picker-month-btn" && className !== "ant-picker-year-btn" && className !== "ant-picker-super-next-icon" && className !== "ant-picker"
            ) {
                setIsDateToggle(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [datePopup]);

    const handleDateChange = (date, dateString) => {
        if (dateString && dateString[0] && dateString[1]) {
            setIsSelect(true);
            setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) });
        }
    }

    const handle = (val) => {
        setDatePopup(val);
    }

    const handleDropdownChange = (interval, startDates, endDates) => {
        let startDate, endDate;
        switch (interval) {
            case "Select":
                startDate = startDates;
                endDate = endDates;
                setDate({ startDate: startDates, endDate: endDates });
                break;
            case "Last 7 days":
                startDate = dayjs().subtract(7, 'day');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "Last 15 days":
                startDate = dayjs().subtract(15, 'day');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "Current Month":
                startDate = dayjs().startOf('month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "1 Month":
                startDate = dayjs().subtract(1, 'month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "3 Month":
                startDate = dayjs().subtract(3, 'month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "6 Month":
                startDate = dayjs().subtract(6, 'month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "9 Month":
                startDate = dayjs().subtract(9, 'month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "1 Year":
                startDate = dayjs().subtract(1, 'year');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            default:
                startDate = '';
                endDate = '';
        }
        setDates({ startDate, endDate });
        setIsDropDown(interval);
    };

    return (
        <div className='giving-central'>
            <div className="col-sm-12 col-md-12" style={{ padding: "0px 0px 20px 0px", fontSize: "xx-large" }}>
                {showComponent === "onetime_donation" &&
                    <strong className="card-title">One Time Donations</strong>
                }
                {showComponent === "recurring_donation" &&
                    <strong className="card-title">Recurring Donations</strong>
                }
                {showComponent === "abandoned_cart" &&
                    <strong className="card-title">Abandoned Gift Explorer</strong>
                }
                {showComponent === "average_donation_amount" &&
                    <strong className="card-title">Average Donation Amount</strong>
                }
                {showComponent === "channels_performance" &&
                    <strong className="card-title">Donation Channel Performance</strong>
                }
                {showComponent === "onetime_vs_recurring" &&
                    <strong className="card-title">One Time vs New Subscriptions</strong>
                }
                {showComponent === "donation_source" &&
                    <strong className="card-title">Donation Distribution by Source </strong>
                }
                {showComponent === "campaign_performance" &&
                    <strong className="card-title">Donation Campaign Performance</strong>
                }
                {showComponent === "frequency_distribution" &&
                    <strong className="card-title">Donation Frequency Distribution</strong>
                }
                {showComponent === "total_donation" &&
                    <strong className="card-title">Total Donations Over Time</strong>
                }
                {showComponent === "average_chart" &&
                    <strong className="card-title">Average Donation Amount with Trend Line</strong>
                }
                {showComponent === "donation_city" &&
                    <strong className="card-title">Donation by City & State</strong>
                }
                {showComponent === "distribution" &&
                    <strong className="card-title">Distribution Table</strong>
                }
            </div>
            <div className='dashboard dynamic-card' style={{ paddingTop: '50px' }}>
                {dropdown !== "distribution_table" &&
                    <div className='date-range-picker card'>
                        <div>
                            <div className={`btn-group ${btnActive === 'days' && 'btn-today'}`}>
                                <button type="button" className={`btn btn-primary date-range-btn dropdown-toggle ${btnActive === 'days' && 'btn-today'}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    onClick={() => { setBtnActive('days'); handleDropdownChange(isDropDown, date.startDate, date.endDate); }}>
                                    {isDropDown} &nbsp; &nbsp;
                                    <i className="material-icons">keyboard_arrow_down</i>
                                </button>
                                <div className="dropdown-menu">
                                    <div className={`dropdown-list ${isDropDown === "Select" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange(isDropDown)}
                                    >&nbsp; &nbsp; Select
                                    </div>
                                    <div className={`dropdown-list ${isDropDown === "Last 7 days" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange("Last 7 days")}
                                    >&nbsp; &nbsp; Last 7 days
                                    </div>
                                    <div className={`dropdown-list ${isDropDown === "Last 15 days" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange("Last 15 days")}
                                    >&nbsp; &nbsp; Last 15 days
                                    </div>
                                    <div className={`dropdown-list ${isDropDown === "Current Month" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange("Current Month")}
                                    >&nbsp; &nbsp; Current Month
                                    </div>
                                    <div className={`dropdown-list ${isDropDown === "1 Month" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange("1 Month")}
                                    >&nbsp; &nbsp; 1 Month
                                    </div>
                                    <div className={`dropdown-list ${isDropDown === "3 Month" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange("3 Month")}
                                    >&nbsp; &nbsp; 3 Month
                                    </div>
                                    <div className={`dropdown-list ${isDropDown === "6 Month" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange("6 Month")}
                                    >&nbsp; &nbsp; 6 Month</div>
                                    <div className={`dropdown-list ${isDropDown === "9 Month" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange("9 Month")}
                                    >&nbsp; &nbsp; 9 Month
                                    </div>
                                    <div className={`dropdown-list ${isDropDown === "1 Year" && `dropdown-list-active`}`}
                                        onClick={() => handleDropdownChange("1 Year")}
                                    >&nbsp; &nbsp; 1 Year
                                    </div>
                                </div>
                            </div>
                            <div className={`btn-group ${btnActive === 'custom-date' && 'btn-today'} ${isDateToggle && 'open'}`} ref={dropdownRef}>
                                <button type="button" className={`btn btn-primary date-range-btn dropdown-toggle ${btnActive === 'custom-date' && 'btn-today'}`}
                                    onClick={() => { setBtnActive('custom-date'); setIsDateToggle(!isDateToggle); }}
                                >
                                    Select Custom Range &nbsp; &nbsp;
                                    <i className="material-icons">keyboard_arrow_down</i>
                                </button>
                                <div className="dropdown-menu">
                                    <div style={{ width: '260px', padding: '5px 10px' }}>
                                        <RangePicker
                                            value={[date.startDate, date.endDate]}
                                            onChange={handleDateChange}
                                            onOpenChange={handle}
                                            allowClear={true}
                                            size={"middle"}
                                            bordered={"false"}
                                            format={"YYYY-MM-DD"}
                                            separator={<i className="material-icons">arrow_right_alt</i>}
                                            disabledDate={(current) => current && current < dayjs().subtract(1, 'year') || current > dayjs()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='date-display d-sm-block d-md-flex'>Data displayed for {btnActive === 'custom-date' ? 'Custom Range' : isDropDown}: <div className='date'>&nbsp;{dayjs(dates.startDate).format('MMMM DD YYYY')} - {dayjs(dates.endDate).format('MMMM DD YYYY')}</div></div>
                        </div>
                    </div>
                }
                <div className='GiveEvents'>
                    <MaximizedEvents isDropDown={isDropDown} dates={dates} isSelect={isSelect} setIsSelect={setIsSelect} datePopup={datePopup} show={showComponent} btnActive={btnActive} setIsDateToggle={setIsDateToggle} handleDropdownChange={handleDropdownChange} />
                </div>
            </div>
        </div>
    )
}

export default MaximizedGivingCentral
