import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useFilters, useResizeColumns, useSortBy, useFlexLayout } from 'react-table';
import GivingCentralPageResizer from './GivingCentralPageResizer';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

let tempObj = [];

const TextFilter = ({ column, filterOnChange, resetFilter }) => {
    const [filterNew, setFilterNew] = useState('');

    useEffect(() => {
        if (resetFilter) {
            tempObj = [];
            setFilterNew('');
        }
    }, [resetFilter]);

    const debounceFilterOnChange = debounce(filterOnChange, 200);

    const onChange = (value, id) => {
        setFilterNew(value);

        tempObj = [{ "id": '', "value": value }];
        debounceFilterOnChange(1, tempObj)
    };

    return (
        <input
            type="search"
            className='search_data'
            value={filterNew}
            placeholder="Search Table.."
            onChange={(event) => { onChange(event.target.value, '') }}
            style={{ width: '100%' }}
        />
    );
}


const GivingCentralReactTable = ({
    data,
    columns,
    isLoading,
    setIsLoading,
    inputOnChange,
    filterOnChange,
    filteredData,
    hiddenColumns = [],
    nextPageEvent = "",
    previousPageEvent = "",
    setPageNumberCount,
    defaultPageSize = 5,
    tablePages = 0,
    pageNumberCount = 0,
    showPaginationOnBottom = false,
    showFilter = true,
    resetFilter = false,
    urlSegment,
    downloadData,
    btnActive,
    isDropDown,
    dates
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageSize },
        previousPage,
        nextPage,
        setPageSize,
        gotoPage
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: defaultPageSize, hiddenColumns: hiddenColumns },
        },
        useFilters,
        useResizeColumns,
        useSortBy,
        usePagination,
        useFlexLayout
    );

    let bodyWidth = 0;

    if (headerGroups.length) {
        bodyWidth = headerGroups.map((headerGroup) => {
            bodyWidth = headerGroup.headers.reduce((total, item) => total + (item.width || 0), 0);
            return bodyWidth;
        })
    }

    return (
        <div id='pages-subPages-table'>
            <div className='ReactTable -highlight'>
                <div className='card-icons'>
                    {!urlSegment &&
                        <div className="search-wrapper">
                            <i className="fa fa-search search-icon"></i>
                            <TextFilter
                                filterOnChange={filterOnChange}
                                setIsLoading={setIsLoading}
                                filtered={filteredData}
                                resetFilter={resetFilter}
                            />
                        </div>
                    }
                    <div className='zoom-icon'>
                        <Tooltip title="Maximize">
                            {urlSegment && <a href={`/admin/give-central/maximized/${urlSegment}/${btnActive}/${isDropDown}/${dayjs(dates?.startDate).format('YYYY-MM-DD')}_${dayjs(dates?.endDate).format('YYYY-MM-DD')}`} target='_blank' style={{ color: "#666666" }}><i className="material-icons">zoom_out_map</i></a>}
                        </Tooltip>
                    </div>
                    <div className='zoom-icon'>
                        <Tooltip title="Download">
                            <i className="material-icons" title='Download Data' style={{ width: "35px" }} onClick={() => downloadData()}>download_2</i>
                        </Tooltip>
                    </div>
                </div>
                <table {...getTableProps()} className='rt-table' role='grid' style={{ display: 'flex', flexDirection: 'column' }}>
                    <thead className='rt-thead -header' style={{ minWidth: `${bodyWidth[1]}px`, overflow: "hidden", minWidth: "1024px" }}>
                        {headerGroups.map((headerGroup) => (
                            <tr className='rt-tr' role='row' {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex' }}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        key={index}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={`rt-th rt-resizable-header -cursor-pointer ${column.isSorted ? (column.isSortedDesc ? '-sort-desc' : '-sort-asc') : ''}`}
                                        role='columnheader'
                                        {...column.cellProps}
                                        style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0, overflow: "hidden", backgroundColor: '#f9f9f9', color: '#000', padding: "1rem" }}
                                    >
                                        <div className='rt-resizable-header-content'>
                                            <div
                                                {...column.getResizerProps()}
                                                className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                            />
                                            <span>{column.render('Header')}</span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className='rt-tbody' style={{ minWidth: `${bodyWidth[1]}px`, overflow: "hidden", minWidth: "1024px" }}>
                        {page.length > 0 && page.map((row, index) => {
                            prepareRow(row);
                            let className = index % 2 === 0 ? '-odd' : '';
                            return (
                                <tr key={index} {...row.getRowProps()} className={`rt-tr ${className}`} style={{ display: 'flex', padding: "1rem 0px" }}>
                                    {row.cells.map((cell, ind) => (
                                        <td key={ind} className='rt-td' style={{ flexBasis: `${cell.column.width}px`, flexGrow: 1, flexShrink: 0 }}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                        {page.length <= 0 && [1, 2, 3].map((row, index) => {
                            let className = index % 2 === 0 ? '-odd' : '';
                            return (
                                <tr key={index} className={`rt-tr ${className}`} style={{ display: 'flex', padding: "1rem 0px" }}>
                                    {headerGroups[0].headers.map((column, index) => (
                                        <td key={index} className='rt-td' style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0 }}>
                                            <span className='noColourText'>NA</span>
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {page.length <= 0 && <div className="rt-noData" style={showFilter ? { margin: "3em" } : { margin: "2em" }} >No data available</div>}

                {isLoading && <div className="-loading -active"><div className="-loading-inner">Loading...</div></div>}

                {showPaginationOnBottom && (
                    <GivingCentralPageResizer
                        previousPage={previousPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        nextPage={nextPage}
                        defaultPageSize={pageSize}
                        gotoPage={gotoPage}
                        pageSizeOptions={false}
                        nextPageEvent={nextPageEvent}
                        previousPageEvent={previousPageEvent}
                        tablePages={tablePages}
                        pageNumberCount={pageNumberCount}
                        setPageNumberCount={setPageNumberCount}
                        inputOnChange={inputOnChange}
                    />
                )
                }
            </div>
        </div>
    )
}

export default GivingCentralReactTable;