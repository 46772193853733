import React, { useEffect, useRef, useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ChangeInitial } from "../../redux/Main/action";
import ClientChoose from "../Clients/chooseClient";
import DistributionTable from '../DistributionTable/DistributionTable';
import GiveEvents from '../Events/GiveEvents';
import { abandoned_cart } from '../ClientData/actions';
import GivingCentralDynamicCard from '../Shared/GivingCentralDynamicCard';

const { RangePicker } = DatePicker;
const GivingCentral = () => {

    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth0();
    const { role, client } = useSelector(({ main }) => main);

    const [dates, setDates] = useState({
        startDate: dayjs().subtract(7, 'day'),
        endDate: dayjs()
    })
    const [datePopup, setDatePopup] = useState(true);
    const [isSelect, setIsSelect] = useState(false);
    const [btnActive, setBtnActive] = useState('days');
    const [isDateToggle, setIsDateToggle] = useState(false);
    const [isDropDown, setIsDropDown] = useState('Last 7 days');
    const dropdownRef = useRef(null);
    const [date, setDate] = useState({
        startDate: '',
        endDate: ''
    })

    useEffect(() => {
        setDates({
            startDate: dayjs().subtract(7, 'day'),
            endDate: dayjs()
        })
    }, []);

    useEffect(() => {
        dispatch(ChangeInitial("Giving Central"));
    }, [dispatch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            var target = event.target;
            var className = target.classList[0];
            console.log("className", className);
            if (className !== "ant-picker-cell-inner" && className !== "date-range-btn" && className !== undefined && className !== "ant-picker-header-prev-btn"
                && className !== "ant-picker-super-prev-icon" && className !== "ant-picker-header-next-btn" && className !== "ant-picker-header-super-next-btn"
                && className !== "ant-picker-prev-icon" && className !== "ant-picker-header-super-prev-btn" && className !== "ant-picker-next-icon"
                && className !== "ant-picker-month-btn" && className !== "ant-picker-year-btn" && className !== "ant-picker-super-next-icon" && className !== "ant-picker"
            ) {
                setIsDateToggle(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [datePopup]);

    const handleDateChange = (date, dateString) => {
        if (dateString && dateString[0] && dateString[1]) {
            setIsSelect(true);
            setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) });
        }
    }

    const handle = (val) => {
        setDatePopup(val);
    }

    const handleDropdownChange = (interval, startDates, endDates) => {
        let startDate, endDate;
        switch (interval) {
            case "Select":
                startDate = startDates;
                endDate = endDates;
                setDate({ startDate: startDates, endDate: endDates });
                break;
            case "Last 7 days":
                startDate = dayjs().subtract(7, 'day');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "Last 15 days":
                startDate = dayjs().subtract(15, 'day');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "Current Month":
                startDate = dayjs().startOf('month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "1 Month":
                startDate = dayjs().subtract(1, 'month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "3 Month":
                startDate = dayjs().subtract(3, 'month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "6 Month":
                startDate = dayjs().subtract(6, 'month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "9 Month":
                startDate = dayjs().subtract(9, 'month');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            case "1 Year":
                startDate = dayjs().subtract(1, 'year');
                endDate = dayjs();
                setDate({ startDate: '', endDate: '' });
                break;
            default:
                startDate = '';
                endDate = '';
        }
        setDates({ startDate, endDate });
        setIsDropDown(interval);
    };

    return (
        <div className='giving-central'>
            {isAuthenticated && role === "admin" && (
                <ClientChoose alertType="alert alert-success">
                    <span>
                        Welcome <strong>Team Member</strong>. Please choose a client to
                        view/edit their data.
                    </span>
                </ClientChoose>
            )}
            {client !== "NONE" && (
                <>
                    <div className='dashboard dynamic-card'>
                        <div className='date-range-picker card shadow'>
                            <div>
                                <div className={`btn-group ${btnActive === 'days' && 'btn-today'}`}>
                                    <button type="button" className={`btn btn-primary date-range-btn dropdown-toggle ${btnActive === 'days' && 'btn-today'}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        onClick={() => { setBtnActive('days'); handleDropdownChange(isDropDown, date.startDate, date.endDate); }}>
                                        {isDropDown} &nbsp; &nbsp;
                                        <i className="material-icons">keyboard_arrow_down</i>
                                    </button>
                                    <div className="dropdown-menu">
                                        <div className={`dropdown-list ${isDropDown === "Select" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange(isDropDown)}
                                        >&nbsp; &nbsp; Select
                                        </div>
                                        <div className={`dropdown-list ${isDropDown === "Last 7 days" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange("Last 7 days")}
                                        >&nbsp; &nbsp; Last 7 days
                                        </div>
                                        <div className={`dropdown-list ${isDropDown === "Last 15 days" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange("Last 15 days")}
                                        >&nbsp; &nbsp; Last 15 days
                                        </div>
                                        <div className={`dropdown-list ${isDropDown === "Current Month" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange("Current Month")}
                                        >&nbsp; &nbsp; Current Month
                                        </div>
                                        <div className={`dropdown-list ${isDropDown === "1 Month" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange("1 Month")}
                                        >&nbsp; &nbsp; 1 Month
                                        </div>
                                        <div className={`dropdown-list ${isDropDown === "3 Month" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange("3 Month")}
                                        >&nbsp; &nbsp; 3 Month
                                        </div>
                                        <div className={`dropdown-list ${isDropDown === "6 Month" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange("6 Month")}
                                        >&nbsp; &nbsp; 6 Month</div>
                                        <div className={`dropdown-list ${isDropDown === "9 Month" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange("9 Month")}
                                        >&nbsp; &nbsp; 9 Month
                                        </div>
                                        <div className={`dropdown-list ${isDropDown === "1 Year" && `dropdown-list-active`}`}
                                            onClick={() => handleDropdownChange("1 Year")}
                                        >&nbsp; &nbsp; 1 Year
                                        </div>
                                    </div>
                                </div>
                                <div className={`btn-group ${btnActive === 'custom-date' && 'btn-today'} ${isDateToggle && 'open'}`} ref={dropdownRef}>
                                    <button type="button" className={`btn btn-primary date-range-btn dropdown-toggle ${btnActive === 'custom-date' && 'btn-today'}`}
                                        onClick={() => { setBtnActive('custom-date'); setIsDateToggle(!isDateToggle); }}
                                    >
                                        Select Custom Range &nbsp; &nbsp;
                                        <i className="material-icons">keyboard_arrow_down</i>
                                    </button>
                                    <div className="dropdown-menu">
                                        <div style={{ width: '260px', padding: '5px 10px' }}>
                                            <RangePicker
                                                value={[date.startDate, date.endDate]}
                                                onChange={handleDateChange}
                                                onOpenChange={handle}
                                                allowClear={true}
                                                size={"middle"}
                                                bordered={"false"}
                                                format={"YYYY-MM-DD"}
                                                separator={<i className="material-icons">arrow_right_alt</i>}
                                                disabledDate={(current) => current && current < dayjs().subtract(1, 'year') || current > dayjs()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='date-display d-sm-block d-md-flex'>Data displayed for {btnActive === 'custom-date' ? 'Custom Range' : isDropDown}: <div className='date'>&nbsp;{dayjs(dates.startDate).format('MMMM DD YYYY')} - {dayjs(dates.endDate).format('MMMM DD YYYY')}</div></div>
                            </div>
                        </div>
                        <div className='GiveEvents'>
                            <GiveEvents
                                setIsDateToggle={setIsDateToggle}
                                isSelect={isSelect}
                                setIsSelect={setIsSelect}
                                datePopup={datePopup}
                                btnActive={btnActive}
                                isDropDown={isDropDown}
                                dates={dates}
                                handleDropdownChange={handleDropdownChange}

                            />
                        </div>
                        <div className='GiveEvents'>
                            <div className='dashboard dynamic-card'>
                                <GivingCentralDynamicCard
                                    title="Abandoned Gift Explorer"
                                    formType="abandoned_cart"
                                    background="linear-gradient(60deg, rgb(76, 175, 80), rgb(33 98 36))"
                                    columns={abandoned_cart}
                                    card={["Person"]}
                                    urlSegment="abandoned_cart"
                                    dates={dates}
                                    isDropDown={isDropDown}
                                    isSelect={isSelect}
                                    setIsSelect={setIsSelect}
                                    datePopup={datePopup}
                                    btnActive={btnActive}
                                />
                            </div>
                        </div>
                        <DistributionTable
                            btnActive={btnActive}
                            isDropDown={isDropDown}
                            urlSegment="distribution"
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default GivingCentral