import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { IdentityApi } from '../utils/constants';

const SidebarSuper = () => {
  const client = useSelector(({ main }) => main.client);
  const [activeLink, setActiveLink] = useState("");
  let currentUrl = window.location.pathname;
  const navigate = useNavigate();

  useEffect(async() => {
    if (window.location.href.includes("csv_path/")) {
      let urlParams = window.location.href.split("csv_path/")[1];
      let url = `${IdentityApi}query/get_csv_download?file=${urlParams}`;
      const response = await axios.get(url);
      const { status, data } = response;
      if (status === 200) {
          try {
            console.log("datadata", data);
              if (data.status === 'success') {
                  let hiddenElement = document.createElement('a');
                  hiddenElement.href = data.message;
                  // hiddenElement.target = '_blank';
                  hiddenElement.download = 'Report.csv';
                  document.body.appendChild(hiddenElement);
                  hiddenElement.click();
                  document.body.removeChild(hiddenElement);
                  navigate("/admin/identity-dashboard");
              } else {
                window.swal({ title: "Error", text: 'We could not find your result file', icon: "error", button: "OK" });
              }
          } catch (error) {
            window.swal({ title: "Error", text: 'We could not find your result file', icon: "error", button: "OK" });
          }
      } else {
          window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
      }
    }
  }, [currentUrl]);
  
  useEffect(() => {
    if (client) {
      setActiveLink("/admin/identity-dashboard")
    }
  }, [client])
  
  useEffect(() => {
    if (currentUrl.includes("maximized")) {
      currentUrl = "/admin/give-central";
    }
    setActiveLink(currentUrl);
  }, [])

  return (
    <ul className="nav">
      <li>
        <NavLink
          style={activeLink === "/admin/identity-dashboard" ? { color: 'white', background: '#409fbd' } : {}}
          to="/admin/identity-dashboard"
          title="Home"
          onClick={() => setActiveLink("/admin/identity-dashboard")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/identity-dashboard" ? { color: 'white' } : {}} >dashboard</i>
          <p>Dashboard</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/give-central" ? { color: 'white', background: '#409fbd' } : {}}
          to="/admin/give-central"
          title="Giving Central"
          onClick={() => setActiveLink("/admin/give-central")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/give-central" ? { color: 'white' } : {}} >attach_money</i>
          <p>Giving Central</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/signup-success" ? { color: 'white', background: '#409fbd' } : {}}
          to="/admin/signup-success"
          title="Signup Success"
          onClick={() => setActiveLink("/admin/signup-success")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/signup-success" ? { color: 'white' } : {}} >drafts</i>
          <p>Signup Success</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/engaged-sessions" ? { color: 'white', background: '#409fbd' } : {}}
          to="/admin/engaged-sessions"
          title="Engaged Sessions"
          onClick={() => setActiveLink("/admin/engaged-sessions")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/engaged-sessions" ? { color: 'white' } : {}} >face</i>
          <p>Engaged Sessions</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/traffic-trends" ? { color: 'white', background: '#409fbd' } : {}}
          to="/admin/traffic-trends"
          title="Traffic Trends"
          onClick={() => setActiveLink("/admin/traffic-trends")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/traffic-trends" ? { color: 'white' } : {}} >insert_chart</i>
          <div style={{fontSize:'15px'}}>Traffic Trends</div>
        </NavLink>
      </li>
    </ul>
  );
}

export default SidebarSuper;
