import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import loading from './loading.svg';
import { useNavigate } from 'react-router-dom';
import { getProfile, handleAuthentication } from '../../Security/Auth/Auth';
import { UpdateUser, UpdateProducts } from '../../redux/Security/action';
import { useDispatch } from "react-redux";

const CallbackPage = () => {
  const style = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  }

  const { isLoading, error, isAuthenticated, loginWithRedirect, user: authUser, getIdTokenClaims } = useAuth0();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleAuthentication = async () => {
      if (isLoading) {
        // Show a loading indicator if needed
        return;
      }
    };

    handleAuthentication();
  }, [isLoading, loginWithRedirect]);

  if (isLoading) {
    // Show a loading indicator if needed
    return <div style={style}> <img src={loading} alt="loading"/> </div>;
  }

  if (error) {
    // Show an error message if authentication fails
    return <div>Error: {error.message}</div>;
  }

  if (isAuthenticated) {
    window.history.go(-2); // navigate(-2);
    const claimIdToken = async () => {
      if(Object.keys(authUser).length > 0){

        const id_token = await getIdTokenClaims()
        let res = await handleAuthentication(authUser, id_token);

        let authenticatedUser = await getProfile(authUser);

        dispatch(UpdateUser(authenticatedUser));
        dispatch(UpdateProducts(authenticatedUser.products));

        // Redirect or render content for authenticated users
        navigate(res.redirect);

      }
    }
    claimIdToken();
  }

  // Render fallback content if none of the above conditions are met
  return <div style={style}> <img src={loading} alt="loading"/> </div>;
};

export default CallbackPage;