import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';

//Import jquery
import jQuery from "jquery";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChangeClient, ChangeClientType, UpdateClientData } from '../../redux/Main/action';
import { IdentityApi } from '../../utils/constants';

const ClientChoose = ({
  alertType,
  children
}) => {

  const [clientsOptions, setClientsOptions] = useState('');
  const [loading, setLoading] = useState({ 'display': 'block' });
  const [loaded, setLoaded] = useState({ 'display': 'none' });
  const [choosenClient, setChoosenClient] = useState(localStorage.getItem("choosenClient") || 'NONE');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getClients();

    return () => {
      //Show data once done loading
      setLoading({ 'display': 'block' });
      setLoaded({ 'display': 'none' });
    }
  }, [])

  const { client, api, s3PublicCharitableDomain } = useSelector(({ main }) => main);

  const getClients = async () => {

    // let action = api + 'clients/getClients';

    // const response = await axios.get(action);
    const response = await axios.get(`${IdentityApi}query/getClients`);
    // console.log(resData, "resDataresDataresDataresDataresData");
    const { status, data: clientData } = response.data;

    if (status === 'success') {

      // document.getElementsByClassName('loading')[0].innerHTML = 'Error finding clients, please reload the page.';

      await renderClientList(clientData);

      //Show data once done loading
      setLoading({ 'display': 'none' });
      setLoaded({ 'display': 'block' });

      if (client === 'NONE') {

        jQuery(document).ready(() => {

          if (cookie.load('clientChosenCookie') && cookie.load('clientChosenCookie') !== 'NONE') {

            jQuery('#clientChoose').val(cookie.load('clientChosenCookie'));
            chooseClientManual(cookie.load('clientChosenCookie'));
            dispatch(ChangeClient(cookie.load('clientChosenCookie')));

          } else {

            jQuery('#clientChoose').val(client);

          }

        });

      } else {
        dispatch(ChangeClient(client));
        jQuery('#clientChoose').val(client);
      }

    } else {
      document.getElementsByClassName('loading')[0].innerHTML = 'Error finding clients, please reload the page.';
    }

  }

  const chooseClientManual = async (client) => {

    let clientAcronym = client;

    //If a client was chosen then cookie it
    if (clientAcronym !== 'NONE') {
      cookie.save('clientChosenCookie', clientAcronym, { path: '/', maxAge: 3600 * .1 });
    } else {
      cookie.remove('clientChosenCookie', { path: '/', maxAge: 3600 * .1 });
    }

    //Make client active
    dispatch(ChangeClient(clientAcronym));

    //Seach client type on client-meta table
    await searchClientTypeData(clientAcronym);

  }

  const changeClient = async (event) => {

    let clientAcronym = event.currentTarget.value;

    setChoosenClient(clientAcronym);
    localStorage.setItem("choosenClient", clientAcronym);
    localStorage.setItem('breadcrumbsPageType', "donation"); // Set the default page type for the bradcrumbs

    //If a client was chosen then cookie it
    if (clientAcronym !== 'NONE') {
      cookie.save('clientChosenCookie', clientAcronym, { path: '/', maxAge: 3600 * .1 });
    } else {
      cookie.remove('clientChosenCookie', { path: '/', maxAge: 3600 * .1 });
    }
    
    //Make client active
    dispatch(ChangeClient(clientAcronym));

    //Seach client type on client-meta table
    await searchClientTypeData(clientAcronym);

  }

  const searchClientTypeData = async (client) => {
    // console.log("********* searchClientTypeData Welcome Admin. Please choose a client to view/edit their campaigns- client", client);
    let action = api + 'getClientConfig?client=' + client;

    const response = await axios.get(action);
    const { status, data } = response.data;

    if (status === "success") {

      try {

        dispatch(ChangeClientType(data?.clientType || "Fundraising"));
        dispatch(UpdateClientData(data || []));

        navigate('/admin/identity-dashboard');

      } catch (error) {
        console.log("catch error >>>", error)
      }

    }


  };

  //Appending Client List into Dropdown Value
  const renderClientList = async (data) => {

    let clientListing;

    let clientListRender = data.map((client) => {

      clientListing = client;

      if (clientListing === 'AFK') { clientListing = 'DEMO 1'; }

      // if (clientListing === 'FBNYC') { clientListing = 'DEMO 2'; }

      return (

        <option key={client} value={client} defaultValue={choosenClient}>{clientListing}</option>

      );

    });

    clientListRender.sort((a, b) => {

      if (a.props.children < b.props.children)
        return -1;
      if (a.props.children > b.props.children)
        return 1;
      return 0;

    });

    setClientsOptions(clientListRender);

  }

  return (

    <div className="row">

      <div className="col-sm-9">
        <div className={alertType}>
          {children}
        </div>
      </div>

      <div className="col-sm-3">
        <div className="loading" style={loading}><img src={s3PublicCharitableDomain + "/assets/loading-small.svg"} className="img-responsive" alt="Not Found!" /></div>
        <form className="loaded" style={loaded}>
          <select value={choosenClient} onChange={changeClient} className="form-control">
            <option value="NONE">Please Choose</option>
            {clientsOptions}
          </select>
        </form>
      </div>
    </div>

  );
}

export default ClientChoose;
