import React, { useEffect, useState } from "react";
import { getProfile } from "../../Security/Auth/Auth";
import Sidebar from "../../Navigation/Sidebar";
import { useAuth0 } from "@auth0/auth0-react";
import TopNav from '../../Navigation/TopNav';
import Validate from "../../Security/Validate/validate";

const Wrapper = ({ children }) => {
    const [type, setType] = useState("editor");
    const { user: authUser } = useAuth0();
    const [isUserLoggedin, setIsUserLoggedin] = useState(false);
    
    const { isAuthenticated } = useAuth0();

    const getProfileOnLoad = async(authUser) => {

        let user = await getProfile(authUser);
    
        setType(user && user.type)
    }

    useEffect(() => {
        getProfileOnLoad(authUser)
    }, [authUser])

    
    useEffect(() => {
        if (isAuthenticated) {
            setIsUserLoggedin(true);
        } else {
            setIsUserLoggedin(false);
        }
    }, [isAuthenticated])

    return (
        <>
            {isUserLoggedin ? (
                <div className="wrapper" id="mainNav">
                    <Validate />
                    <Sidebar type={type} />
                    <div className="main-panel">
                        <TopNav />
                        <div className="content">
                            <div className="container-fluid">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {children}
                </>
            )}
        </>
    );
}

export default Wrapper;