
import React, { useState, useEffect } from 'react';
import CardWithHeader from '../../Views/UI/cardWithHeader';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { addLeadingZero, positiveNagativeValueCheck } from '../../Helper/helper';
import { Modal, Button, Tooltip, Row, Col } from 'antd';
import ReactDataTable from '../../Views/UI/reactTable';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingOutlined } from '@ant-design/icons';

const TimeRangeFilters = [
    { id: '30_days', value: '30 Days', displayValue: 'Past 30 days' },
    { id: '60_days', value: '60 Days', displayValue: 'Past 60 days' },
    { id: '90_days', value: '90 Days', displayValue: 'Past 90 days' },
    { id: '180_days', value: '6 Months', displayValue: 'Past 6 months' },
    { id: '365_days', value: '1 Year', displayValue: 'Past year' },
    { id: '30_days_prior', value: '30 Days', displayValue: 'Past 30 days compared to 30 days prior' },
    { id: '60_days_prior', value: '60 Days', displayValue: 'Past 60 days compared to 60 days prior' },
    { id: '90_days_prior', value: '90 Days', displayValue: 'Past 90 days compared to 90 days prior' },
    { id: '180_days_prior', value: '6 Months', displayValue: 'Past 6 months compared to 6 months prior' },
    { id: '365_days_prior', value: '1 Year', displayValue: 'Past year compared to prior year' },
]

const DistributionTbl = ({
    urlSegment,
    btnActive,
    isDropDown
}) => {

    const { client, IdentityApi } = useSelector(({ main }) => main);
    const [data, setData] = useState([]);
    const { user: authUser } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const defaultColumns = [
        {
            Header: 'Decile',
            accessor: 'range',
            width: 80,
            disableSortBy: true,
            Cell: ({ value }) => { return <div style={{ fontWeight: '400' }}>{value}</div> }
        }, {
            Header: "Average",
            accessor: "average",
            width: 100,
            disableSortBy: true
        },
        {
            Header: "Quantity",
            accessor: "quantity",
            width: 100,
            disableSortBy: true
        },
        {
            Header: "Minimum",
            accessor: "minimum",
            width: 100,
            disableSortBy: true
        },
        {
            Header: "Maximum",
            accessor: "maximum",
            width: 100,
            disableSortBy: true
        },
        {
            Header: "Most Frequent",
            accessor: "mostfrequent",
            disableSortBy: true
        },
    ];

    const [columns, setColumns] = useState(defaultColumns);
    const [timeValue, setTimeValue] = useState("30_days");
    const [transaction, setTransaction] = useState("All");
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [precedingHideShow, setPrecedingHideShow] = useState(false);
    const [selectValueHideShow, setSelectValueHideShow] = useState(false);
    const [dates, setDates] = useState({
        start_date: new Date(),
        end_date: new Date()
    });

    const calculateDateRange = async () => {
        let startDate = new Date();
        let endDate = new Date();
        let previousStartDate = new Date();
        let days = timeValue.substring(0, 2);
        if (timeValue === "180_days_prior" || timeValue === "365_days_prior" || timeValue === "180_days" || timeValue === "365_days") { days = timeValue.substring(0, 3) }
        switch (timeValue) {

            case `${days}_days`:
                //startDate
                startDate.setDate(endDate.getDate() - days);
                break;

            case `${days}_days_prior`:
                //startDate
                startDate.setDate(endDate.getDate() - parseInt(days));

                //previousStartDate
                previousStartDate.setTime(startDate.getTime());
                previousStartDate.setDate(previousStartDate.getDate() - parseInt(days));

                break;
            default:
                break;
        }

        fetchData(startDate, endDate, previousStartDate);
        setDates({ start_date: startDate, end_date: endDate });
    };

    const fetchData = async (startDate, endDate, previousStartDate) => {
        if (client === "NONE") {
            setData([]);
            return;
        }

        const tableData = {
            client: client.toLowerCase(),
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            compareStartDate: timeValue !== "30_days" && timeValue !== "60_days" && timeValue !== '90_days' && timeValue !== '180_days' && timeValue !== '365_days' ? moment(previousStartDate).format("YYYY-MM-DD") : '',
            compareEndDate: timeValue !== "30_days" && timeValue !== "60_days" && timeValue !== '90_days' && timeValue !== '180_days' && timeValue !== '365_days' ? moment(startDate).subtract(1, 'day').format("YYYY-MM-DD") : '',
            donationType: transaction
        };

        try {
            const response = await axios.post(`${IdentityApi}query/getfrequency`, tableData);
            if (response.status === 200) {
                const arrayData = [];
                let selectedCount = 0;
                let compareCount = 0;
                const results = response.data.data;
                const selected = TimeRangeFilters.find((filter) => filter.id === timeValue);
                for (const res of results) {
                    arrayData.push({
                        description: `Last ${selected.value}`,
                        range: `${res.range}%`,
                        average: `$${res.selectedRange.avg === null ? '0' : addLeadingZero(res.selectedRange.avg)}`,
                        quantity: res.selectedRange.qty === null ? '0' : res.selectedRange.qty,
                        minimum: `$${res.selectedRange.min === null ? '0' : addLeadingZero(res.selectedRange.min)}`,
                        maximum: `$${res.selectedRange.max === null ? '0' : addLeadingZero(res.selectedRange.max)}`,
                        mostfrequent: `$${res.selectedRange?.frequency.amt === null ? '0' : addLeadingZero(res.selectedRange?.frequency.amt)} | ${res.selectedRange?.frequency.qty === null ? '0' : res.selectedRange?.frequency.qty}`,
                    });
                    selectedCount += parseInt(res.selectedRange.qty);
                    res.selectedRange.avg == null && res.selectedRange.qty == 0 && res.selectedRange.min == null && res.selectedRange.max == null && res.selectedRange?.frequency?.amt == 0 && res.selectedRange?.frequency?.qty == 0 ? setSelectValueHideShow(true) : setSelectValueHideShow(false);

                    if (res.comparisionRange) {
                        arrayData.push({
                            description: `Preceding ${selected.value}`,
                            range: `${res.range}%`,
                            average: res.comparisionRange.avg == null ? '$0' : `$${addLeadingZero(res.comparisionRange.avg)}`,
                            quantity: res.comparisionRange.qty == null ? '0' : res.comparisionRange.qty,
                            minimum: res.comparisionRange.min == null ? '$0' : `$${addLeadingZero(res.comparisionRange.min)}`,
                            maximum: res.comparisionRange.max == null ? '$0' : `$${addLeadingZero(res.comparisionRange.max)}`,
                            mostfrequent: `$${res.comparisionRange?.frequency?.amt ?? '0'} | ${res.comparisionRange?.frequency?.qty ?? 0}`,
                            preceding: `preceding|${(res.comparisionRange.avg == null && res.comparisionRange.qty == 0 && res.comparisionRange.min == null && res.comparisionRange.max == null && res.comparisionRange?.frequency?.amt == 0 && res.comparisionRange?.frequency?.qty == 0) ? 0 : 1}`
                        });
                        compareCount += parseInt(res.selectedRange.qty);
                        res.comparisionRange.avg == null && res.comparisionRange.qty == 0 && res.comparisionRange.min == null && res.comparisionRange.max == null && res.comparisionRange?.frequency?.amt == 0 && res.comparisionRange?.frequency?.qty == 0 ? setPrecedingHideShow(true) : setPrecedingHideShow(false);
                    }
                    if (!res.comparisionRange) {
                        setPrecedingHideShow(false);
                    }

                    if (res.comparisionRange) {
                        const avg = res.selectedRange.avg - res.comparisionRange.avg;
                        const avg_percentage = ((avg / res.selectedRange.avg) * 100).toFixed(2);
                        const qty = res.comparisionRange && res.selectedRange.qty - res.comparisionRange.qty;
                        const qty_percentage = res.comparisionRange && ((qty / res.selectedRange.qty) * 100).toFixed(2);
                        const min = res.comparisionRange && res.selectedRange.min - res.comparisionRange.min;
                        const min_percentage = res.comparisionRange && ((min / res.selectedRange.min) * 100).toFixed(2);
                        const max = res.comparisionRange && res.selectedRange.max - res.comparisionRange.max;
                        const max_percentage = res.comparisionRange && ((max / res.selectedRange.max) * 100).toFixed(2);
                        const frq_amt = res.comparisionRange && res.selectedRange.frequency.amt - res.comparisionRange.frequency.amt;
                        const frq_amt_percentage = res.comparisionRange && ((frq_amt / res.selectedRange.frequency.amt) * 100).toFixed(2);
                        const frq_qty = res.comparisionRange && res.selectedRange.frequency.qty - res.comparisionRange.frequency.qty;
                        const frq_qty_percentage = res.comparisionRange && ((frq_qty / res.selectedRange.frequency.qty) * 100).toFixed(2);

                        arrayData.push({
                            description: 'Net Change',
                            range: `${res.range}%`,
                            average: `${addLeadingZero(avg_percentage)}%`,
                            quantity: `${addLeadingZero(qty_percentage)}%`,
                            minimum: `${addLeadingZero(min_percentage)}%`,
                            maximum: `${addLeadingZero(max_percentage)}%`,
                            mostfrequent: `${addLeadingZero(frq_amt_percentage)}% | ${addLeadingZero(frq_qty_percentage)}%`,
                        });
                    }
                }

                setIsLoading(false);
                if (timeValue === "30_days" && selectedCount <= 10) {
                    setData([]);
                } else if (timeValue !== "30_days" && selectedCount <= 10 && compareCount <= 10) {
                    setData([]);
                } else {
                    setData(arrayData);
                }
            } else {
                setData([]);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setData([]);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let columnList = defaultColumns;
        if (timeValue !== '30_days' && timeValue !== '60_days' && timeValue !== '90_days' && timeValue !== '180_days' && timeValue !== '365_days') {
            columnList = [
                {
                    Header: 'Decile',
                    accessor: 'range',
                    width: 80,
                    disableSortBy: true,
                    Cell: ({ value, row, rows }) => {
                        const firstRowIndex = rows.findIndex(
                            r => r.original.range === value
                        );
                        if (row.index === (timeValue === "30_days" && timeValue !== "60_days" && timeValue == '90_days' && timeValue == '180_days' && timeValue == '365_days' ? firstRowIndex : firstRowIndex + 1)) {
                            const rowspan = rows.filter(r => r.original.range === value).length;
                            return <div style={{ fontWeight: '400' }} rowSpan={rowspan}>{value}</div>;
                        } else {
                            return null
                        }
                    }
                },
                {
                    Header: 'Description',
                    accessor: 'description',
                    width: 150,
    
                    disableSortBy: true,
                }, {
                    Header: "Average",
                    accessor: (row) => {
                        return (
                            row.description === "Net Change" ?
                                <span style={positiveNagativeValueCheck(row.average) === -1 ? { color: 'red' } : { color: 'green' }}><strong>{row.average}</strong></span>
                                : <>{row.average}</>
                        )
                    },
    
                    width: 100,
                    disableSortBy: true,
                    enableRowSpan: true
                },
                {
                    Header: "Quantity",
                    accessor: (row) => {
                        return (
                            row.description === "Net Change" ?
                                <span style={positiveNagativeValueCheck(row.quantity) === -1 ? { color: 'red' } : { color: 'green' }}><strong>{row.quantity}</strong></span>
                                : <>{row.average}</>
                        )
                    },
                    width: 100,
                    disableSortBy: true,
                    enableRowSpan: true
                },
                {
                    Header: "Minimum",
                    accessor: (row) => {
                        return (
                            row.description === "Net Change" ?
                                <span style={positiveNagativeValueCheck(row.minimum) === -1 ? { color: 'red' } : { color: 'green' }}><strong>{row.minimum}</strong></span>
                                : <>{row.average}</>
                        )
                    },
    
                    width: 100,
                    disableSortBy: true,
                    enableRowSpan: true
                },
                {
                    Header: "Maximum",
                    accessor: (row) => {
                        return (
                            row.description === "Net Change" ?
                                <span style={positiveNagativeValueCheck(row.maximum) === -1 ? { color: 'red' } : { color: 'green' }}><strong>{row.maximum}</strong></span>
                                : <>{row.average}</>
                        )
                    },
    
                    width: 100,
                    disableSortBy: true,
                    enableRowSpan: true
                },
                {
                    Header: "Most Frequent",
                    accessor: (row) => {
                        let parts = row.mostfrequent.split('|');
                        let firstValue = parts[0].trim();
                        let secondValue = parts[1].trim();
                        return (
                            row.description === "Net Change" ?
                                <>
                                    <span style={positiveNagativeValueCheck(firstValue) === -1 ? { color: 'red' } : { color: 'green' }}><strong>{firstValue}</strong></span>{''} | {''}
                                    <span style={positiveNagativeValueCheck(secondValue) === -1 ? { color: 'red' } : { color: 'green' }}><strong>{secondValue}</strong></span>
                                </>
                                : <>{row.mostfrequent}</>
                        )
                    },
    
                    disableSortBy: true,
                    enableRowSpan: true
                },
            ];
        }

        setColumns(columnList);
        setIsLoading(true);
        setData([]);

        calculateDateRange();
    }, [timeValue, transaction, client]);

    const handledownload = () => {
        setIsModalOpen(true);
    }

    const handleOk = async () => {
        setIsLoading(true);
        try {
            const formData = {
                client: client,
                startDate: moment(dates?.start_date).format("YYYY-MM-DD"),
                endDate: moment(dates?.end_date).format("YYYY-MM-DD"),
                email: authUser.email,
                userName: authUser.name,
                title: "Distribution Table",
                data: data,
                origin: window.origin
            };
            const results = await axios.post(`${IdentityApi}query/downloadData`, formData);

            if (results.status === 200 && results.data.status === 'success') {
                window.swal({ title: "Success", text: 'Email send successfully.', icon: "success", button: "OK" });
                setIsLoading(false);
                setIsModalOpen(false);
            } else {
                window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
                setIsLoading(false);
                setIsModalOpen(false);
            }
        } catch (error) {
            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
            setIsLoading(false);
            setIsModalOpen(false);
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    return (
        <div className='dashboard-table distribution-table'>
            <div className="utm-leader-board-container">
                <div className="row">
                    {urlSegment ?
                        <div className="col-sm-12 col-md-12">
                            <div className="card-title">Distribution Table</div>
                        </div>
                        : ""
                    }
                </div>
                <div className="card" style={{ paddingTop: '40px' }}>
                    <div className="card-content" style={{ padding: '0px' }}>
                        <div className="col-sm-12 col-md-12" style={{ marginTop: '-30px' }}>
                            <div className='row dropdown-transaction-main'>
                                <div className='col-sm-3 col-md-3 dropdown-transaction-type'>
                                    <select value={transaction} onChange={(e) => setTransaction(e.target.value)} className="form-control">
                                        <option value="All">All</option>
                                        <option value="One-Time">One-Time</option>
                                        <option value="Recurring">Recurring</option>
                                    </select>
                                </div>
                                <div className='col-sm-9 col-md-9 dropdown-transaction-prior'>
                                    <select value={timeValue} onChange={(e) => setTimeValue(e.target.value)} className="form-control">
                                        {TimeRangeFilters.map((range) => <option key={range.id} value={range.id}>{range.displayValue}</option>)}
                                    </select>
                                </div>
                                <div className='col-sm-1 col-md-1'>
                                    {/* <Button className="btn  btn-sm btn-secondary fullscreen-button" onClick={() => setVisible(true)}>
                                        <i className="material-icons">fullscreen</i>
                                    </Button> */}
                                </div>
                            </div>
                            <div className='row dashboard-table-data'>
                                {/* <Modal
                                    title=""
                                    className="dashboard-table-model"
                                    open={visible}
                                    onCancel={() => setVisible(false)}
                                    footer={null}
                                >
                                    <ReactDataTable
                                        data={precedingHideShow ? [] : data}
                                        columns={columns}
                                        showFilter={false}
                                        showPaginationOnBottom={false}
                                        showPaginationOnTop={false}
                                        defaultPageSize={50}
                                        showHideColumns={true}
                                        excludedIndexFromHide={timeValue !== "30_days" && timeValue !== "60_days" && timeValue == '90_days' && timeValue == '180_days' && timeValue == '365_days' ? [0, 1] : [0]}
                                        hiddenColumns={['quantity']}
                                        noDataFound={
                                            selectValueHideShow && precedingHideShow
                                                ? 'Data collection in progress'
                                                : precedingHideShow
                                                    ? 'Your web telemetry data isn’t complete for this date range, if you believe this is in error please reach out to support through the chat, or contact dmcsupport@rkdgroup.com'
                                                    : data.length
                                                        ? ''
                                                        : 'Data collection in progress'}
                                    />
                                </Modal> */}
                                <ReactDataTable
                                    isLoading={isLoading}
                                    handledownload={handledownload}
                                    data={precedingHideShow ? [] : data}
                                    columns={columns}
                                    showFilter={false}
                                    showPaginationOnBottom={false}
                                    showPaginationOnTop={false}
                                    defaultPageSize={50}
                                    showHideColumns={true}
                                    timeValue={timeValue}
                                    excludedIndexFromHide={timeValue !== "30_days" && timeValue !== "60_days" && timeValue == '90_days' && timeValue == '180_days' && timeValue == '365_days' ? [0, 1] : [0]}
                                    hiddenColumns={['quantity']}
                                    noDataFound={
                                        selectValueHideShow && precedingHideShow
                                            ? 'Data collection in progress'
                                            : precedingHideShow
                                                ? 'Your web telemetry data isn’t complete for this date range, if you believe this is in error please reach out to support through the chat, or contact dmcsupport@rkdgroup.com'
                                                : data.length
                                                    ? ''
                                                    : 'Data collection in progress'}
                                    urlSegment={urlSegment}
                                    btnActive={btnActive}
                                    isDropDown={isDropDown}
                                />
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
                <Modal
                    open={isModalOpen}
                    // onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[]}
                >
                    <div className="download-modal">
                        <p>Downloading</p>
                        <strong>Distribution Table</strong>
                        <span>You are about to download the data for Distribution Table. Download link will be sent to the following Email.</span>
                        <Row className="profile">
                            <Col>
                                <img src={authUser.picture} width={50} height={50} />
                            </Col>
                            <Col style={{ padding: "0px 10px" }}>
                                <span>{authUser.name}</span><br/>
                                <span>{authUser.email}</span>
                            </Col>
                        </Row>
                        <Button className="send-download-link" key="submit" type="primary" onClick={handleOk}>
                            Send Download Link
                            {isLoading ? <LoadingOutlined/> : <i className="material-icons">send</i>}
                        </Button>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default DistributionTbl;