import React, { useState } from 'react';
import { SketchSquareFilled } from '@ant-design/icons';
import { Progress, Pagination } from 'antd';
const StateTable = ({
  data = [],
  isValid = false,
}) => {
  // State to manage current page
  const [currentPage, setCurrentPage] = useState(1);
  // Number of items to display per page
  const itemsPerPage = 5;
  // Calculate index range based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // Filter data based on current page
  const currentPageData = data.slice(startIndex, endIndex);

  return (
    <div>
      <div className="row">
        <div className={`col-md-12 chart-container`}>
          <div className='col-md-12'>
            <div className='charts container-fluid'>
              <div className='row header-row'>
                <div className='col-md-10'>
                  <strong>Location</strong>
                </div>
                <div className='col-md-2 percentage'>
                  <strong>No. of Users</strong>
                </div>
              </div>
              <div className='progress-bars'>
                {
                  currentPageData.length ? currentPageData.map((item, index) => {
                    return (
                      <div className='row data-row' key={index}>
                        <div className='col-md-12'>
                          <div className="table-heading">
                            <div className='row header-contain'>
                              {item.city}
                            </div>
                            <div className='row inline-block'>
                              {item.users}
                            </div>
                          </div>
                          <div className='row bar'>
                            <Progress strokeLinecap="butt" percent={parseFloat(item.percentage)} status="active" strokeColor="#39a7d9" showInfo={false} />
                          </div>
                        </div>
                      </div>
                    )
                  }) : <div className='row data-row'></div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination component */}
      {isValid && (<div className="row pagination">
        <div className="col-md-12">
          <Pagination
            current={currentPage}
            total={data.length}
            pageSize={itemsPerPage}
            onChange={(page) => setCurrentPage(page)}
            showSizeChanger={false}
          />
        </div>
      </div>)}
    </div>
  )
}
export default StateTable;