import React, { useState } from "react";
import { LoadingOutlined, SketchSquareFilled } from "@ant-design/icons";
import { Progress, Pagination, Tooltip, Modal, Row, Col, Button } from "antd";
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSelector } from "react-redux";
import { replaceArrayKeys } from "../../Helper/helper";
import ReactTableUI from "../../Views/UI/ReactTableUI";

const CityTable = ({
  data = [],
  isValid = false,
  urlSegment,
  isLoading,
  btnActive,
  isDropDown,
  dates,
  setisLoading,
}) => {
  // State to manage current page
  const [currentPage, setCurrentPage] = useState(1);
  const { client, IdentityApi } = useSelector(({ main }) => main);
  const { user: authUser } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Number of items to display per page
  const itemsPerPage = 5;

  // Calculate index range based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filter data based on current page
  const currentPageData = data.slice(startIndex, endIndex);

  const handledownload = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    let array = replaceArrayKeys(currentPageData, [
      { old: "percentage", new: "Percentage" },
      { old: "name", new: "Name" },
      { old: "actualtotalamount", new: "Actual Total Amount" },
      { old: "count", new: "Count" },
      { old: "totalamount", new: "Total Amount" },
    ]);
    setisLoading(true);
    try {
      const formData = {
        client: client,
        startDate: dayjs(dates?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(dates?.endDate).format("YYYY-MM-DD"),
        email: authUser.email,
        userName: authUser.name,
        title: "Donation by City & State",
        data: array,
        origin: window.origin,
      };
      const results = await axios.post(
        `${IdentityApi}query/downloadData`,
        formData
      );

      if (results.status === 200 && results.data.status === "success") {
        window.swal({
          title: "Success",
          text: "Email send successfully.",
          icon: "success",
          button: "OK",
        });
        setisLoading(false);
        setIsModalOpen(false);
      } else {
        window.swal({
          title: "Error",
          text: "Something went wrong! Please try again later.",
          icon: "error",
          button: "OK",
        });
        setisLoading(false);
        setIsModalOpen(false);
      }
    } catch (error) {
      window.swal({
        title: "Error",
        text: "Something went wrong! Please try again later.",
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const activeColumns = [
    {
      Header: "City, State",
      accessor: "name",
      width: 100,
    },
    {
      Header: "No. of Donations",
      accessor: "count",
      width: 100,
    },
    {
      Header: "Total Amount",
      accessor: "totalamount",
      width: 100,
    },
  ];

  return (
    <div>
      <div className="city-chart">
        <div
          className={urlSegment ? "" : "city-table"}
          style={{ paddingLeft: "0px", paddingTop: "20px" }}
        >
          {urlSegment && (
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="card-title">Donation by City & State</div>
              </div>
            </div>
          )}
          <div className="card" style={{ height: urlSegment ? "350px" : "", marginBottom:'0px' }}>
            <div className="card-content" style={{ padding: "15px 15px 0px 15px" }}>
              {!isLoading ? (
                <div className="row">
                  <div className={`col-md-12 chart-container`}>
                    <div>
                    {urlSegment &&(
                      <div
                        className={`row download event-icons ${
                          urlSegment ? "event-icons" : "event-icons-download"
                        }`}
                      >
                        <Tooltip title="Maximize">
                          {urlSegment && (
                            <a
                              href={`/admin/give-central/maximized/${urlSegment}/${btnActive}/${isDropDown}/${dayjs(
                                dates?.startDate
                              ).format("YYYY-MM-DD")}_${dayjs(
                                dates?.endDate
                              ).format("YYYY-MM-DD")}`}
                              target="_blank"
                              style={{ color: "#666666" }}
                            >
                              <span className="icon-title-icon">
                                <i className="material-icons">zoom_out_map</i>
                              </span>
                            </a>
                          )}
                        </Tooltip>
                        <Tooltip title="Download">
                          <span
                            className="icon-title-icon"
                            onClick={() => handledownload()}
                          >
                            <i className="material-icons">download_2</i>
                          </span>
                        </Tooltip>
                      </div>)}
                      <div className="charts">
                        <div className="progress-bars giving-central-card">
                          <ReactTableUI
                            data={data}
                            columns={activeColumns}
                            isLoading={!isValid}
                            showFilter={false}
                            showPaginationOnBottom={true}
                            pageSizeOptions={false}
                            defaultPageSize={5}
                            urlSegment={urlSegment}
                            btnActive={btnActive}
                            isDropDown={isDropDown}
                            dates={dates}
                            handledownload={handledownload}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="skeleton skeleton-chart">
                      <SkeletonLine style={{ height: "50%", width: "4%" }} />
                      <SkeletonLine style={{ height: "45%", width: "4%" }} />
                      <SkeletonLine style={{ height: "20%", width: "4%" }} />
                      <SkeletonLine style={{ height: "100%", width: "4%" }} />
                      <SkeletonLine style={{ height: "50%", width: "4%" }} />
                      <SkeletonLine style={{ height: "45%", width: "4%" }} />
                      <SkeletonLine style={{ height: "20%", width: "4%" }} />
                      <SkeletonLine style={{ height: "100%", width: "4%" }} />
                      <SkeletonLine style={{ height: "50%", width: "4%" }} />
                      <SkeletonLine style={{ height: "45%", width: "4%" }} />
                      <SkeletonLine style={{ height: "20%", width: "4%" }} />
                      <SkeletonLine style={{ height: "100%", width: "4%" }} />
                      <SkeletonLine style={{ height: "50%", width: "4%" }} />
                      <SkeletonLine style={{ height: "45%", width: "4%" }} />
                      <SkeletonLine style={{ height: "20%", width: "4%" }} />
                    </div>
                  </div>
                </div>
              )}

            </div>
            <Modal open={isModalOpen} onCancel={handleCancel} footer={[]}>
              <div className="download-modal">
                <p>Downloading</p>
                <strong>Donation by City & State</strong>
                <span>
                  You are about to download the data for Donation by City &
                  State. Download link will be sent to the following Email.
                </span>
                <Row className="profile">
                  <Col>
                    <img src={authUser.picture} width={50} height={50} />
                  </Col>
                  <Col style={{ padding: "0px 10px" }}>
                    <span>{authUser.name}</span>
                    <br />
                    <span>{authUser.email}</span>
                  </Col>
                </Row>
                <Button
                  className="send-download-link"
                  key="submit"
                  type="primary"
                  onClick={handleOk}
                >
                  Send Download Link{" "}
                  {isLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <i className="material-icons">send</i>
                  )}
                </Button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityTable;
