import { AUTH_CONFIG } from "./auth0-variables";
import axios from 'axios';

export const getSetAPIAccessToken = () => {
  let getURL = AUTH_CONFIG.usersAPI + 'get/token';
  //let getURL = 'http://192.168.2.18:8080/user/get/token';
  let accessToken = axios.get(getURL, { headers: { 'Email': localStorage.getItem('email') } })
    .then((response) => {

      if (response.data.status === "success") {
        localStorage.setItem('api_access_token', response.data.data.access_token);
      } else {
        localStorage.setItem('api_access_token', "");
      }
      return response;
    }).catch((error) => { console.log(error); });

  return accessToken;

}

export const handleAuthentication = async (authUser, tokenData) => {

  let dataToken = await getSetAPIAccessToken();
  const { data } = dataToken.data;

  setSession(authUser, data, tokenData);

  if (!window.location.href.includes("csv_path/")) {
    return { redirect: '/admin/identity-dashboard' };
  }
}

export const setSession = async (authUser, apiData, tokenData) => {

  // Set the time that the access token will expire at
  let expiresAt = JSON.stringify(
    apiData.expires_in * 1000 + new Date().getTime()
  );

  localStorage.setItem('access_token', apiData.access_token);
  localStorage.setItem('id_token', tokenData.__raw);
  localStorage.setItem('expires_at', expiresAt);
  localStorage.setItem('scopes', apiData.scope);
  localStorage.setItem('email', authUser.email);
  localStorage.setItem('nickname', authUser.nickname);
  localStorage.setItem('client_role', !!authUser['http://one.rkd.io/user_metadata']["role"] ? authUser['http://one.rkd.io/user_metadata']["role"] : '');
  localStorage.setItem('client_type', !!authUser["http://one.rkd.io/app_metadata"]["type"] ? authUser["http://one.rkd.io/app_metadata"]["type"] : '');
  localStorage.setItem('breadcrumbsPageType', 'donation'); // Set the default page type for the bradcrumbs

}

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) {
    throw new Error('No access token found');
  }
  return accessToken;
}

export const getProfile = (authUser) => {

  if (authUser && Object.keys(authUser).length > 0) {
    authUser.type = authUser["http://one.rkd.io/app_metadata"]["type"];
    authUser.role = authUser["http://one.rkd.io/user_metadata"]["role"];
    authUser.products = authUser["http://one.rkd.io/app_metadata"]["products"];
    localStorage.setItem('authUser', JSON.stringify(authUser));
  }

  return authUser;

}

export const logout = () => {
  // Clear local storage
  localStorage.clear();
}

export const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time
  let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  return new Date().getTime() < expiresAt;
}

export const userHasScopes = (scopes) => {
  const grantedScopes = JSON.parse(localStorage.getItem('scopes')).split(' ');
  return scopes.every(scope => grantedScopes.includes(scope));
}

export const getUserProfileFromLocalStorage = () => {
  let authUser = localStorage.getItem('authUser');
  if (authUser) {
    return JSON.parse(authUser);
  }
  return '';
}