let API = 'https://deployapi.charitable.one/';
let IdentityApi = "https://dev-identity.charitable.one/";
let environment = "localhost";
let DomainRedirectURL = "";
let HubLoginURL = "";
let SGKlive = "";

switch (window.location.hostname) {

  case "localhost":
    /*****  Main-Dashboard repo API  *****/
    // API = 'http://localhost:3020/';
    // API = 'https://y33lok8i06.execute-api.us-east-1.amazonaws.com/prod/';
    API = 'https://stagingdashboard.charitable.one/';
    DomainRedirectURL = "?http://localhost:3000/admin/identity-dashboard";
    HubLoginURL = "https://hub-staging.charitable.one/apps";
    IdentityApi = "https://0lncnd13df.execute-api.us-east-1.amazonaws.com/development/";
    // IdentityApi = "http://localhost:3010/";

    // Used in SGK Reducer
    SGKlive = "https://stage-app.charitable.one:3051/";
    // SGKlive = "http://localhost:3051/";

    break;

  case "dev-identity.charitable.one":
    API = 'https://stagingdashboard.charitable.one/';
    IdentityApi = "https://0lncnd13df.execute-api.us-east-1.amazonaws.com/development/";
    DomainRedirectURL = "?https://dev-identity.charitable.one/admin/identity-dashboard";
    HubLoginURL = "https://hub-staging.charitable.one/apps";

    // Used in SGK Reducer
    SGKlive = "https://stage-app.charitable.one:3051/";

    break;

  case "identity.charitable.one":
    API = 'https://deployapi.charitable.one/';
    IdentityApi = " https://kt8k9fxq33.execute-api.us-east-1.amazonaws.com/development/";
    DomainRedirectURL = "?https://identity.charitable.one/admin/identity-dashboard";
    HubLoginURL = "https://hub-staging.charitable.one/apps";

    // Used in SGK Reducer
    SGKlive = "https://stage-app.charitable.one:3051/";

    break;

  default:
    API = 'https://0xai02pjna.execute-api.us-east-1.amazonaws.com/production/';
    DomainRedirectURL = "?http://192.168.2.103:3001/admin/identity-dashboard";
    HubLoginURL = "http://localhost:3000/apps";

    SGKlive = "https://stage-app.charitable.one:3051/";

    break;
}

export {
  API, IdentityApi, environment, DomainRedirectURL, HubLoginURL, SGKlive
}

