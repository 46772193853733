import React, { useState } from "react";
import { DatePicker } from 'antd';
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import dayjs from 'dayjs';

//Plugins
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const { RangePicker } = DatePicker;

const UniqueVisits = ({ uniqueVisits, isLoading, dates, setDates, disableStartDate }) => {

  const CustomTooltip = ({ active, payload }) => {
    if (!(active && payload && payload.length)) return null;
    return (
      <div className="dark-tooltip">
        <p className="title">Date:</p>
        <p className="text">{payload[0].payload.unixdate}</p>
        <hr />
        <p className="title">Unique Visits:</p>
        <p className="text">{payload[0].payload.uniqueVisits}</p>
      </div>
    )
  }

  return (
    <div>
      <div className="card">
        <div className="card-header card-header-icon" data-background-color='red'>
          <i className="material-icons">person</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Unique Visitors</h4>
        </div>
        <div className="card-content">
          <RangePicker
            value={[dates.startDate, dates.endDate]}
            onChange={(date, dateString) => setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) })}
            allowClear={false}
            size={"middle"}
            bordered={"false"}
            format={"YYYY-MM-DD"}
            disabledDate={(current) => current && current < dayjs(disableStartDate)}
            separator={<i className="material-icons">arrow_right_alt</i>}
          />
        </div>
        <div className="card-content">
          {!isLoading ?
            <div className="row">
              <div className={`col-md-9 line-chart-container`}>
                <div className='col-md-12'>
                  <div className='charts'>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={uniqueVisits}>
                        <Line
                          type="monotone"
                          dataKey="uniqueVisits"
                          stroke="#0093B2"
                          activeDot={{ r: 4 }}
                        />
                        <YAxis dataKey="uniqueVisits" />
                        <XAxis dataKey="unixdate" />
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Legend /> */}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-md-12">
                <div className="skeleton skeleton-chart">
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                  <SkeletonLine style={{ height: '20%', width: '4%' }} />
                  <SkeletonLine style={{ height: '100%', width: '4%' }} />
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                  <SkeletonLine style={{ height: '20%', width: '4%' }} />
                  <SkeletonLine style={{ height: '100%', width: '4%' }} />
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default UniqueVisits;
