import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Cell } from 'recharts';
import { addLeadingZero } from '../../Helper/helper';

const BarCharts = ({
    data = [],
    isValid = false,
    eventType,
    segment,
}) => {

    const colors = ['#004A6C', '#0E7D9F', '#41B0D2', '#1A237E', '#303F9F', '#3F51B5', '#263238', '#455A64', '#607D8B'];

    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    {eventType === "channels_performance" &&
                        <>
                            <p className="date">{`Channel Name: ${payload[0].payload.name}`}</p>
                            <div>
                                <p className={!segment ? `average` : 'central-tooltip'} style={{ color: payload[0].color }}>{`Donation Amount: $${addLeadingZero(payload[0].payload.totalamount)}`}</p>
                            </div>
                        </>
                    }
                    {eventType === "campaign_performance" &&
                        <>
                            <p className="date">{`Campaign Name: ${payload[0].payload.name}`}</p>
                            <div>
                                <p className={!segment ? `average` : 'central-tooltip'} style={{ color: payload[0].color }}>{`Donation Amount: $${addLeadingZero(payload[0].payload.totalamount)}`}</p>
                            </div>
                        </>
                    }
                    {
                        eventType === "frequency_distribution" &&
                        <div>
                            <p className={!segment ? `average` : 'central-tooltip'} style={{ color: payload[0].color }}>{`Percentage: ${parseFloat(payload[0].payload.percentage).toFixed(2)}%`}</p>
                            <p className={!segment ? `average` : 'central-tooltip'} style={{ color: payload[0].color }}>{`Total Amount: $${addLeadingZero(payload[0].payload.totalamount)}`}</p>
                        </div>
                    }

                </div>
            );
        }
        return null;
    };

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                {eventType === "frequency_distribution" ?
                    <text x={2} y={-1} dy={10} textAnchor="end" fill="#666" transform="rotate(0)" fontSize={'12px'} style={{ textOverflow: 'ellipsis', width: '20px' }}>
                        {truncateText(payload.value, 7)}
                    </text>
                    : <text x={15} y={-1} dy={10} textAnchor="end" fill="#666" transform="rotate(-18)" fontSize={'12px'} style={{ textOverflow: 'ellipsis', width: '20px' }}>
                        {truncateText(payload.value, 7)}
                    </text>
                }

            </g>
        );
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

    return (
        <div className='bar-chart'>
            <ResponsiveContainer width={"100%"} height={segment ? 300 : 450}>
                {
                    eventType === "frequency_distribution"
                        ? <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 50,
                            }}
                            barCategoryGap={-1}
                        >
                            <XAxis dataKey="count" textAnchor="end" interval={0} tick={<CustomizedAxisTick />}
                                label={{
                                    value: `Number of Donations per Donor`,
                                    style: { textAnchor: 'middle' },
                                    position: 'bottom',
                                }}
                                padding={{
                                    left: 5
                                }}
                            />
                            <YAxis
                                tickFormatter={(value) => `${parseInt(value)}%`}
                                interval="preserveStartEnd"
                                width={100}
                                padding={{
                                    top: 10,
                                    bottom: 4
                                }}
                                label={{
                                    value: `Percentage of Donors`,
                                    style: { textAnchor: 'middle' },
                                    angle: -90,
                                    position: 'insideLeft',
                                    offset: 15,
                                }}
                            />
                            <CartesianGrid strokeDasharray="5 5" vertical={false} horizontal={false} />
                            {isValid ? <Tooltip cursor={false} content={<CustomTooltip />} /> : ''}
                            <Bar dataKey="percentage" activeBar={false} stroke="none">
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={'#004A6C'} />
                                ))}
                            </Bar>
                        </BarChart>

                        : <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 50,
                            }}
                        >
                            <XAxis dataKey="name" textAnchor="end" interval={0} tick={<CustomizedAxisTick />}
                                label={{
                                    value: `${eventType === "channels_performance" ? 'Donation Channels'
                                        : eventType === "campaign_performance" && 'Campaigns'
                                        } `,
                                    style: { textAnchor: 'middle' },
                                    position: 'bottom',
                                }}
                            />
                            <YAxis
                                tickFormatter={(value) => `$${parseInt(value)}`}
                                interval="preserveStartEnd"
                                width={100}
                                padding={{
                                    top: 10,
                                    bottom: 4
                                }}
                                label={{
                                    value: `Total Donation Amount`,
                                    style: { textAnchor: 'middle' },
                                    angle: -90,
                                    position: 'insideLeft',
                                    offset: 15,
                                }}
                            />
                            <CartesianGrid strokeDasharray="5 5" vertical={false} horizontal={false} />
                            {isValid ? <Tooltip cursor={false} content={<CustomTooltip />} /> : ''}
                            <Bar dataKey="totalamount" activeBar={false} >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                                ))}
                            </Bar>
                        </BarChart>
                }
            </ResponsiveContainer>
        </div>
    )
}

export default BarCharts