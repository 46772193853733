import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';

// Antd Icons for Header
import { BuildFilled, EnvironmentFilled, LineChartOutlined } from "@ant-design/icons";

// Redux Actions
import { ChangeInitial } from '../../redux/Main/action';

// JSX Components
import ClientChoose from '../Clients/chooseClient';
import TrafficCards from '../../Components/TrafficTrends/TrendCards';

// NPM Packages
import axios from 'axios';

// Date Picker Package
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

// Constant Variables
import { IdentityApi } from '../../utils/constants';
import moment from 'moment';
import { addLeadingZero } from '../../Helper/helper';

import TotalVisits from '../../Components/TrafficTrends/TotalVisits';
import UniqueVisits from "../../Components/TrafficTrends/UniqueVisits";
import NewVsRepeatVisits from "../../Components/TrafficTrends/NewVsRepeatVisits";


const { RangePicker } = DatePicker;

const TrafficTrends = () => {

    const { isAuthenticated } = useAuth0();
    const dispatch = useDispatch();
    const { role, client } = useSelector(({ main }) => main);

  const [totalVisits, setTotalVisits] = useState([]);
  const [uniqueVisits, setUniqueVisits] = useState([]);
  const [newVisits, setNewVisits] = useState([]);
  const [repeatingVisits, setRepeatingVisits] = useState([]);
  const [isLoading1, setisLoading1] = useState(true);
  const [disableStartDate, setDisableStartDate] = useState('');

    const [deviceLocation, setDeviceLocation] = useState([]);
    const [deviceCategory, setDeviceCategory] = useState([]);
    const [datePopup, setDatePopup] = useState(true);
    const [pageDepth, setPageDepth] = useState([]);
    const [engagementRate, setEngagementRate] = useState([]);
    const [isValidCategory, setIsValidCategory] = useState(false);
    const [isValidLocation, setIsValidLocation] = useState(false);
    const [isValidPageDepth, setIsValidPageDepth] = useState(false);
    const [isValidEngaged, setIsValidEngaged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dates, setDates] = useState({
        startDate: dayjs().subtract(6, 'day'),
        endDate: dayjs()
    });
    const [isSelect, setIsSelect] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    fetchData1(controller);

    return () => {
      controller.abort()
    }
  }, [dates, client]);

  const fetchData1 = async (controller) => {
    try {
      setisLoading1(true);

      let startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
      let endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
      // startDate = '2023-04-06';
      // endDate = '2024-04-06';

      // fetch(`http://localhost:3010/query/getPageTraffic`, {
      fetch(`${IdentityApi}query/getPageTraffic`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        signal: controller.signal,
        body: JSON.stringify({
          client,
          startDate,
          endDate,
        })
      }).then(res => res.json())
        .then(res => {
          console.log(res)
          if (res.status !== 'success') {
            console.error(res);
            return;
          }
          calculateDataPoints1(res.data)
        })

      // axios.post(
      //   // `${IdentityApi}query/getPageTraffic`,
      //   `http://localhost:3010/query/getPageTraffic`,
      //   { client, startDate, endDate },
      //   { signal: controller.signal }
      // ).then(res => {
      //   if (res.data.status !== 'success') {
      //     console.error(res.data);
      //     return;
      //   }
      //   calculateDataPoints(res.data.data)
      // })

    } catch (error) {
      console.error('error fetching traffic trends:', error);
    } finally {
      setisLoading1(false)
    }
  }

  const calculateDataPoints1 = (trafficTrends) => {
    console.log('trafficTrends', trafficTrends.length, trafficTrends)
    // new - GAID that has only one date
    // repeating - GAID that has more than one date
    // unique - total number of distinctive GAID sessions in a day
    // total - total number of GAID sessions in a day
    let newVisitsArray = [];
    let repeatingVisitsArray = [];
    let uniqueVisitsArray = [];
    let totalVisitsArray = [];
    const dGroup = {};
    const gGroup = {};

    // group traffic trends by date
    for (let i = 0; i < trafficTrends.length; i++) {
      if (trafficTrends[i].unixdate in dGroup === false) {
        dGroup[trafficTrends[i].unixdate] = [];
      }
      dGroup[trafficTrends[i].unixdate].push(trafficTrends[i])
    }

    // group traffic trends by gaid
    for (let i = 0; i < trafficTrends.length; i++) {
      if (trafficTrends[i].ga_id in gGroup === false) {
        gGroup[trafficTrends[i].ga_id] = [];
      }
      gGroup[trafficTrends[i].ga_id].push(trafficTrends[i])
    }

    // calculate number of days for each gaid
    for (const gaid in gGroup) {
      let dm = {};
      for (let i = 0; i < gGroup[gaid].length; i++) {
        if (gGroup[gaid][i].unixdate in dm === false) {
          dm[gGroup[gaid][i].unixdate] = 0;
        }
        dm[gGroup[gaid][i].unixdate] += 1;
      }
      if (Object.keys(dm).length === 1) {
        // new - GAID that has only one date
        newVisitsArray.push({ gaid })
      } else {
        // repeating - GAID that has more than one date
        repeatingVisitsArray.push({ gaid })
      }
    }

    // calculate sessions of each day
    for (const date in dGroup) {
      // total - total number of GAID sessions in a day
      totalVisitsArray.push({
        unixdate: date,
        totalVisits: dGroup[date].length,
      })
      let ugsm = {};
      for (let i = 0; i < dGroup[date].length; i++) {
        let key = `${dGroup[date][i].ga_id}.${dGroup[date][i].session_id}`
        if (key in ugsm === false) {
          ugsm[key] = 0;
        }
        ugsm[key] += 1;
      }
      // unique - total number of distinctive GAID sessions in a day
      uniqueVisitsArray.push({
        unixdate: date,
        uniqueVisits: Object.keys(ugsm).length,
      })
    }
    setNewVisits(newVisitsArray);
    setRepeatingVisits(repeatingVisitsArray);
    setTotalVisits(totalVisitsArray.sort((a, b) => a.unixdate.toLowerCase().localeCompare(b.unixdate.toLowerCase())));
    setUniqueVisits(uniqueVisitsArray.sort((a, b) => a.unixdate.toLowerCase().localeCompare(b.unixdate.toLowerCase())));
    console.log({ dGroup, gGroup, totalVisitsArray, uniqueVisitsArray, newVisitsArray, repeatingVisitsArray })
  }

    useEffect(() => {
        dispatch(ChangeInitial("Traffic Trends"));
        fetchData();
    }, []);

    useEffect(() => {
        if (isSelect && !datePopup && dates.startDate && dates.endDate) {
          fetchData();
          setIsSelect(false);
        }
    }, [datePopup]);

    useEffect(() => {
        setDates({
            startDate: dayjs().subtract(6, 'day'),
            endDate: dayjs()
        })
    }, [client]);

    const fetchData = async () => {
        try {

            setIsLoading(true);

            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
            const dateDifference = moment(endDate).diff(moment(startDate), 'days');

            const formData = {
                client: client,
                startDate: startDate,
                endDate: endDate
            };

            const results = await axios.post(`${IdentityApi}query/getTrafficTrends`, formData);
            
            if (results.data.status === 'success' && results.data.data.length) {
                
                const preSignedUrl = results.data.data[0];
                const fromResponse = await axios.get(preSignedUrl);
                
                const trafficData = fromResponse.data;

                if (trafficData.length) {
                    calculateCategory(trafficData);
                    calculateCity(trafficData);
                    PageViewEngagedLoad(dateDifference, trafficData, startDate);
                } else {
                    ifArrayBlank(dateDifference, startDate);
                }

            } else {
                ifArrayBlank(dateDifference, startDate);
            }

            setIsLoading(false);

        } catch (error) {
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            ifArrayBlank(dateDifference, startDate);
            setIsLoading(false);
            console.error("An error occurred:", error);
        }
    };

    // Lie chart code start
    const PageViewEngagedLoad = (dateDifference, datas, startDate) => {
        let Array = [];
        let engagedArray = [];
        for (let i = 0; i <= dateDifference; i++) {
            // for line chart
            let uniqueVisitor = 0;
            const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
            const singleData = datas?.filter((e) => e.unixdate == currentDate);
            const matchingData = singleData[0] ? JSON.parse(singleData[0].pageDepthData) : {};
            const matchingEngaged = singleData[0] ? JSON.parse(singleData[0].engaged20Data) : {};
            const total_lenght = matchingData?.totalLenght ? matchingData?.totalLenght : 0;
            const totalVisitor = matchingData?.pageViews;
            const engagedVisitor = matchingEngaged?.engaged_20Lenght ? matchingEngaged?.engaged_20Lenght : 0;
            if (totalVisitor) {
                uniqueVisitor = totalVisitor?.filter((a, i) => totalVisitor.findIndex((s) => a.ga_id === s.ga_id) === i).length;
            }
            const avg = total_lenght / uniqueVisitor;
            const engagedAvg = (engagedVisitor / total_lenght) * 100;
            Array.push({ date: currentDate, average: avg ? parseFloat(addLeadingZero(parseFloat(avg).toFixed(2))) : 0 });
            engagedArray.push({ date: currentDate, engaged_rate: engagedAvg ? parseFloat(addLeadingZero(parseFloat(engagedAvg).toFixed(2))) : 0 });
        }
        setPageDepth(Array);
        setEngagementRate(engagedArray);
        if (Object.keys(datas[0].pageDepthData).length == 0) {
            setIsValidPageDepth(false);
        } else {
            setIsValidPageDepth(true);
        }
        if (Object.keys(datas[0].engaged20Data).length == 0) {
            setIsValidEngaged(false);
        } else {
            setIsValidEngaged(true);
        }
    };

    const ifArrayBlank = (dateDifference, startDate) => {
        let Array = [];
        let engagedArray = [];
        for (let i = 0; i <= dateDifference; i++) {
            const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
            Array.push({ date: currentDate, average: 0 });
            engagedArray.push({ date: currentDate, engaged_rate: 0 });
        }
        setPageDepth(Array);
        setEngagementRate(engagedArray);
        setIsValidPageDepth(false);
        setIsValidEngaged(false);
    };
    // Lie chart code end
    
    const calculateCategory = (trafficData) => {

        let desktopCount = 0, mobileCount = 0, tabletCount = 0;

        trafficData.forEach((element) => {
            if (element.device_category) {
                let category = JSON.parse(element.device_category);
                if (Object.keys(category).length > 0) {
                    desktopCount += category.isdesktop;
                    mobileCount += category.ismobile;
                    tabletCount += category.istablet;
                }
            }
        });

        const totalUsers = desktopCount + mobileCount + tabletCount;

        let cat_counts = [];
        if (desktopCount > 0) {
            const desktopPercentage = desktopCount / totalUsers * 100;
            cat_counts.push({ name: "Desktop", count: desktopCount, percentage: desktopPercentage.toFixed(2) });
        }
        if (mobileCount > 0) {
            const mobilePercentage = mobileCount / totalUsers * 100;
            cat_counts.push({ name: "Mobile", count: mobileCount, percentage: mobilePercentage.toFixed(2) });
        }
        if (tabletCount > 0) {
            const tabletPercentage = tabletCount / totalUsers * 100;
            cat_counts.push({ name: "Tablet", count: tabletCount, percentage: tabletPercentage.toFixed(2) });
        }

        setIsValidCategory(true);
        setDeviceCategory(cat_counts);

    };

    const calculateCity = (trafficData) => {

        let cityCounts = {};
        var count = 0;

        trafficData.forEach((element) => {
            if (element.device_city) {
                let cities = JSON.parse(element.device_city);
                if (Object.keys(cities).length > 0) {
                    for (let city in cities) {
                        if (cityCounts.hasOwnProperty(city)) {
                            cityCounts[city] += cities[city];
                            count += cityCounts[city];
                        } else {
                            cityCounts[city] = cities[city];
                        }
                    }
                }
            }
        });

        var count = 0;
        for (var key in cityCounts) {
            count += cityCounts[key];
        }

        var result = [];
        for (var key in cityCounts) {
            var percentage = (cityCounts[key] / count) * 100;
            const resObj = {
                "city": key,
                "users": cityCounts[key],
                "percentage": percentage.toFixed(2)
            };
            result.push(resObj);
            result.sort((a, b) => b.users - a.users);
        }

        setIsValidLocation(true);
        setDeviceLocation(result);

    };

    const handleDateChange = (date, dateString) => {
        if (dateString && dateString[0] && dateString[1]) {
            setIsSelect(true);
            setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) });
        }
    }

    const handle = async (val) => {
        setDatePopup(val);
    }

    return (
        <div className='traffic-trends'>
            {isAuthenticated && role === "admin" && (
                <ClientChoose alertType="alert alert-success">
                    <span>
                        Welcome <strong>Team Member</strong>. Please choose a client to
                        view/edit their data.
                    </span>
                </ClientChoose>
            )}

            <div className="date-range card">
                <div className="card-content">
                    <div className="row">
                        <div className="col-md-9 col-sm-9">
                            <RangePicker
                                defaultValue={[dates.startDate, dates.endDate]}
                                onChange={handleDateChange}
                                onOpenChange={handle}
                                allowClear={false}
                                size={"middle"}
                                bordered={"false"}
                                format={"YYYY-MM-DD"}
                                separator={<i className="material-icons">arrow_right_alt</i>}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <TrafficCards
                title="Page Depth"
                trafficType="page_depth"
                data={pageDepth}
                isValid={isValidPageDepth}
                isLoading={isLoading}
                headerIcon={<LineChartOutlined style={{ fontSize: '28px', width: '33px' }} />}
            />
            
            <TrafficCards
                title="Engagement Rate"
                trafficType="engagement_rate"
                data={engagementRate}
                isValid={isValidEngaged}
                isLoading={isLoading}
                headerIcon={<LineChartOutlined style={{ fontSize: '28px', width: '33px' }} />}
            />
            
            <TrafficCards
                title="Device Category"
                trafficType="device_category"
                data={deviceCategory}
                filter_dates={dates}
                isValid={isValidCategory}
                isLoading={isLoading}
                headerIcon={<BuildFilled style={{ fontSize: '28px', width: '33px' }} />}
            />

            <TrafficCards
                title="Device Location"
                trafficType="device_location"
                data={deviceLocation}
                isValid={isValidLocation}
                isLoading={isLoading}
                headerIcon={<EnvironmentFilled style={{ fontSize: '28px', width: '33px' }} />}
            />

          <TotalVisits
            totalVisits={totalVisits}
            isLoading={isLoading1}
            dates={dates}
            setDates={setDates}
            disableStartDate={disableStartDate}
          />
          <UniqueVisits
            uniqueVisits={uniqueVisits}
            isLoading={isLoading1}
            dates={dates}
            setDates={setDates}
            disableStartDate={disableStartDate}
          />
          <NewVsRepeatVisits
            newVisits={newVisits}
            repeatingVisits={repeatingVisits}
            isLoading={isLoading1}
            dates={dates}
            setDates={setDates}
            disableStartDate={disableStartDate}
          />

        </div>
    )
}
export default TrafficTrends;
