import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout as ClearSession } from "../Security/Auth/Auth";
import { useAuth0 } from '@auth0/auth0-react';
import { UpdateUser, UpdateProducts } from '../redux/Security/action';
import { useDispatch } from 'react-redux';
import { getProfile } from '../Security/Auth/Auth';
import { HubLoginURL } from '../utils/constants';
import crypto from "crypto";

const TopNav = () => {

    const { breadcrumbs: breadcrumbsTitle, initial } = useSelector(({ main }) => main);

    const { isAuthenticated, user: authUser } = useAuth0();

    const dispatch = useDispatch();

    useEffect(() => {
      if (isAuthenticated) {
        const authorization = async () => {
          if (Object.keys(authUser).length > 0) {
            let authenticatedUser = await getProfile(authUser);

            dispatch(UpdateUser(authenticatedUser));
            dispatch(UpdateProducts(authenticatedUser.products));
          }
        };
        authorization();
      }
    }, [isAuthenticated, authUser, dispatch]);

    useEffect(() => {
      const secretKey = process.env.REACT_APP_INTERCOM_SECRETE; // an Identity Verification secret key (web)
      const userIdentifier = authUser.email; // the email for your user
      const hash = crypto
        .createHmac("sha256", secretKey)
        .update(userIdentifier)
        .digest("hex");
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "ubbjplne",
        name: authUser.name, // Full name
        email: authUser.email, // the email for your user
        created_at: authUser.updated_at, // Signup date as a Unix timestamp
        user_hash: hash, // HMAC using SHA-256
      });
    }, []);


    const handleLogout = () => {
        ClearSession();
        // logout({ returnTo: window.location.origin + '/login' });
        window.location.replace(HubLoginURL + "/apps/Logout&Identity");
        window.Intercom("shutdown");
    }

    return (

        <nav className="navbar navbar-transparent navbar-absolute">

            <div className="container-fluid">

                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <div className="navbar-brand breadcrumbsText">
                        <Link to={'/dashboard/creatives'} className="dropdown-toggle text-center">
                            <span>{breadcrumbsTitle ? breadcrumbsTitle : ''} </span>
                        </Link>
                        <span>{breadcrumbsTitle ? '/' : ''} </span>
                        <span>{initial ? initial : ''}</span>
                    </div>
                </div>

                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <Link className="dropdown-toggle text-center" data-toggle="dropdown" onClick={()=> handleLogout()}>
                                <i className="material-icons" title="Logout">power_settings_new</i>
                                <p className="">LOGOUT</p>
                            </Link>
                        </li>

                        <li className="separator hidden-lg hidden-md"></li>

                    </ul>
                </div>

            </div>

        </nav>

    );
};

export default TopNav;