import React, { useState, useEffect } from "react";
import { DatePicker, Button, Switch, Input, Row, Col, Modal } from "antd";
import {
    DatabaseOutlined,
    DownloadOutlined,
    LoadingOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Pane, SideSheet, Card as Cards, Positioner } from "evergreen-ui";
import { useSelector } from "react-redux";
import { debounce } from 'lodash';

// Import NPM Libraries
import axios from "axios";
import swal from 'sweetalert';

// for handling RangePicker Dates
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DashbaordCard from "../UI/dashboardCards";

// Helper Functions
import { commas } from "../../Helper/helper";

// Table component
import ReactTable from "../../Components/ReactTablePagination/ReactTable";
import StoredSearchTable from "../ClientData/StoredSearch/StoredSearchTable";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc)
dayjs.extend(timezone)

const { RangePicker } = DatePicker;
let source = axios.CancelToken.source();

const DynamicCards = ({
    title,
    background,
    formType,
    columns,
    card,
    customDateRange
}) => {

    const { client, IdentityApi } = useSelector(({ main }) => main);

    const [isLoading, setIsLoading] = useState(false);
    const [sideLoader, setSideLoader] = useState(false);
    const [mode_search, setMode_search] = useState("date");
    const [mode_text, setMode_text] = useState("Date");
    const [startDate, setStartDate] = useState(customDateRange?.start?.format("YYYY-MM-DD") ?? dayjs().tz("America/New_York").subtract(6, 'd').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(customDateRange?.end?.format("YYYY-MM-DD") ?? dayjs().tz("America/New_York").format("YYYY-MM-DD"));
    const [date_range, setDate_Range] = useState(customDateRange ?? {
        start: dayjs().tz("America/New_York").subtract(6, 'd'),
        end: dayjs().tz("America/New_York")
    });

    // Advance Search States
    const [ga_id, setGa_id] = useState("");
    const [person_id, setPerson_id] = useState("");
    const [session_id, setSession_id] = useState("");
    const [utm_campaign, setUtm_campaign] = useState("");
    const [utm_source, setUtm_source] = useState("");
    const [utm_medium, setUtm_medium] = useState("");
    const [campaign_name, setCampaign_name] = useState("");
    const [supporter_id, setSupporter_id] = useState("");
    const [email, setEmail] = useState("");

    // Stored Results States
    const [stored_searches, setStored_searches] = useState([]);
    const [search_range, setSearch_range] = useState("Past 24 hours");

    // DashbaordCard States
    const [revenue, setRevenue] = useState("");
    const [mobile_count, setMobile_count] = useState(0);
    const [tablet_count, setTablet_count] = useState(0);
    const [desktop_count, setDesktop_count] = useState(0);

    // Side Sheet States
    const [isShown, setIsShown] = useState(false);
    const [sideTableLoading, setSideTableLoading] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const [pageNumberCount, setPageNumberCount] = useState(1);
    const [increment, setIncrement] = useState(0);
    const [isGetCount, setIsGetCount] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [active_data, setActive_data] = useState([]);
    const [tablePages, setTablePages] = useState(0);
    const [dataType, setDataType] = useState('search');

    // Initial Check
    const [initialCall, setInitialCall] = useState(true);
    const [exportLoader, setExportLoader] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);

    useEffect(() => {
        search("date");
        console.log("Start Date ::: ", customDateRange?.start?.format("YYYY-MM-DD") ?? dayjs().tz("America/New_York").subtract(6, 'd').format("YYYY-MM-DD"));
        console.log("End Date ::: ", customDateRange?.end?.format("YYYY-MM-DD") ?? dayjs().tz("America/New_York").format("YYYY-MM-DD"));
    }, []);

    const handleDateChanges = (date, dateString) => {
        setDate_Range({ start: date && date[0], end: date && date[1] });
        setStartDate(dateString && dayjs(dateString[0]).format("YYYY-MM-DD"));
        setEndDate(dateString && dayjs(dateString[1]).format("YYYY-MM-DD"));
    };

    const search_mode = (checked) => {
        if (checked) {
            setMode_text("Advanced");
            setMode_search("advanced");
        } else {
            setMode_text("Date");
            setMode_search("date");
        }
    };

    const show_error = (error, message) => {
        Modal.error({ title: "Error: " + error, content: message });
    };

    const handleSearch = () => {
        if (startDate === "" || endDate === "") {
            show_error(
                "No Date Selected",
                "Please select a date range before searching."
            );
            return;
        }
        console.log(mode_search, "mode_searchmode_searchmode_search");
        if (mode_search === "date") {
            search("date");
        } else {
            search("advanced");
        }
    };

    //Execute search
    const search = (type) => {

        setIsLoading(true);
        setDataType("search");
        setSideLoader(true);
        setPageNumberCount(1);
        setIncrement(0);
        setResetFilter(true);
        setFilteredData([]);

        setTimeout(() => {
            let url = `${IdentityApi}query/get_event`;

            let params;

            if (type === "date") {

                params = {
                    type: formType,
                    eventData: "search",
                    date_start: startDate,
                    date_end: endDate,
                    client: client
                };

            } else if (type === "advanced") {

                params = {
                    type: formType,
                    eventData: "search",
                    date_start: startDate,
                    date_end: endDate,
                    client: client
                };

                if (ga_id !== "") { params.ga_id = ga_id; }
                if (person_id !== "") { params.person_id = person_id; }
                if (session_id !== "") { params.session_id = session_id; }
                if (utm_campaign !== "") { params.utm_campaign = utm_campaign; }
                if (utm_source !== "") { params.utm_source = utm_source; }
                if (utm_medium !== "") { params.utm_medium = utm_medium; }
                if (campaign_name !== "") { params.campaign_name = campaign_name; }
                if (supporter_id !== "") { params.supporter_id = supporter_id; }
                if (email !== "") { params.email = email; }

            }

            setSearchParams(params);
            setInitialCall(false);

            axios.get(url, { params })
                .then((fromResolve) => {
                    if (fromResolve.data.status === "success") {
                        setActive_data(fromResolve.data.data.data);
                        setSearch_range(
                            `${fromResolve.data.data.startDate} -${fromResolve.data.data.endDate}`
                        );
                        setTablePages(
                            Math.ceil(fromResolve.data.data.counts.event_count / 10)
                        );
                        setIsLoading(false);

                        if (type !== "abandoned_cart") {
                            url = `${IdentityApi}query/search_count`;
                            setSideLoader(false);
                            return axios.get(url, { params });
                        } else {
                            setSideLoader(false);
                            return true;
                        }
                    } else {
                        setSideLoader(false);
                        window.swal({ title: "Error", text: 'No data found.', icon: "error", button: "OK" });
                        setFilteredData([]);
                        // throw fromResolve.data.message;
                    }
                })
                .then((fromResolve) => {
                    if (
                        type !== "abandoned_cart" &&
                        fromResolve?.data.status === "success"
                    ) {

                        const { revenue: respRevenue, mobile_count: respMobile_count, tablet_count: respTablet_count, desktop_count: respDesktop_count } = fromResolve.data.data;

                        setRevenue(commas(respRevenue));
                        setMobile_count(respMobile_count ? respMobile_count : 0);
                        setTablet_count(respTablet_count ? respTablet_count : 0);
                        setDesktop_count(respDesktop_count ? respDesktop_count : 0);
                        setIsLoading(false);
                        setSideLoader(false);
                    } else {
                        setRevenue(0);
                        setMobile_count(0);
                        setTablet_count(0);
                        setDesktop_count(0);
                        setFilteredData([]);
                        setIsLoading(false);
                        setSideLoader(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setSideLoader(false);
                    setFilteredData([]);
                    show_error(
                        "Searching Data",
                        error + ", please check logs for further details"
                    );
                });
        }, 500);
    };

    const save_search = () => {

        if (active_data.length <= 0) {

            show_error('Not a valid search', 'Please run a search before trying to save results.');
            return;

        }

        window.swal({
            text: "What would you like to name this search?",
            content: "input",
            buttons: { confirm: { value: '' } }
        }).then(async (value) => {

            if (!value) {

                window.swal({ text: 'Please enter a name for this search.', });

            } else {

                let actionUrl = `${IdentityApi}files/get_stored_names?client=${client}`;

                let validateNames = await axios.get(actionUrl)

                const { message: storedNames } = validateNames.data;

                if (storedNames.includes(value)) {
                    swal("ERROR", "Same name already exists. Try some other name.", "error", { closeOnClickOutside: false });
                } else {

                    let url = `${IdentityApi}files/save_search`;

                    // eslint-disable-next-line
                    let regExp = /[^a-zA-Z0-9 \-\/]/

                    if (regExp.test(value)) {

                        show_error('Not a valid search', 'Please enter valid name');
                        return;

                    }

                    const fromResolve = await axios.get(url, {
                        params: {
                            name: value.toLowerCase().replace(/ /g, '_'),
                            type: formType,
                            searchParams: JSON.stringify(searchParams),
                            counts: JSON.stringify({
                                revenue: revenue,
                                mobile_count: mobile_count,
                                tablet_count: tablet_count,
                                desktop_count: desktop_count,
                                tablePages: tablePages,
                                searchRange: search_range
                            }),
                            client: client
                        }
                    });

                    const { status, message } = fromResolve.data;

                    if (status === 'success') {
                        window.swal({ text: message, });
                    } else {
                        window.swal({ text: message, });
                    }

                }

            }

        });

    }

    const getSaveResult = () => {

        setIsShown(true);
        setSideTableLoading(true);

        let url = `${IdentityApi}files/get_search_list?client=${client}`;

        axios.get(url).then((fromResolve) => {

            if (fromResolve.data.status === 'success') {

                let results = [];
                fromResolve.data.message.forEach((search) => { if (search.type === formType) { results.push(search); } });
                setStored_searches(results);
                setSideTableLoading(false);

            } else {

                show_error("Fetch Search List", "We could not load your data. Please try again");
                setSideTableLoading(false);

            }

        }).catch(() => {

            show_error("Fetch Search List", "We could not load your data. Please try again");
            setSideTableLoading(false);

        });


    }


    // Fetch data through paggination.
    const fetchData = async (page, filter_data = [], from) => {

        let formData = {
            tableType: formType,
            eventData: dataType,
            pageSize: 10,
            pageNumber: page,
            searchParams: searchParams,
            filtered: from === "filterChange" ? filter_data : filteredData,
            sorted: [],
            isGetCount: isGetCount
        }

        if (!initialCall) {
            setTimeout(() => {
                setIsLoading(true);
                debouncedFetchData(formData);
            }, 200);
        }

    }

    const fetchDataAjaxCall = async (formData) => {

        if (source) {
            source.cancel("Previous request cancelled");
        }
        source = axios.CancelToken.source();

        setIsLoading(true);

        try {

            let url = `${IdentityApi}query/getDataByPagination?client=${client}`;

            const response = await axios.post(url, formData, { cancelToken: source.token });
            const { status, data } = response;

            if (status === 200) {
                try {
                    if (data.status === 'success') {
                        setActive_data(data.data.data);

                        if (typeof data.data.event_count === "number") {
                            setTablePages(Math.ceil(data.data.event_count / 10));
                        }
                        setIsLoading(false);

                    } else {
                        window.swal({ title: "Error", text: 'We could not find your result.', icon: "error", button: "OK" });
                        setIsLoading(false);
                    }

                } catch (error) {

                    window.swal({ title: "Error", text: 'We could not find your result.', icon: "error", button: "OK", });
                    setIsLoading(false);

                }

            } else {
                window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
                setIsLoading(false);

            }

        } catch (error) {
            setIsLoading(false);
        }


    }

    const debouncedFetchData = debounce(fetchDataAjaxCall, 200);

    const inputOnChange = (count) => {
        if (count > 0 && count <= tablePages) {
            setPageNumberCount(count);
            setIncrement(count - 1);
            fetchData(count - 1, [], "inputChange");
        }
    }

    const filterOnChange = (count, data) => {

        if(resetFilter && data.length > 0) {
            setResetFilter(false);
        }
        console.log("data :  :", data);
        setFilteredData(data);
        setIsGetCount(true);
        setPageNumberCount(1);
        setIncrement(0);
        fetchData(count - 1, data, "filterChange");
    }

    const nextPageEvent = (page) => {
        if (tablePages > increment) {
            setIncrement(increment + page);
            setPageNumberCount(prev => prev + 1);
            fetchData(increment + page, [], "next");
        }
    }

    const previousPageEvent = (page) => {
        if (increment > 0) {
            setIncrement(increment - page);
            setPageNumberCount(prev => prev - 1);
            fetchData(increment - page, [], "previous");
        }
    }

    const load_search = (event) => {

        let id = event.hash;
        setIsLoading(true);
        setIsShown(false);
        setResetFilter(true);
        setFilteredData([]);
        setInitialCall(true);

        let isAdvanceSearch = false;

        let url = `${IdentityApi}files/get_search?hash=${id}&client=${client}`;

        axios.get(url).then((fromResolve) => {

            if (fromResolve.data.status === 'success') {

                let tempSearchParams = JSON.parse(fromResolve.data.data.searchParams);
                let searchCounts = JSON.parse(fromResolve.data.data.counts);
                let params = { type: tempSearchParams.type, eventData: "search" };

                if (tempSearchParams.ga_id) {
                    setGa_id(tempSearchParams.ga_id);
                    params.ga_id = tempSearchParams.ga_id;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.person_id) {
                    setPerson_id(tempSearchParams.person_id);
                    params.person_id = tempSearchParams.person_id;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.session_id) {
                    setSession_id(tempSearchParams.session_id);
                    params.session_id = tempSearchParams.session_id;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.utm_campaign) {
                    setUtm_campaign(tempSearchParams.utm_campaign);
                    params.utm_campaign = tempSearchParams.utm_campaign;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.utm_source) {
                    setUtm_source(tempSearchParams.utm_source);
                    params.utm_source = tempSearchParams.utm_source;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.utm_medium) {
                    setUtm_medium(tempSearchParams.utm_medium);
                    params.utm_medium = tempSearchParams.utm_medium;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.campaign_name) {
                    setCampaign_name(tempSearchParams.campaign_name);
                    params.campaign_name = tempSearchParams.campaign_name;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.supporter_id) {
                    setSupporter_id(tempSearchParams.supporter_id);
                    params.supporter_id = tempSearchParams.supporter_id;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.email) {
                    setEmail(tempSearchParams.email);
                    params.email = tempSearchParams.email;
                    isAdvanceSearch = true;
                }

                setMode_search("date");
                setMode_text("Date");

                setDate_Range({
                    start: dayjs(tempSearchParams.date_start),
                    end: dayjs(tempSearchParams.date_end)
                });

                setStartDate(tempSearchParams.date_start);
                setEndDate(tempSearchParams.date_end);

                params.date_start = tempSearchParams.date_start;
                params.date_end = tempSearchParams.date_end;
                params.client = client;

                setSearchParams(params);

                url = `${IdentityApi}query/get_event`;

                axios.get(url, { params }).then((fromResolve) => {

                    if (fromResolve.data.status === 'success') {

                        setInitialCall(false);
                        setActive_data(fromResolve.data.data.data);
                        setSearch_range(`${searchCounts.searchRange}`);
                        setTablePages(searchCounts.tablePages);
                        setRevenue(searchCounts.revenue);
                        setMobile_count(searchCounts.mobile_count);
                        setTablet_count(searchCounts.tablet_count);
                        setDesktop_count(searchCounts.desktop_count);
                        setMode_search('advanced');
                        setDataType('search');
                        setPageNumberCount(1);
                        setIsLoading(false);

                        if (isAdvanceSearch) {

                            setMode_search("advanced");
                            setMode_text("Advanced")

                        } else {

                            setMode_search("date");
                            setMode_text("Date");

                        }

                    } else {
                        setIsLoading(false);
                        window.swal({ title: "Error", text: 'No data found.', icon: "error", button: "OK" });
                    }

                }).catch((error) => {
                    setIsLoading(false);
                    setActive_data([]);
                    setTablePages(1);
                    console.log(error);

                });


            } else {
                setIsLoading(false);
                setActive_data([]);
                setTablePages(1);
            }

        }).catch((error) => {
            setIsLoading(false);
            setActive_data([]);
            setTablePages(1);
            console.log(error);
        });


    }

    const delete_search = (event) => {


        let url = `${IdentityApi}files/delete_search?hash=${event.hash}&client=${client}`;

        window.swal("Are you sure you want to delete this stored search?", {
            buttons: {
                no: { text: "No, cancel", value: "cancel" },
                publish: { text: "Yes, delete it", value: "yes" },
            }
        }).then((value) => {

            if (value === 'yes') {

                axios.get(url).then((fromResolve) => {

                    window.swal({ text: fromResolve.data.message, });
                    setIsShown(false);

                });

            } else {

                setIsShown(false);

            }

        });

    }

    const download_find = async () => {

        setExportLoader(true);

        // This lines of setTimeout is only writtern to notify the user that button is clicked. Whenever this functionality will be resolved, this lines should be removed
        setTimeout(() => {
            setExportLoader(false);
        }, 1000);

        let params = searchParams;

        params.type = formType;
        params.eventData = dataType;
        params.date_start = dayjs(date_range.start).format('YYYY-MM-DD');
        params.date_end = dayjs(date_range.end).format('YYYY-MM-DD');
        params.isExport = true;
        params.eventCount = tablePages * 10;
        params.client = client;
        console.log("params", params);

        let url = `${IdentityApi}query/export_event`;

        const response = await axios.get(url, { params });

        const { status, data } = response;

        if (status === 200) {
            try {
                if (data.status === 'success') {
                    downloadFull(data.data);
                } else {
                    window.swal({ title: "Error", text: 'We could not export your data. Please try again,', icon: "error", button: "OK" });
                }
            } catch (error) {
                console.log(error);
                window.swal({ title: "Error", text: 'We could not export your data. Please try again,', icon: "error", button: "OK" });
            }
        } else {
            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
        }
    };

    const downloadFull = async (file) => {

        let url = `${IdentityApi}files/get_file_download?file=${file}&client=${client}`;

        const response = await axios.get(url);

        const { status, data } = response;

        if (status === 200) {

            try {

                if (data.status === 'success') {

                    let hiddenElement = document.createElement('a');

                    hiddenElement.href = data.message;

                    hiddenElement.target = '_blank';

                    hiddenElement.download = 'fullResults.csv';

                    document.body.appendChild(hiddenElement);

                    hiddenElement.click();

                    document.body.removeChild(hiddenElement);

                } else {

                    window.swal({ title: "Error", text: 'We could not find your result file', icon: "error", button: "OK" });

                }

            } catch (error) {

                window.swal({ title: "Error", text: 'We could not find your result file', icon: "error", button: "OK" });

            }

        } else {

            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });

        }

    }

    return (
        <div className="utm-leader-board-container">
            <div className="card">
                <div
                    className="card-header card-header-icon"
                    data-background-color="red"
                    style={{ background: background }}
                >
                    <span className="icon-title-text">{title}</span>
                </div>

                <div className="card-content">
                    <div className="row card-title mr-5"></div>
                    <div className="row transaction-range-container">
                        <Row>
                            <Col span={20}>
                                <Row>
                                    <Col span={18}>
                                        <Col span={24}>
                                            {mode_text === "Date" ? (
                                                <RangePicker
                                                    defaultValue={[date_range.start, date_range.end]}
                                                    value={[date_range.start, date_range.end]}
                                                    onChange={(date, dateString) =>
                                                        handleDateChanges(date, dateString)
                                                    }
                                                    allowClear={false}
                                                    size="large"
                                                    bordered="false"
                                                    separator={
                                                        <i className="material-icons">arrow_right_alt</i>
                                                    }
                                                />
                                            ) : (
                                                <span id="advanced_search">
                                                    <div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                <strong>Google ID:</strong> &nbsp;
                                                            </label>
                                                        </div>

                                                        <div className="col-md-8 advance_field">
                                                            <Input
                                                                onChange={(e) => setGa_id(e.target.value)}
                                                                size="large"
                                                                value={ga_id}
                                                                defaultValue=""
                                                                placeholder="Google ID"
                                                                id="ga_id"
                                                                style={{ width: "260px" }}
                                                            />
                                                            (optional)
                                                        </div>

                                                        {(formType === "onetime_donation" ||
                                                            formType === "recurring_donation") && (
                                                                <div className="col-md-4">
                                                                    <label>
                                                                        <strong>Campaign Name:</strong> &nbsp;
                                                                    </label>
                                                                </div>
                                                            )}

                                                        {(formType === "onetime_donation" ||
                                                            formType === "recurring_donation") && (
                                                                <div className="col-md-8 advance_field">
                                                                    <Input
                                                                        onChange={(e) => setCampaign_name(e.target.value)}
                                                                        value={campaign_name}
                                                                        size="large"
                                                                        defaultValue=""
                                                                        placeholder="Campaign Name"
                                                                        id="campaign_name"
                                                                        style={{ width: "260px" }}
                                                                    />
                                                                    (optional)
                                                                </div>
                                                            )}

                                                        {formType === "general_email_submitted" && (
                                                            <div className="col-md-4">
                                                                <label>
                                                                    <strong>Email ID:</strong> &nbsp;
                                                                </label>
                                                            </div>
                                                        )}

                                                        {formType === "general_email_submitted" && (
                                                            <div className="col-md-8 advance_field">
                                                                <Input
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    value={email}
                                                                    size="large"
                                                                    defaultValue=""
                                                                    placeholder="Email ID"
                                                                    id="email"
                                                                    style={{ width: "260px" }}
                                                                />
                                                                (optional)
                                                            </div>
                                                        )}

                                                        {(formType === "general_email_submitted" ||
                                                            formType === "abandoned_cart") && (
                                                                <div className="col-md-4">
                                                                    <label>
                                                                        <strong>Session ID:</strong> &nbsp;
                                                                    </label>
                                                                </div>
                                                            )}

                                                        {(formType === "general_email_submitted" ||
                                                            formType === "abandoned_cart") && (
                                                                <div className="col-md-8 advance_field">
                                                                    <Input
                                                                        onChange={(e) => setSession_id(e.target.value)}
                                                                        value={session_id}
                                                                        size="large"
                                                                        defaultValue=""
                                                                        placeholder="Session ID"
                                                                        id="session_id"
                                                                        style={{ width: "260px" }}
                                                                    />
                                                                    (optional)
                                                                </div>
                                                            )}

                                                        <div className="col-md-4">
                                                            <label>
                                                                <strong>UTM Campaign:</strong> &nbsp;
                                                            </label>
                                                        </div>

                                                        <div className="col-md-8 advance_field">
                                                            <Input
                                                                onChange={(e) => setUtm_campaign(e.target.value)}
                                                                value={utm_campaign}
                                                                size="large"
                                                                defaultValue=""
                                                                placeholder="UTM Campaign"
                                                                id="utm_campaign"
                                                                style={{ width: "260px" }}
                                                            />
                                                            (optional)
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>
                                                                <strong>UTM Source:</strong> &nbsp;
                                                            </label>
                                                        </div>

                                                        <div className="col-md-8 advance_field">
                                                            <Input
                                                                onChange={(e) => setUtm_source(e.target.value)}
                                                                value={utm_source}
                                                                size="large"
                                                                defaultValue=""
                                                                placeholder="UTM Source"
                                                                id="utm_source"
                                                                style={{ width: "260px" }}
                                                            />
                                                            (optional)
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>
                                                                <strong>UTM Medium:</strong> &nbsp;
                                                            </label>
                                                        </div>

                                                        <div className="col-md-8 advance_field">
                                                            <Input
                                                                onChange={(e) => setUtm_medium(e.target.value)}
                                                                value={utm_medium}
                                                                size="large"
                                                                defaultValue=""
                                                                placeholder="UTM Medium"
                                                                id="utm_medium"
                                                                style={{ width: "260px" }}
                                                            />
                                                            (optional)
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>
                                                            <strong>Dates:</strong>{" "}
                                                        </label>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <RangePicker
                                                            defaultValue={[date_range.start, date_range.end]}
                                                            allowClear={false}
                                                            size="large"
                                                            onChange={(date, dateString) =>
                                                                handleDateChanges(date, dateString)
                                                            }
                                                            bordered="false"
                                                            separator={
                                                                <i className="material-icons">arrow_right_alt</i>
                                                            }
                                                            style={{ width: "260px" }}
                                                        />
                                                        &nbsp;{" "}
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "24px",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </span>
                                            )}
                                            <div className="col-md-12 donation-buttons">
                                                <Row>
                                                    <Button
                                                        type="default"
                                                        icon={<SearchOutlined />}
                                                        size="medium"
                                                        className="col-md-6 btn btn-md btn-generate-export searh-button ant-btn-lg"
                                                        onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </Button>
                                                    <Button
                                                        type="default"
                                                        icon={<DatabaseOutlined />}
                                                        size="medium"
                                                        className="col-md-6 btn btn-md btn-generate-export search-results-button"
                                                        onClick={() => save_search()}
                                                    >
                                                        Save Results
                                                    </Button>
                                                </Row>
                                                <Row>
                                                    <div className="toggle-search">
                                                        <div>
                                                            Toggle search modes: &nbsp;&nbsp;
                                                            <strong>{mode_text} Search:</strong> &nbsp;
                                                            {mode_text === "Date" && (
                                                                <Switch size="large" onChange={search_mode} />
                                                            )}
                                                            {mode_text === "Advanced" && (
                                                                <Switch
                                                                    size="large"
                                                                    defaultChecked
                                                                    onChange={search_mode}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Col>
                                    <Col span={6}>
                                        <div className="viewButton" style={{ marginTop: "65px" }}>
                                            View saved results: &nbsp;
                                            <Button
                                                type="default"
                                                icon={<DatabaseOutlined />}
                                                size="medium"
                                                onClick={() => getSaveResult()}
                                                style={{ width: "100%", fontSize: "1.1vw", height: '36px' }}
                                            >
                                                Saved Results
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                        <ReactTable
                                            data={active_data}
                                            columns={columns}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            inputOnChange={inputOnChange}
                                            filterOnChange={filterOnChange}
                                            filteredData={filteredData}
                                            nextPageEvent={nextPageEvent}
                                            previousPageEvent={previousPageEvent}
                                            setPageNumberCount={setPageNumberCount}
                                            defaultPageSize={10}
                                            tablePages={tablePages}
                                            pageNumberCount={pageNumberCount}
                                            showPaginationOnBottom={true}
                                            showFilter={true}
                                            resetFilter={resetFilter}
                                        />
                                </Row>
                            </Col>
                            <Col span={4}>
                                <Col span={24}>
                                    <div className="sub-cards-block col-md-3">
                                        <div className="row card-title mr-5"></div>
                                        <div className="sub-dynamic-cards">
                                            {/* <Button */}
                                            {/*     type="default" */}
                                            {/*     icon={exportLoader ? <LoadingOutlined /> : <DownloadOutlined />} */}
                                            {/*     size="medium" */}
                                            {/*     disabled={!active_data.length} */}
                                            {/*     className="btn btn-md export-buttons" */}
                                            {/*     onClick={download_find} */}
                                            {/* > */}
                                            {/*     Export to CSV */}
                                            {/* </Button> */}
                                            <DashbaordCard
                                                cards={card}
                                                range={search_range}
                                                revenueData={revenue}
                                                mobileCount={mobile_count}
                                                tabletCount={tablet_count}
                                                desktopCount={desktop_count}
                                                isLoading={sideLoader}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <SideSheet
                isShown={isShown}
                position={Positioner.TOP}
                width={700}
                onCloseComplete={() => setIsShown(false)}
                containerProps={{ display: "flex", flex: "1", flexDirection: "column" }}
            >
                <Pane flex="1" overflowY="scroll" appearance="tint1" padding={16}>
                    <Cards
                        backgroundColor="white"
                        elevation={0}
                        display="block"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <h2 style={{ padding: "10px", textAlign: "center" }}>Stored Search Results</h2>

                        <StoredSearchTable
                            id="stored_searches"
                            data={stored_searches}
                            isLoading={sideTableLoading}
                            load_search={load_search}
                            delete_search={delete_search}
                        />

                    </Cards>
                </Pane>
            </SideSheet>
        </div>
    );
};

export default DynamicCards;
