// packages
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import axios from "axios";
import moment from 'moment';

// Import Columns
import {
  onetime_donation as oneTimeDonations,
  recurring_donation as recurringDonations,
} from "../ClientData/actions";

//import components
import GiveEventChart from "../../Components/GiveEventChart/GiveEventChart";
import CityTable from "../../Components/GiveEventChart/CityTable";
import AvgCharts from "../../Components/AvgCharts/AvgCharts";
import GivingCentralDynamicCard from "../Shared/GivingCentralDynamicCard";

const GiveEvents = ({
  isSelect,
  datePopup,
  setIsSelect,
  btnActive,
  isDropDown,
  dates,
  setIsDateToggle,
  handleDropdownChange
}) => {

  const { client, IdentityApi } = useSelector(({ main }) => main);
  const [average_donation_amount, setAverage_donation_amount] = useState([]);
  const [channels_performance, setChannels_performance] = useState([]);
  const [onetime_vs_recurring, setOnetime_vs_recurring] = useState([]);
  const [donation_source, setDonation_source] = useState([]);
  const [donationFrequency, setDonationFrequency] = useState([]);
  const [campaign_performance, setCampaign_performance] = useState([]);
  const [donation_city, setDonation_city] = useState([]);
  const [totalDonation, setTotalDonation] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isvalidAverage, setIsvalidAverage] = useState(false);
  const [isvalidChannel, setIsvalidChannel] = useState(false);
  const [isvalidOneTime, setIsvalidOneTime] = useState(false);
  const [isvalidCampaign, setIsvalidCampaign] = useState(false);
  const [isvalidSource, setIsvalidSource] = useState(false);
  const [isvalidFrequency, setIsvalidFrequency] = useState(false);
  const [isvalidTotalDonation, setIsvalidTotalDonation] = useState(false);
  const [isvalidCity, setIsvalidCity] = useState(false);

  useEffect(() => {
    if (isDropDown !== "custom-date" && isDropDown !== "Select" && btnActive === "days") {
      setIsvalidAverage(false);
      setIsvalidOneTime(false);
      setIsvalidTotalDonation(false);
      fetchData();
    }
  }, [client, isDropDown]);

  useEffect(() => {
    if (isSelect && !datePopup && dates.startDate && dates.endDate) {
      setIsvalidAverage(false);
      setIsvalidOneTime(false);
      setIsvalidTotalDonation(false);
      fetchData();
      setIsSelect(false);
      setIsDateToggle(false);
      handleDropdownChange("Select", dates.startDate, dates.endDate);
    }
  }, [datePopup]);

  const fetchData = async () => {
    try {
      setisLoading(true);

      const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
      const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
      const dateDifference = moment(endDate).diff(moment(startDate), 'days');

      const formData = {
        client: client,
        startDate: startDate,
        endDate: endDate,
      };

      const results = await axios.post(`${IdentityApi}query/getClientDashboard`, formData);

      if (results.data.status === 'success') {
        const URL = results.data.data[0];
        let data = []
        if (URL && URL !== undefined) {
          const URLData = await axios.get(URL);
          data = URLData.data;
        }

        // Process channels performance
        const channels_performance = data.map(element => JSON.parse(element.utmMediumData));
        const channels = handleChartEvent(channels_performance);
        setIsvalidChannel(channels.length > 0);
        setChannels_performance(channels.length > 0 ? channels : [{ name: "", totalamount: 0 }]);

        // Process campaign performance
        const channels_campaign = data.map(element => JSON.parse(element.utmCampaignData));
        const campaign = handleChartEvent(channels_campaign);
        setIsvalidCampaign(campaign.length > 0);
        setCampaign_performance(campaign.length > 0 ? campaign : [{ name: "", totalamount: 0 }]);

        // Process source
        const source_data = data.map(element => JSON.parse(element.utmSourceData));
        const source = handleChartEvent(source_data);
        setIsvalidSource(source.length > 0);
        setDonation_source(source.length > 0 ? source : [{ name: "", totalamount: 1 }]);

        // Process frequency
        const frequency_data = data.map(element => JSON.parse(element.gaIdData));
        const frequency = handleChartEvent(frequency_data, 'frequency');
        const mergedData = frequency.reduce((acc, curr) => {
          const { count, totalamount } = curr;
          if (!acc[count]) {
            acc[count] = { totalamount, count };
          } else {
            acc[count].totalamount += totalamount;
          }
          return acc;
        }, {});
        const result = Object.values(mergedData);
        let totalAmount = 0;
        result.forEach((item) => { totalAmount += item.totalamount });
        result.forEach((item) => {
          item.percentage = (item.totalamount / totalAmount) * 100;
        });
        setIsvalidFrequency(result.length > 0);
        setDonationFrequency(result.length > 0 ? result : [{ count: "", percentage: 0 }]);

        // Process city
        let totalAmountCity = 0;
        const city_data = data.map(element => JSON.parse(element.ipcityData));
        const city = handleChartEvent(city_data, "ipcity");
        city.forEach((item) => { totalAmountCity += item.actualtotalamount });
        city.forEach((item) => {
          item.percentage = (item.actualtotalamount / totalAmountCity) * 100;
        });
        setIsvalidCity(true);
        setDonation_city(city.length > 0 ? city : []);

        const averageDonation = [];
        const onetime_vs_recurringDonation = [];
        const totalDonationAmt = [];
        for (let i = 0; i <= dateDifference; i++) {
          const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');

          let matchingData = data.find((res) => res.transaction_date === currentDate);

          if (matchingData) {
            // Parsing and rounding the average revenue values, defaulting to 0 if not provided
            let oneTimeAverageRevenue = parseFloat(parseFloat(matchingData.oneTimeAverageRevenue).toFixed(2)) || 0;
            let recurringAverageRevenue = parseFloat(parseFloat(matchingData.recurringAverageRevenue).toFixed(2)) || 0;

            // Parsing total donors, defaulting to 0 if not provided
            let oneTimeTotalDonors = parseInt(matchingData.oneTimeTotalDonors) || 0;
            let recurringTotalDonors = parseInt(matchingData.recurringTotalDonors) || 0;

            //Total donation overTime
            let totalDonationAmount = parseFloat(matchingData.revenue?.replace(/,/g, '')) || 0;

            // Checking if either average revenue is greater than 0
            if (oneTimeAverageRevenue > 0 || recurringAverageRevenue > 0) {
              setIsvalidAverage(true);
            }

            // Checking if either total donors is greater than 0
            if (oneTimeTotalDonors > 0 || recurringTotalDonors > 0) {
              setIsvalidOneTime(true);
            }

            // Checking if either totalDonationAmount is greater than 0
            if (totalDonationAmount > 0) {
              setIsvalidTotalDonation(true);
            }

            // Pushing data to arrays
            averageDonation.push({ date: currentDate, average1: oneTimeAverageRevenue, average2: recurringAverageRevenue });
            onetime_vs_recurringDonation.push({ date: currentDate, oneTimeTotalDonors: oneTimeTotalDonors, recurringTotalDonors: recurringTotalDonors });
            totalDonationAmt.push({ date: currentDate, totalDonationAmt: totalDonationAmount });
          } else {
            averageDonation.push({ date: currentDate, average1: 0, average2: 0 });
            onetime_vs_recurringDonation.push({ date: currentDate, oneTimeTotalDonors: 0, recurringTotalDonors: 0 });
            totalDonationAmt.push({ date: currentDate, totalDonationAmt: 0 });
          }
        }
        //Average Donation Amount month wise data calculation
        const average_donation_amount = aggregateData(averageDonation, dateDifference, "average_donation_amount");
        setAverage_donation_amount(average_donation_amount);

        //One Time vs New Subscriptions month wise data calculation
        const onetime_vs_recurring = aggregateData(onetime_vs_recurringDonation, dateDifference, "onetime_vs_recurring");
        setOnetime_vs_recurring(onetime_vs_recurring);

        //Total Donations Over Time month wise data calculation
        const total_donation = aggregateData(totalDonationAmt, dateDifference, "total_donation");
        setTotalDonation(total_donation);

        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (error) {
      const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
      const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
      const dateDifference = moment(endDate).diff(moment(startDate), 'days');
      const average_donation_amount = Array.from({ length: dateDifference + 1 }, (_, i) => {
        const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
        return { date: currentDate, average1: 0, average2: 0 };
      });
      const onetime_vs_recurring = Array.from({ length: dateDifference + 1 }, (_, i) => {
        const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
        return { date: currentDate, oneTimeTotalDonors: 0, recurringTotalDonors: 0 };
      });
      const totalDonationAmt = Array.from({ length: dateDifference + 1 }, (_, i) => {
        const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
        return { date: currentDate, totalDonationAmt: 0 };
      });
      setAverage_donation_amount(average_donation_amount);
      setOnetime_vs_recurring(onetime_vs_recurring);
      setTotalDonation(totalDonationAmt);
      setisLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const handleChartEvent = (performance, type) => {
    const mergedObjects = performance.reduce((mergedObjects, obj) => {
      if (!obj) return mergedObjects;
      Object.keys(obj).forEach(key => {
        const currentObj = obj[key];
        if (mergedObjects.has(key)) {
          const existingObj = mergedObjects.get(key);
          existingObj.count += currentObj.count || 0;
          existingObj.totalamount += currentObj.totalamount || 0;
          existingObj.onetime.totalgift += currentObj.onetime.totalgift || 0;
          existingObj.onetime.totalamount += currentObj.onetime.totalamount || 0;
          existingObj.recurring.totalgift += currentObj.recurring.totalgift || 0;
          existingObj.recurring.totalamount += currentObj.recurring.totalamount || 0;
        } else {
          mergedObjects.set(key, { ...currentObj });
        }
      });
      return mergedObjects;
    }, new Map());

    const mergedArray = Array.from(mergedObjects, ([key, value]) => ({ [key]: value }));

    const newArray = mergedArray.map(obj => {
      const name = Object.keys(obj)[0];
      const totalamount = parseFloat(parseFloat(obj[name].totalamount).toFixed(2)) || 0;
      const count = obj[name].count || 0;
      return { name, totalamount, count };
    });

    let array = newArray.filter((item) => item.name !== "Others");
    let sortData = array.sort((a, b) => b.totalamount - a.totalamount);

    if (type === "ipcity") {
      sortData = sortData.map((res) => {
        let name = res.name ? res.name.split(',') : "";
        res.actualtotalamount = res.totalamount;
        res.totalamount = '$' + res.totalamount;
        res.name = `${name[0]}, ${name[1]}`;
        return res;
      });
      return sortData;
    }

    const topFive = sortData.slice(0, 5);
    if (type === 'frequency') {
      let array = sortData.filter((item) => item.name !== "something" && item.name !== "na");
      return array;
    } else {
      return topFive;
    }
  };

  const aggregateData = (data, dateDifference, type) => {
    if (dateDifference >= 90) {
      const months = {};

      if (type === "average_donation_amount") {
        data.forEach(item => {
          const date = new Date(item.date);
          const monthKey = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`;
          if (months[monthKey]) {
            months[monthKey].totalAverage1 += item.average1;
            months[monthKey].totalAverage2 += item.average2;
            months[monthKey].count += 1;
          } else {
            months[monthKey] = {
              totalAverage1: item.average1,
              totalAverage2: item.average2,
              count: 1
            };
          }
        });

        const result = [];
        for (const monthKey in months) {
          const monthData = months[monthKey];
          result.push({
            date: monthKey,
            average1: parseFloat(parseFloat(monthData.totalAverage1).toFixed(2)),
            average2: parseFloat(parseFloat(monthData.totalAverage2).toFixed(2))
          });
        }
        return result;

      } else if (type === "total_donation") {
        data.forEach(item => {
          const date = new Date(item.date);
          const monthKey = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`;
          if (months[monthKey]) {
            months[monthKey].totalAmt += item.totalDonationAmt;
            months[monthKey].count += 1;
          } else {
            months[monthKey] = {
              totalAmt: item.totalDonationAmt,
              count: 1
            };
          }
        });

        const result = [];
        for (const monthKey in months) {
          const monthData = months[monthKey];
          result.push({
            date: monthKey,
            totalDonationAmt: parseFloat(parseFloat(monthData.totalAmt).toFixed(2))
          });
        }
        return result;
      } else if (type === "onetime_vs_recurring") {
        data.forEach(item => {
          const date = new Date(item.date);
          const monthKey = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`;
          if (months[monthKey]) {
            months[monthKey].totalOneTimeTotalDonors += item.oneTimeTotalDonors;
            months[monthKey].totalRecurringTotalDonors += item.recurringTotalDonors;
            months[monthKey].count += 1;
          } else {
            months[monthKey] = {
              totalOneTimeTotalDonors: item.oneTimeTotalDonors,
              totalRecurringTotalDonors: item.recurringTotalDonors,
              count: 1
            };
          }
        });

        const result = [];
        for (const monthKey in months) {
          const monthData = months[monthKey];
          result.push({
            date: monthKey,
            oneTimeTotalDonors: parseFloat(parseFloat(monthData.totalOneTimeTotalDonors).toFixed(2)),
            recurringTotalDonors: parseFloat(parseFloat(monthData.totalRecurringTotalDonors).toFixed(2))
          });
        }
        return result;
      }
    } else {
      return data;
    }
  };

  return (
    <>
      {client !== "NONE" && (
        <div className="dashboard dynamic-card">

          {/*Giving event chart*/}
          <div className="row dashboard-row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <GiveEventChart eventType="average_donation_amount" urlSegment="average_donation_amount" data={average_donation_amount} isValid={isvalidAverage} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <GiveEventChart eventType="total_donation" urlSegment="total_donation" data={totalDonation} isValid={isvalidTotalDonation} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <GiveEventChart eventType="onetime_vs_recurring" urlSegment="onetime_vs_recurring" data={onetime_vs_recurring} isValid={isvalidOneTime} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <GiveEventChart eventType="frequency_distribution" urlSegment="frequency_distribution" data={donationFrequency} isValid={isvalidFrequency} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <GiveEventChart eventType="channels_performance" urlSegment="channels_performance" data={channels_performance} isValid={isvalidChannel} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <GiveEventChart eventType="campaign_performance" urlSegment="campaign_performance" data={campaign_performance} isValid={isvalidCampaign} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <GiveEventChart eventType="donation_source" urlSegment="donation_source" data={donation_source} isValid={isvalidSource} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <AvgCharts urlSegment="average_chart" btnActive={btnActive} isDropDown={isDropDown} dates={dates} isSelect={isSelect} setIsSelect={setIsSelect} datePopup={datePopup} setisLoading={setisLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <CityTable eventType="donation_city" urlSegment="donation_city" data={donation_city} isValid={isvalidCity} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
          </div>

          {/* One Time Donations Card */}
          <GivingCentralDynamicCard
            title="One Time Donations"
            formType="onetime_donation"
            background="linear-gradient(60deg, rgb(255, 200, 69), rgb(255, 158, 27))"
            headerIcon="attach_money"
            columns={oneTimeDonations}
            card={["Person", "Revenue", "Device"]}
            urlSegment="onetime_donation"
            dates={dates}
            isDropDown={isDropDown}
            isSelect={isSelect}
            setIsSelect={setIsSelect}
            datePopup={datePopup}
            btnActive={btnActive}
          />

          {/* Recurring Donations Card */}
          <GivingCentralDynamicCard
            title="Recurring Donations"
            formType="recurring_donation"
            background="linear-gradient(60deg, rgb(239, 83, 80), rgb(229, 57, 53))"
            headerIcon="attach_money"
            columns={recurringDonations}
            card={["Person", "Revenue", "Device"]}
            urlSegment="recurring_donation"
            dates={dates}
            isDropDown={isDropDown}
            isSelect={isSelect}
            setIsSelect={setIsSelect}
            datePopup={datePopup}
            btnActive={btnActive}
          />

        </div>
      )}
    </>
  );
};

export default GiveEvents;