import React from 'react';
import { useTable, usePagination, useFilters, useResizeColumns, useSortBy } from 'react-table';
import ReactTablePageResizer from './reactTablePageResizer';
import { Tooltip } from 'antd';



const TextFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
        <input
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value || undefined)}
            style={{ width: '100%' }}
        />
    );
}

const ReactDataTable = ({
    data,
    columns,
    isLoading,
    showFilter = true,
    showPaginationOnTop = true,
    showPaginationOnBottom = false,
    defaultPageSize = 5,
    showHideColumns = false,
    timeValue,
    excludedIndexFromHide,
    hiddenColumns,
    noDataFound,
    urlSegment,
    btnActive,
    isDropDown,
    handledownload
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page, // Instead of using 'rows', we now use 'page'
        prepareRow,
        state: { pageCount, pageSize, pageIndex }, // Get the current page index and page size
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        gotoPage,
        allColumns,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: defaultPageSize, hiddenColumns: hiddenColumns }, // Set the initial page index and page size
        },
        useFilters,
        useResizeColumns,
        useSortBy,
        usePagination, // Use the usePagination hook
    );

    const pageCountAfterFiltering = Math.ceil(rows.length / pageSize);

    const handlePageChange = (e) => {
        const newPageIndex = Number(e.target.value) - 1; // Subtract 1 to convert to zero-based index
        gotoPage(newPageIndex);
    };

    let bodyWidth = 0;

    if (headerGroups.length) {
        headerGroups.forEach((headerGroup) => {
            bodyWidth = headerGroup.headers.reduce((total, item) => total + (item.width || 0), 0);
        })
    }
    let tempIndex = 0;

    return (
        <div id='pages-subPages-table'>
            {showHideColumns && <div className="dropdown col-md-offset-10 col-md-2 text-right" style={{ marginBottom: '5px', paddingRight: '10px', paddingLeft: '25px', display: 'flex', justifyContent: 'end' }}>
                <Tooltip title="Maximize">
                    <span className="icon-title-icon" style={{ paddingTop: '9px', paddingRight: '11px' }}>
                        {urlSegment && <a href={`/admin/give-central/maximized/${urlSegment}/${btnActive}/distribution_table/date`} target='_blank' style={{ color: "#666666" }}><i className="material-icons">zoom_out_map</i></a>}
                    </span>
                </Tooltip>
                <Tooltip title="Download">
                    <span className="icon-title-icon" style={{ color: 'grey', cursor: 'pointer', paddingTop: '9px', width: '30px' }}><i className="material-icons" onClick={() => handledownload()}>download_2</i></span>
                </Tooltip>
                <button className="dropdown-toggle" style={{ margin: '0px', width: '35px', padding: '7px', background: 'white', border: 'none' }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <Tooltip title="Hide/Show Column">
                        <i className="material-icons" style={{ color: 'grey' }}>settings</i>
                    </Tooltip>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        allColumns.map((column, columnIndex) => (
                            <div key={column.id} style={{ marginLeft: '5px', cursor: 'pointer' }}>
                                {excludedIndexFromHide.includes(columnIndex) ? (
                                    <></>
                                ) : (
                                    <label style={{ cursor: 'pointer', color: 'black' }}>
                                        <input
                                            style={{ marginRight: '5px' }}
                                            type="checkbox"
                                            checked={column.getToggleHiddenProps().checked}
                                            onChange={() => column.toggleHidden()}
                                        />
                                        {column.Header}
                                    </label>
                                )}
                            </div>
                        ))
                    }
                </div>
            </div>}

            <div className='ReactTable -striped -highlight'>
                {showPaginationOnTop === true && (
                    <ReactTablePageResizer
                        previousPage={previousPage}
                        canPreviousPage={canPreviousPage}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        nextPage={nextPage}
                        defaultPageSize={defaultPageSize}
                        gotoPage={gotoPage}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        pageCountAfterFiltering={pageCountAfterFiltering}
                        canNextPage={canNextPage}
                    />
                )}
                <table {...getTableProps()} className='rt-table' role='grid' style={{ display: 'flex', flexDirection: 'column' }}>
                    <thead className='rt-thead -header' style={{ minWidth: `${bodyWidth}px`, boxShadow: 'none' }}>
                        {headerGroups.map((headerGroup) => (
                            <tr className='rt-tr' role='row' {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex', border: '1px solid rgba(0, 0, 0, 0.05)' }}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        key={index}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={`rt-th rt-resizable-header -cursor-pointer ${column.isSorted ? (column.isSortedDesc ? '-sort-desc' : '-sort-asc') : ''}`}
                                        role='columnheader'
                                        rowSpan={column.rowSpan || 1}
                                        colSpan={column.colSpan || 1}
                                        {...column.cellProps}
                                        style={{ flexBasis: `${column.width}px`, flexGrow: `${column.flexGrow}`, flexShrink: 0, background: 'rgb(249, 249, 249)', color: 'black', padding: '1em' }}
                                    >
                                        <div className='rt-resizable-header-content'>
                                            <div
                                                {...column.getResizerProps()}
                                                className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                            />
                                            <span>{column.render('Header')}</span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {showFilter === true && <thead className='rt-thead -filters' style={{ minWidth: `${bodyWidth}px` }}>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} className='rt-tr' role='row' {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex', overflow: "hidden" }}>
                                {headerGroup.headers.map((column, i) => (
                                    <th className={`rt-th`} key={i} style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0 }}>
                                        {column.canFilter && <TextFilter column={column} />}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>}
                    <tbody {...getTableBodyProps()} className='rt-tbody' style={{ minWidth: `${bodyWidth}px` }}>
                        {page.length > 0 && page.map((row, index) => {
                            prepareRow(row);
                            let className = index % 2 === 0 ? 'border-solid' : 'border-solid';
                            tempIndex = index >= 2 && index <= 29 && (index - 2) % 3 === 0;
                            className = timeValue !== "30_days" && timeValue !== "60_days" && timeValue !== '90_days' && timeValue !== '180_days' && timeValue !== '365_days' ? tempIndex ? 'border-top-solid' : ' ' : className;

                            return (
                                <tr key={index} {...row.getRowProps()} className={`rt-tr ${className}`} style={{ display: 'flex', overflow: "hidden", padding: '1em 0px' }}>
                                    {row.cells.map((cell, ind) => (
                                        <td key={ind} className={`rt-td ${cell.column.className || ''}`} style={{ flexBasis: `${cell.column.width}px`, flexGrow: 1, flexShrink: 0 }}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                        {page.length <= 0 && [1, 2, 3].map((row, index) => {
                            let className = index % 2 === 0 ? '' : '';
                            return (
                                <tr key={index} className={`rt-tr ${className}`} style={{ display: 'flex', padding: '1em 0px' }}>
                                    {headerGroups[0].headers.map((column, index) => (
                                        <td key={index} className='rt-td' style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0, borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
                                            <span className='noColourText'>NA</span>
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {page.length <= 0 && <div className="rt-noData"><strong>{noDataFound ? noDataFound : 'No rows found'}</strong></div>}

                {isLoading && <div className="-loading -active"><div className="-loading-inner">Loading...</div></div>}

                {showPaginationOnBottom === true && (
                    <ReactTablePageResizer
                        previousPage={previousPage}
                        canPreviousPage={canPreviousPage}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        nextPage={nextPage}
                        defaultPageSize={defaultPageSize}
                        gotoPage={gotoPage}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        pageCountAfterFiltering={pageCountAfterFiltering}
                        canNextPage={canNextPage}
                    />
                )}

            </div>
        </div>
    )
}

export default ReactDataTable;