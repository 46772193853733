import React, { useEffect, useState } from 'react';
import { SkeletonLine } from '../../Views/UI/skeletonLoader';


const DashboardCard = ({
    isLoading,
    topChannel,
    donors,
    revenue,
    pageViewTotal,
    type = ""
}) => {
    const [data, setData] = useState({ topChannel: {}, donors: {}, revenue: {}, viewsTotal: {} });
    useEffect(() => {
        setData({
            topChannel: topChannel,
            donors: donors,
            revenue: revenue,
            viewsTotal: pageViewTotal,
        });
    }, [topChannel, donors, revenue, pageViewTotal]);
    return (
        <div className="Dashboard-card row">

            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-stats">
                    <div className="card-header card-header-icon" style={{ background: 'linear-gradient(60deg,#FFC845,#FF9E1B)' }} data-background-color="linear-gradient(60deg,#FFC845,#FF9E1B)">
                     {type === "AbandonedGiftsLightbox" 
                     ? <i className="material-icons">ads_click</i> //find_in_page
                     : <i className="material-icons">emoji_events</i>
                     }   
                    </div>
                    <div className="card-content">
                        <p className="category"> {data?.topChannel.title === "Clicks" ? data?.topChannel.title : "Top Channel"}</p>
                        {isLoading ? (
                            <div className="skeleton">
                                <SkeletonLine style={{ height: '17px', width: '55%', marginLeft: 'auto' }} />
                                <SkeletonLine style={{ height: '12px', width: '100%' }} />
                                <SkeletonLine style={{ height: '8px', width: '50%' }} />
                                <SkeletonLine style={{ height: '12px', width: '75%' }} />
                            </div>
                        ) : (
                            <h3 className="card-title">{data.topChannel.name}</h3>
                        )}
                    </div>
                    <div className="card-footer">
                        <div className="stats">
                            {isLoading ? (
                                <div className="skeleton" style={{ 'display': 'block' }}>
                                    <SkeletonLine style={{ height: '8px', width: '50%' }} />
                                    <SkeletonLine style={{ height: '12px', width: '100%' }} />
                                </div>
                            ) : (
                                <>
                                {type === "AbandonedGiftsLightbox" 
                                ? ""
                                :
                                <div>
                                    <i className="material-icons insight-1">bar_chart</i> &nbsp;
                                    <span><b>${data.topChannel.totalAmount}</b> received from <b>{data.topChannel.totalGift}</b> gifts.</span>
                                </div>
                                }
                            </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-stats">
                    <div className="card-header card-header-icon" style={{ background: 'linear-gradient(60deg,#D50003,#BF0D3E)' }} data-background-color="linear-gradient(60deg,#D50003,#BF0D3E)">
                    {type === "AbandonedGiftsLightbox" 
                     ?  <i className="material-icons">people</i>
                     :  <i className="material-icons">people</i>
                    }   
                    </div>
                    <div className="card-content">
                        <p className="category"> {data?.donors.title === "Donations" ? data?.donors.title : "Donors"}</p>
                        {isLoading ? (
                            <div className="skeleton">
                                <SkeletonLine style={{ height: '17px', width: '55%', marginLeft: 'auto' }} />
                                <SkeletonLine style={{ height: '12px', width: '100%' }} />
                                <SkeletonLine style={{ height: '8px', width: '50%' }} />
                                <SkeletonLine style={{ height: '12px', width: '75%' }} />
                            </div>
                        ) : (
                            <h3 className="card-title">{data.donors.totalDonors}</h3>
                        )}
                    </div>
                    <div className="card-footer">
                        <div className="stats">
                            {isLoading ? (
                                <div className="skeleton">
                                    <SkeletonLine style={{ height: '8px', width: '50%' }} />
                                    <SkeletonLine style={{ height: '12px', width: '100%' }} />
                                </div>
                            ) : (
                                <>
                                {type === "AbandonedGiftsLightbox" 
                                ? ""
                                :
                                <div>
                                    <i className="material-icons insight-2">fiber_new</i> &nbsp;
                                    <span>Average gift of <b>${data.donors.avgGift}</b>.</span>
                                </div>
                                }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-stats">
                    <div className="card-header card-header-icon" style={{ background: 'linear-gradient(60deg,#006272,#003B49)' }} data-background-color="linear-gradient(60deg,#006272,#003B49)">
                    {type === "AbandonedGiftsLightbox" 
                     ?  <i className="material-icons">monetization_on</i>
                     :  <i className="material-icons">monetization_on</i>
                    }   
                    </div>
                    <div className="card-content">
                        <p className="category"> {data?.revenue.title === "Donation Revenue" ? data?.revenue.title : "Revenue"}</p>
                        {isLoading ? (
                            <div className="skeleton">
                                <SkeletonLine style={{ height: '17px', width: '55%', marginLeft: 'auto' }} />
                                <SkeletonLine style={{ height: '12px', width: '100%' }} />
                                <SkeletonLine style={{ height: '8px', width: '50%' }} />
                                <SkeletonLine style={{ height: '12px', width: '75%' }} />
                            </div>
                        ) : (
                            <h3 className="card-title">${data.revenue.totalRevenue}</h3>
                        )}
                    </div>
                    <div className="card-footer">
                        <div className="stats">
                            {isLoading ? (
                                <div className="skeleton">
                                    <SkeletonLine style={{ height: '8px', width: '50%' }} />
                                    <SkeletonLine style={{ height: '12px', width: '100%' }} />
                                </div>
                            ) : (
                                <>
                                {type === "AbandonedGiftsLightbox" 
                                ? ""
                                :
                                <div>
                                    <i className="material-icons insight-3">calculate</i> &nbsp;
                                    <span>One-time gifts: <b>${data.revenue.totalOneTimeRevenue}</b>, Initial sustaining gifts: <b>${data.revenue.totalRecurringRevenue}</b>.</span>
                                </div>
                                }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-stats">
                    <div className="card-header card-header-icon" style={{ background: 'linear-gradient(60deg,#0093B2,#00617F)' }} data-background-color="linear-gradient(60deg,#0093B2,#00617F)">
                        {type === "AbandonedGiftsLightbox" 
                            ?  <i className="material-icons">volunteer_activism</i>
                            :  <i className="material-icons">volunteer_activism</i>
                        }  
                    </div>
                    <div className="card-content">
                    <p className="category"> {data?.viewsTotal.title === "Conversion Rate" ? data?.viewsTotal.title : "Page Views"}</p>
                        {isLoading ? (
                            <div className="skeleton">
                                <SkeletonLine style={{ height: '17px', width: '55%', marginLeft: 'auto' }} />
                                <SkeletonLine style={{ height: '12px', width: '100%' }} />
                                <SkeletonLine style={{ height: '8px', width: '50%' }} />
                                <SkeletonLine style={{ height: '12px', width: '75%' }} />
                            </div>
                        ) : (
                            <h3 className="card-title">{data?.viewsTotal.views}</h3>
                        )}
                    </div>
                    <div className="card-footer">
                        <div className="stats">
                            {isLoading ? (
                                <div className="skeleton">
                                    <SkeletonLine style={{ height: '8px', width: '50%' }} />
                                    <SkeletonLine style={{ height: '12px', width: '100%' }} />
                                </div>
                            ) : (
                                <>
                                {type === "AbandonedGiftsLightbox" 
                                ? ""
                                :
                                <div>
                                    <i className="material-icons insight-4">access_time</i> &nbsp;
                                    <span>20 Second Engagements: <b>{data.viewsTotal.engaged}</b></span>
                                </div>
                                }
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardCard
