import React from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { addLeadingZero } from '../../Helper/helper';

const PieCharts = ({
    data = [],
    isValid = false,
    segment,
}) => {

    const COLORS = ['#004A6C','#0E7D9F','#41B0D2','#1A237E','#303F9F','#3F51B5','#263238','#455A64','#607D8B'];

    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <>
                        <div>
                            <p className={!segment ? `average` : 'central-tooltip'} style={{ color: '#287d9f' }}>{`${payload[0].payload.name}: $${addLeadingZero(payload[0].payload.totalamount)}`}</p>
                        </div>
                    </>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer className="pieChart" width={"100%"} height={segment ? 300 : 450}>
                {
                    !isValid ?
                        <PieChart width={800} height={300}>
                            <Pie
                                data={data}
                                cx={'50%'}
                                cy={'50%'}
                                innerRadius={100}
                                outerRadius={130}
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="totalamount"

                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill="#f3f6f9" />
                                ))}
                            </Pie>
                        </PieChart>
                        : <PieChart width={800} height={300}>
                            <Pie
                                data={data}
                                cx={'50%'}
                                cy={'50%'}
                                innerRadius={90}
                                outerRadius={120}
                                fill="#8884d8"
                                paddingAngle={1}
                                dataKey="totalamount"

                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend />
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                }

            </ResponsiveContainer>
        </div>
    )
}

export default PieCharts