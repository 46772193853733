import React from "react";
import { Card } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const DashbaordCard = ({
  range,
  revenueData,
  cards,
  mobileCount,
  tabletCount,
  desktopCount,
  isLoading
}) => {

  return (
    <>
      {cards.includes("Person") && (
        <Card>
          <i className="material-icons">person</i>
          <strong style={{ fontSize: "12px" }}>{range}</strong>
        </Card>
      )}

      {cards.includes("Revenue") && (
        <div>
          <Card>
            <i className="material-icons">attach_money</i>
            Revenue <br />
            {isLoading ? (
              <LoadingOutlined />
            ) : (
              <strong>${revenueData === "" ? "0.00" : revenueData} </strong>
            )}
            <br />
            <strong style={{ fontSize: "12px" }}>{range}</strong>
          </Card>
        </div>
      )}

      {cards.includes("Device") && (
        <Card>
          <i className="material-icons">important_devices</i>
          Device types: <br />
          <strong>Mobile:</strong> {isLoading ? <LoadingOutlined /> : mobileCount} <br />
          <strong>Tablet:</strong> {isLoading ? <LoadingOutlined /> : tabletCount} <br />
          <strong>Desktop:</strong> {isLoading ? <LoadingOutlined /> : desktopCount} <br />
          <strong style={{ fontSize: "12px" }}>{range}</strong>
        </Card>
      )}
    </>
  );
};

export default DashbaordCard;