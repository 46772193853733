/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Modal, Row } from 'antd';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import * as ss from 'simple-statistics'
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import { Tooltip as AntdTooltip } from 'antd';

dayjs.extend(weekday);
dayjs.extend(localeData);


//Plugins
import moment from 'moment';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { replaceArrayKeys } from "../../Helper/helper";

const { RangePicker } = DatePicker;

const AvgCharts = ({
    urlSegment,
    btnActive,
    isDropDown,
    dates,
    isSelect,
    setIsSelect,
    datePopup
}) => {

    const { client, IdentityApi } = useSelector(({ main }) => main);
    const [activeTab, setActiveTab] = useState("oneTime");
    const [average, setaverage] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [ApiData, setApiData] = useState([]);
    const [disableStartDate, setDisableStartDate] = useState('');
    const { user: authUser } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (isDropDown !== "custom-date" && isDropDown !== "Select") {
            fetchData();
            setIsSelect(false);
        }
    }, [client, isDropDown]);

    useEffect(() => {
        if (isSelect && !datePopup && dates.startDate && dates.endDate) {
            fetchData();
        }
    }, [datePopup]);

    useEffect(() => {
        get_donations_start_date();
    }, [client]);

    const get_donations_start_date = async () => {
        try {
            const formData = {
                client: client.toLowerCase(),
                donation_type: 'onetime'
            };

            const results = await axios.post(`${IdentityApi}query/get_donations_start_date`, formData);

            if (results.data.status === 'success') {
                const date = results.data.data.find((res) => res.unixdate);
                setDisableStartDate(date.unixdate);
                let daysDifference = dayjs(dates.startDate).diff(date.unixdate, 'days');

                // if (daysDifference < 0) {
                //     setDates({
                //         startDate: dayjs(date.unixdate),
                //         endDate: dayjs().subtract(1, 'days'),
                //     });
                // }
            }
        } catch (error) {
            let Array = [];
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            //calculate dashbard Rechart  
            for (let i = 0; i <= dateDifference; i++) {
                // Get the current date
                const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                Array.push({ date: currentDate, average: 0, qty: 0, y: 0 });
            }
            setData(Array)
            setApiData({})
            setisLoading(false)
            console.error("An error occurred:", error);
        }
    };


    const fetchData = async (tab) => {
        try {
            setisLoading(true);

            // Parse start and end dates
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            const donationType = tab ? tab : activeTab;

            const formData = {
                start_date: dayjs(dates.startDate).format('YYYY/MM/DD'),
                end_date: dayjs(dates.endDate).format('YYYY/MM/DD'),
                client: client.toLowerCase(),
                donation_type: donationType.toLowerCase()
            };

            const results = await axios.post(`${IdentityApi}query/get_donations`, formData);

            if (results.data.status === 'success') {
                const countsData = results.data.data;
                setApiData(results.data.data)
                const Array = [];

                for (let i = 0; i <= dateDifference; i++) {
                    const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                    const matchingData = countsData[currentDate] || [];
                    const transactionAmount = matchingData.reduce((total, item) => total + (parseFloat(item.transaction_amount) || 0), 0);
                    const total = matchingData.length;
                    const average = total ? transactionAmount / total : 0;

                    Array.push({ date: currentDate, average: parseFloat(average.toFixed(2)), qty: total });
                }

                //reference line 
                const dates = [];
                const values = [];

                Array.map((entry) => {
                    const date = entry.date;
                    const value = entry.average;
                    dates.push(date);
                    values.push(value);
                });

                const regression = ss.linearRegression(
                    values.map((value, index) => [index, value])
                );
                const predict = ss.linearRegressionLine(regression);
                const predictedValues = dates.map((date, index) => ({
                    date: date,
                    y: parseFloat(predict(index).toFixed(2)),
                }));
                Array && Array.forEach(item => {
                    const matchingItem = predictedValues.find(d => d.date === item.date);
                    if (matchingItem) {
                        item.y = matchingItem.y;
                    }
                });
                let result = aggregateData(Array, dateDifference);
                setData(result);
                setaverage(result);
                setisLoading(false)
            }
        } catch (error) {
            let Array = [];
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            //calculate dashbard Rechart  
            for (let i = 0; i <= dateDifference; i++) {
                // Get the current date
                const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                Array.push({ date: currentDate, average: 0, qty: 0, y: 0 });
            }
            setData(Array)
            setApiData({})
            setisLoading(false)
            console.error("An error occurred:", error);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        fetchData(tab)
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setData(tabData['average']);
        }, 5);

        return () => clearTimeout(timer);
    }, [activeTab, average]);

    const tabData = {
        average: average
    };

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            urlSegment ?
                <g transform={`translate(${x},${y})`}>
                    <text x={20} y={14} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                        {payload.value}
                    </text>
                </g>
                : <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                        {payload.value}
                    </text>
                </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="date">{`${payload[0].payload.date}`}</p>
                        <>
                            <div>
                                <p className={!urlSegment ? `average` : 'central-tooltip'}>{`Average: $${payload[0].payload.average}`}</p>
                            </div>
                            <div>
                                <p className={!urlSegment ? `average` : 'central-tooltip'}>{`Quantity: ${payload[0].payload.qty}`}</p>
                            </div>
                            <div>
                                <p className={!urlSegment ? `y` : 'central-tooltip'}>{`Trend Line: $${payload[1].payload.y}`}</p>
                            </div>
                        </>
                </div>
            );
        }
        return null;
    };

    const handledownload = () => {
        setIsModalOpen(true);
    }

    const handleOk = async () => {
        let array = replaceArrayKeys(data, [{ old: "average", new: "Average" }, { old: "qty", new: "Quantity" }, { old: "y", new: "Trend Line" }]);
        setisLoading(true);
        try {
            const formData = {
                client: client,
                startDate: dayjs(dates?.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(dates?.endDate).format('YYYY-MM-DD'),
                email: authUser.email,
                userName: authUser.name,
                title: "Average Donation with Trend Line",
                data: array,
                origin: window.origin
            };
            const results = await axios.post(`${IdentityApi}query/downloadData`, formData);

            if (results.status === 200 && results.data.status === 'success') {
                window.swal({ title: "Success", text: 'Email send successfully.', icon: "success", button: "OK" });
                setisLoading(false);
                setIsModalOpen(false);
            } else {
                window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
                setisLoading(false);
                setIsModalOpen(false);
            }
        } catch (error) {
            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
            setisLoading(false);
            setIsModalOpen(false);
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const aggregateData = (data, dateDifference) => {

        if (dateDifference >= 90) {
            const months = {};
            data.forEach(item => {
                const date = new Date(item.date);
                const monthKey = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`;
                if (months[monthKey]) {
                    months[monthKey].totalAverage += item.average;
                    months[monthKey].totalQty += item.qty;
                    months[monthKey].totalY += item.y;
                    months[monthKey].count += 1;
                } else {
                    months[monthKey] = {
                        totalAverage: item.average,
                        totalQty: item.qty,
                        totalY: item.y,
                        count: 1
                    };
                }
            });

            const result = [];
            for (const monthKey in months) {
                const monthData = months[monthKey];
                result.push({
                    date: monthKey,
                    average: parseFloat(parseFloat(monthData.totalAverage).toFixed(2)),
                    qty: parseFloat(parseFloat(monthData.totalQty).toFixed(2)),
                    y: parseFloat(parseFloat(monthData.totalY).toFixed(2))
                });
            }
            return result;
        } else {
            return data;
        }
    };
    return (
        <div className="avg-chart">
            <div className={urlSegment ? "" : "avg-card"} style={{ paddingLeft: '0px', paddingTop: '20px' }}>
                {urlSegment &&
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="card-title">Average Donation with Trend Line</div>
                        </div>
                    </div>
                }
                <div className="card" style={{ maxHeight: urlSegment ? '350px' : '' }}>
                    <div className="card-content">
                        <>
                            <div className="row">
                                <div className="col-sm-12 col-md-8 col-md-offset-1">
                                    <h4 className="card-title">
                                        {/* <RangePicker
                                        value={[dates.startDate, dates.endDate]}
                                        onChange={(date, dateString) => setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) })}
                                        allowClear={false}
                                        size={"middle"}
                                        bordered={"false"}
                                        format={"YYYY-MM-DD"}
                                        disabledDate={(current) => current && current < dayjs(disableStartDate)}
                                        separator={<i className="material-icons">arrow_right_alt</i>}
                                    /> */}
                                    </h4>
                                </div>
                            </div>
                        </>
                        <div className="row">
                            <div className={`col-md-12 line-chart-container`}>
                                <div className='row'>
                                    <div className={`${urlSegment ? 'event-icons' : 'event-icons-download'}`}>
                                        {!isLoading && urlSegment &&
                                            <>
                                                <AntdTooltip title="Maximize">
                                                    {urlSegment && <a href={`/admin/give-central/maximized/${urlSegment}/${btnActive}/${isDropDown}/${dayjs(dates?.startDate).format('YYYY-MM-DD')}_${dayjs(dates?.endDate).format('YYYY-MM-DD')}`} target='_blank' style={{ color: "#666666" }}><span className="icon-title-icon"><i className="material-icons">zoom_out_map</i></span></a>}
                                                </AntdTooltip>
                                                <AntdTooltip title="Download">
                                                    <span className='icon-title-icon' onClick={() => handledownload()}><i className="material-icons avg-download-icon">download_2</i></span>
                                                </AntdTooltip>
                                            </>
                                        }
                                        {!urlSegment && <div className="tab-container">
                                            <div className="btn-group btn-group-sm">
                                                <button className={`btn tabs ${activeTab === "oneTime" && 'tabshover'}`} onClick={() => handleTabChange("oneTime")}>
                                                    One Time
                                                </button>
                                                <button className={`btn tabs ${activeTab === "recurring" && 'tabshover'}`} onClick={() => handleTabChange("recurring")}>
                                                    Recurring
                                                </button>
                                            </div>
                                        </div>}
                                        {!urlSegment &&
                                            <div className={`${!urlSegment && 'download-icon'}`}>
                                                <AntdTooltip title="Download">
                                                    <span className='icon-title-icon'><i className="material-icons" onClick={() => handledownload()}>download_2</i></span>
                                                </AntdTooltip>
                                            </div>
                                        }
                                    </div>
                                    {!isLoading &&
                                        <>
                                            <div className='charts'>
                                                <ResponsiveContainer width={"100%"} height={urlSegment ? 300 : 450}>
                                                    <LineChart data={data}>
                                                        <XAxis
                                                            allowDataOverflow
                                                            dataKey="date"
                                                            tick={<CustomizedAxisTick />}
                                                            domain={['dataMin', 'dataMax']}
                                                            interval={!urlSegment ? data ? data.length < 31 ? 0 : Math.round(data.length / 30) : 0 : ''}
                                                            height={70}
                                                            padding={{
                                                                right: 6,
                                                                left: 4,
                                                            }}
                                                            label={{
                                                                value: `Time (days)`,
                                                                style: { textAnchor: 'middle' },
                                                                position: 'insideBottom',
                                                            }}
                                                        />

                                                        <YAxis
                                                            tickFormatter={(value) => `$${parseInt(value)}`}
                                                            allowDataOverflow={true}
                                                            interval="preserveStartEnd"
                                                            width={100}
                                                            padding={{
                                                                top: 10,
                                                                bottom: 4
                                                            }}
                                                            label={{
                                                                value: `Total Donation Amount`,
                                                                style: { textAnchor: 'middle' },
                                                                angle: -90,
                                                                position: 'left',
                                                                offset: -30,
                                                            }}
                                                        />
                                                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                                                        {Object.keys(ApiData).length ?
                                                            <Tooltip content={<CustomTooltip />} />
                                                            : ''
                                                        }
                                                        <Line strokeWidth={2.5} dot={true} type="monotone" dataKey='average' stroke={Object.keys(ApiData).length ? "#4c9ef5" : "white"} />
                                                        <Line strokeWidth={2.5} dot={false} type="monotone" strokeDasharray="5 5" dataKey="y" stroke={Object.keys(ApiData).length ? "red" : "white"} />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            </div>
                                            {Object.keys(ApiData).length === 0 && (
                                                <div className='rechart-text' style={{ fontSize: '15px' }}>Data collection in progress</div>
                                            )}
                                        </>
                                    }
                                </div>
                            </div>
                            {/* <div className="col-md-3 tab-container"> */}

                            {/* <div className="dashbaord-chart-containt">
                                        {activeTab === "oneTime" && (
                                            <div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="average-icon1" />
                                                    <div className="ml-5"><span className="icons-containt">Average Donation</span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="average-icon2" />
                                                    <div className="ml-5"><span className="icons-containt">Trend Line </span></div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "recurring" && (
                                            <div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="average-icon1" />
                                                    <div className="ml-5"><span className="icons-containt">Average Donation </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="average-icon2" />
                                                    <div className="ml-5"><span className="icons-containt">Trend Line </span></div>
                                                </div>
                                            </div>
                                        )}
                                    </div> */}
                            {/* </div> */}
                        </div>
                        {isLoading &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="skeleton skeleton-chart">
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                    </div>
                                </div>
                            </div>
                        }
                        <Modal
                            open={isModalOpen}
                            // onOk={handleOk}
                            onCancel={handleCancel}
                            footer={[]}
                        >
                            <div className="download-modal">
                                <p>Downloading</p>
                                <strong>Average Donation with Trend Line</strong>
                                <span>You are about to download the data for Average Donation with Trend Line. Download link will be sent to the following Email.</span>
                                <Row className="profile">
                                    <Col>
                                        <img src={authUser.picture} width={50} height={50} />
                                    </Col>
                                    <Col style={{ padding: "0px 10px" }}>
                                        <span>{authUser.name}</span><br />
                                        <span>{authUser.email}</span>
                                    </Col>
                                </Row>
                                <Button className="send-download-link" key="submit" type="primary" onClick={handleOk}>
                                    Send Download Link
                                    {isLoading ? <LoadingOutlined /> : <i className="material-icons">send</i>}
                                </Button>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>)
}

export default AvgCharts;
