// packages
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Switch } from "antd";
import dayjs from "dayjs";


//import components
import { ChangeInitial, getClientDashboard } from "../../redux/Main/action";
import ClientChoose from "../Clients/chooseClient";
import DashboardCard from "../../Components/DashbaordCard/DashboardCard";
import DashboardChart from "../../Components/DashboardChart/DashboardChart";
import { IdentityApi } from "../../utils/constants";
import axios from "axios";
import moment from "moment";

const { RangePicker } = DatePicker;

const Home = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { role, client, clientDashboardData } = useSelector(({ main }) => main);

  const [isLoading, setIsLoading] = useState(true);
  const [counter, setCounter] = useState(0);
  const [mode, setMode] = useState("live");
  const [dates, setDates] = useState({
    startDate: dayjs().subtract(30, "days"),
    endDate: dayjs().subtract(1, "days"),
  });
  const [isGiftArrayEmpty, setIsGiftArrayEmpty] = useState(true);
  const [giftTab, setGiftTab] = useState({
    RechartGiftArray: [],
    giftLeadingSource: "",
    giftLeadingCreative: "",
    giftPercentage: "",
    giftAverageAmount: "",
    totalGifts: "",
    giftsfirsttimedonorcount: "",
    giftsRecurring: "",
    GiftLeadingZipcode: "",
  });
  const [isArrayEmpty, setIsArrayEmpty] = useState(true);
  const [revenueTab, setRevenueTab] = useState({
    RechartArray: [],
    leadingCreative: "",
    leadingSource: "",
    revenuePercentage: "",
    revenueAverageAmount: "",
    revenueTotalAmount: "",
    RevenueLeadingZipcode: "",
  });
  const [cardsDetails, setCardsDetails] = useState({
    topChannel: { name: "-", totalAmount: 0, totalGift: 0 },
    donors: { totalDonors: 0, avgGift: 0 },
    revenue: {
      totalRevenue: 0,
      avgGift: 0,
      conversionRate: 0,
      totalOneTimeRevenue: 0,
      totalRecurringRevenue: 0,
    },
    viewsEngagedTotal: { views: 0, engaged: 0 },
  });

  let channelsCounts = {
    email: {
      name: "Email",
      totalamount: 0,
      totalgift: 0,
    },
    directMail: {
      name: "Direct Mail",
      totalamount: 0,
      totalgift: 0,
    },
    social: {
      name: "Social",
      totalamount: 0,
      totalgift: 0,
    },
    cpc: {
      name: "CPC",
      totalamount: 0,
      totalgift: 0,
    },
    directtraffic: {
      name: "Direct Traffic",
      totalamount: 0,
      totalgift: 0,
    },
    referraltraffic: {
      name: "Referral Traffic",
      totalamount: 0,
      totalgift: 0,
    },
    search: {
      name: "Search",
      totalamount: 0,
      totalgift: 0,
    },
    display: {
      name: "Display",
      totalamount: 0,
      totalgift: 0,
    },
    paidfacebook: {
      name: "Paid Facebook",
      totalamount: 0,
      totalgift: 0,
    },
    other: {
      name: "Other",
      totalamount: 0,
      totalgift: 0,
    },
  };
  let donorCounts = {
    total: 0,
    average: 0,
    amount: 0,
    oneTime: 0,
    recurring: 0,
  };
  let pageViewsEngaged20Total = {
    pageviews: 0,
    engaged_20: 0
  };
  let revenueArray = {
    creativeArray: {},
    sourceArray: {},
    ipPostalArray: {},
    overAll: {},
  };
  let giftArray = {
    creativeArray: {},
    sourceArray: {},
    ipPostalArray: {},
    overAll: {},
  };

  useEffect(() => {
    dispatch(ChangeInitial("Home"));
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    if (client !== "NONE") {
      // getting client dashboard data
      const tableData = {
        client: client, //clientName
        startDate: dates.startDate.format("YYYY-MM-DD"),
        endDate: dates.endDate.format("YYYY-MM-DD"),
      };
      fetchClientDashboardData(tableData);
    }
  }, [client]);

  const fetchClientDashboardData = async (tableData) => {

    const response = await axios.post(
      `${IdentityApi}query/getClientDashboard`,
      tableData
    );

    if (response.data.status === "success" && response.data.data.length) {

      const preSignedUrl = response.data.data[0];
      const fromResponse = await axios.get(preSignedUrl);

      // store the Client Dashboard data
      dispatch(getClientDashboard(fromResponse.data));

    } else {
      window.swal({
        text: "No data found",
        type: "error",
        confirmButtonClass: "btn btn-success",
      });

      resetStateData();
      setIsLoading(false);
      dispatch(getClientDashboard([]));
      setCounter(counter + 1);
    }
  };

  useEffect(() => {
    setAllData();
  }, [clientDashboardData]);

  const setAllData = () => {
    setIsLoading(true);
    console.log("clientDashboardData", clientDashboardData);
    for (const key in clientDashboardData) {
      const element = clientDashboardData[key];
      const tempChannelsCount = JSON.parse(element.topChannelData);
      let pageViewsTotal = JSON.parse(element.pageViewsEngaged20Total);
      pageViewsEngaged20Total.pageviews += pageViewsTotal?.pageviews ? parseInt(pageViewsTotal?.pageviews) : 0;
      pageViewsEngaged20Total.engaged_20 += pageViewsTotal?.engaged_20 ? parseInt(pageViewsTotal?.engaged_20) : 0;
      // *************** Cards Calculations: ***************
      // Card-1: Calculate Top-Channels.
      Object.keys(tempChannelsCount).forEach((channel, index) => {
        let actualChannelName = ["directeMail", "directMail"].includes(channel)
          ? "directMail"
          : channel;
        channelsCounts[actualChannelName].totalamount = parseFloat(
          (
            channelsCounts[actualChannelName].totalamount +
            parseFloat(tempChannelsCount[channel].totalamount)
          ).toFixed(2)
        );
        channelsCounts[actualChannelName].totalgift =
          channelsCounts[actualChannelName].totalgift +
          parseFloat(tempChannelsCount[channel].totalgift);
      });

      // Card-2: Calculate Donors.
      let tempDonors = JSON.parse(element.donors);
      donorCounts.total += tempDonors?.length;
      if (tempDonors?.length) {
        // Card-3: Calculate Revenues.
        let oneTime = tempDonors.filter((e) => e?.onetimerevenue);
        let recurring = tempDonors.filter((e) => e?.recurringrevenue);
        let amounts = tempDonors.filter((e) => e?.amount);
        const oneTimeSum = oneTime?.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.amount);
        }, 0);
        const recurringSum = recurring?.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.amount);
        }, 0);
        const amountsSum = amounts?.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.amount);
        }, 0);
        donorCounts.oneTime += parseFloat(oneTimeSum);
        donorCounts.recurring += parseFloat(recurringSum);
        donorCounts.amount += parseFloat(amountsSum);
      }

      // *************** Line Chart Calculation: ***************
      // ======== for Leading Creative ========
      // for Revenue & Gift
      let creativeData = JSON.parse(element.creativeData);
      setRevenueGiftArray(
        creativeData,
        revenueArray.creativeArray,
        giftArray.creativeArray,
        element.transaction_date
      );
      // ======== for Leading Source ========
      // for Revenue & Gift
      let sourceData = JSON.parse(element.sourceData);
      setRevenueGiftArray(
        sourceData,
        revenueArray.sourceArray,
        giftArray.sourceArray,
        element.transaction_date
      );
      // ======== for Leading ZipCode ========
      // for Revenue & Gift
      let ipPostalData = JSON.parse(element.ipPostalData);
      setRevenueGiftArray(
        ipPostalData,
        revenueArray.ipPostalArray,
        giftArray.ipPostalArray,
        element.transaction_date
      );

      // for Overall Revenue
      if (tempDonors?.length) {
        tempDonors?.forEach((donor, index) => {
          revenueArray.overAll[element.transaction_date] = {
            count:
              (revenueArray.overAll[element.transaction_date]?.count || 0) + 1,
            amount:
              (revenueArray.overAll[element.transaction_date]?.amount || 0) +
              parseFloat(donor.amount),
          };
          giftArray.overAll[element.transaction_date] = {
            count:
              (revenueArray.overAll[element.transaction_date]?.count || 0) + 1,
            amount:
              (giftArray.overAll[element.transaction_date]?.amount || 0) +
              parseFloat(donor.amount),
          };
        });
      }
    }

    if (Object.values(channelsCounts)?.length > 0) {
      const topItem = Object.values(channelsCounts)?.reduce((prev, current) =>
        +prev.totalamount > +current.totalamount ? prev : current
      );
      cardsDetails["topChannel"].name =
        topItem.totalamount > 0 ? topItem.name : "-";
      cardsDetails["topChannel"].totalAmount =
        topItem.totalamount > 0 ? addLeadingZero(topItem.totalamount) : 0;
      cardsDetails["topChannel"].totalGift =
        topItem.totalamount > 0 ? addLeadingZero(topItem.totalgift) : 0;
    }

    cardsDetails["viewsEngagedTotal"].views = pageViewsEngaged20Total?.pageviews;
    cardsDetails["viewsEngagedTotal"].engaged = pageViewsEngaged20Total?.engaged_20;

    if (Object.values(donorCounts)?.length > 0) {
      // for Calculate Donors.
      const avg =
        donorCounts.amount &&
        (donorCounts.amount / donorCounts.total).toFixed(2);
      donorCounts.average = Number(avg);
      cardsDetails["donors"].totalDonors = donorCounts?.total
        ? addLeadingZero(donorCounts.total)
        : 0;
      cardsDetails["donors"].avgGift = donorCounts?.average
        ? addLeadingZero(donorCounts.average)
        : 0;
      // for Calculate Revenues.
      cardsDetails["revenue"].totalRevenue = donorCounts?.amount
        ? addLeadingZero(donorCounts.amount)
        : 0;
      cardsDetails["revenue"].totalOneTimeRevenue = donorCounts?.oneTime
        ? addLeadingZero(donorCounts.oneTime)
        : 0;
      cardsDetails["revenue"].totalRecurringRevenue = donorCounts?.recurring
        ? addLeadingZero(donorCounts.recurring)
        : 0;
    }
    setCardsDetails(cardsDetails);
    setCounter(counter + 1);

    if (
      Object.keys(revenueArray?.["creativeArray"])?.length ||
      Object.keys(revenueArray?.["sourceArray"])?.length ||
      Object.keys(revenueArray?.["ipPostalArray"])?.length ||
      Object.keys(revenueArray.overAll)?.length ||
      Object.keys(giftArray?.["creativeArray"])?.length ||
      Object.keys(giftArray?.["sourceArray"])?.length ||
      Object.keys(giftArray?.["ipPostalArray"])?.length ||
      Object.keys(giftArray.overAll)?.length
    ) {
      // for Revenue
      const creativeChart = revenueArray["creativeArray"];
      const sourceChart = revenueArray["sourceArray"];
      const ipPostalChart = revenueArray["ipPostalArray"];
      const overAllChart = revenueArray["overAll"];

      // for Gift
      const creativeGiftChart = giftArray["creativeArray"];
      const sourceGiftChart = giftArray["sourceArray"];
      const ipPostalGiftChart = giftArray["ipPostalArray"];

      // array for Revenue
      const creativeArray = handleArray(creativeChart);
      const sourceArray = handleArray(sourceChart);
      const ipPostalArray = handleArray(ipPostalChart);
      const overAllArray = handleArrayforOverAll(overAllChart);

      // array for Gift
      const creativeGiftArray = handleArray(creativeGiftChart, 'gift');
      const sourceGiftArray = handleArray(sourceGiftChart, 'gift');
      const ipPostalGiftArray = handleArray(ipPostalGiftChart, 'gift');


      // revenue max amount creative, source, ipPostal name    
      const creativeAmount = aggregateData(creativeArray);
      const sourceAmount = aggregateData(sourceArray);
      const ipPostalAmount = aggregateData(ipPostalArray); 
      const maxCreative = handleHigherValueName(creativeAmount, 'amount');
      const maxSource = handleHigherValueName(sourceAmount, 'amount');
      const maxIpPostal = handleHigherValueName(ipPostalAmount, 'amount');

      //gift max amount creative, source, ipPostal name
      const creativeGiftCount = aggregateData(creativeGiftArray);
      const sourceGiftCount = aggregateData(sourceGiftArray);
      const ipPostalGiftCount = aggregateData(ipPostalGiftArray);
      const maxCreativeGift = handleHigherValueName(creativeGiftCount, 'count');
      const maxSourceGift = handleHigherValueName(sourceGiftCount, 'count');
      const maxIpPostalGift = handleHigherValueName(ipPostalGiftCount, 'count');

      let revenueCount = 0, revenueTotalAmount = 0, revenueAverageAmount = 0, giftCount = 0, giftTotalAmount = 0, giftAverageAmount = 0;

      creativeArray && creativeArray.forEach(item => {
        if (item.name === maxCreative) {
          revenueCount += item.count ? parseInt(item.count) : 0;
          revenueTotalAmount += item.amount ? item.amount : 0;
        }
        if (item.name === maxCreativeGift) {
          giftCount += item.count ? parseInt(item.count) : 0;
          giftTotalAmount += item.amount ? item.amount : 0;
        }
      });

      // Revenue average amount
      revenueAverageAmount = revenueTotalAmount ? revenueTotalAmount / revenueCount : 'N/A';

      // Gift average amount
      giftAverageAmount = giftTotalAmount ? giftTotalAmount / giftCount : 'N/A';

      let sourceArrayData = sourceArray.filter((item) => item.name === maxSource);
      let creativeArrayData = creativeArray.filter((item) => item.name === maxCreative);
      let ipPostalArrayData = ipPostalArray.filter((item) => item.name === maxIpPostal);

      let sourceGiftArrayData = sourceGiftArray.filter((item) => item.name === maxSourceGift);
      let creativeGiftArrayData = creativeGiftArray.filter((item) => item.name === maxCreativeGift);
      let ipPostalGiftArrayData = ipPostalGiftArray.filter((item) => item.name === maxIpPostalGift);

      const finalData = daysDiffData(
        overAllArray,
        sourceArrayData,
        creativeArrayData,
        ipPostalArrayData,
        sourceGiftArrayData,
        creativeGiftArrayData,
        ipPostalGiftArrayData
      );

      if (revenueCount > 0 || revenueTotalAmount > 0) {
        setIsArrayEmpty(false);
      }

      if (giftCount > 0 || giftTotalAmount > 0) {
        setIsGiftArrayEmpty(false);
      }

      setRevenueTab({
        RechartArray: finalData.RevenueArrayData,
        leadingCreative: maxCreative || "",
        leadingSource: maxSource || "",
        RevenueLeadingZipcode: maxIpPostal || "",
        revenueAverageAmount: revenueAverageAmount
          ? "$" + parseFloat(revenueAverageAmount).toFixed(2) : 0,
        revenueTotalAmount: revenueTotalAmount
          ? "$" + addLeadingZero(revenueTotalAmount) : 0,
      });

      setGiftTab({
        RechartGiftArray: finalData.GiftArrayData,  
        giftLeadingSource: maxSourceGift || "",
        giftLeadingCreative: maxCreativeGift || "",
        GiftLeadingZipcode: maxIpPostalGift || "",
        giftAverageAmount: giftAverageAmount ? "$" + parseFloat(giftAverageAmount).toFixed(2) : 0,
        totalGifts: giftCount,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      resetStateData();
    }
  };

  const handleArray = (data, type) => {
    const result = [];

    data && Object.entries(data).forEach(([date, campaigns]) => {
      campaigns && Object.entries(campaigns).forEach(([name, values]) => {
        if (type === 'gift') {
          if (values && values.hasOwnProperty('gift')) {
            result.push({ date, name, count: values.gift });
          }
        } else {
          if (values && values.hasOwnProperty('count') && values.hasOwnProperty('amount')) {
            result.push({ date, name, count: values.count, amount: values.amount });
          }
        }
      });
    });

    return result;
  }

  const handleArrayforOverAll = (data) => {
    const result = [];

    data && Object.entries(data).forEach(([date, campaigns]) => {
      if (campaigns && campaigns.hasOwnProperty('count') && campaigns.hasOwnProperty('amount')) {
        result.push({ date, date, count: campaigns.count, amount: campaigns.amount });
      }
    });

    return result;
  }

  const handleHigherValueName = (data, key) => {

    let maxTotal = -Infinity;
    let maxName = '';

    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      let total = parseFloat(entry[key]);
      if (total > maxTotal) {
        maxTotal = total;
        if (maxTotal !== 0) { maxName = entry.name }
      }
    }

    return maxName;
  }

  function aggregateData(data) {
    const result = {};

    data.forEach(item => {
        if (result[item.name]) {
            result[item.name].count += item.count;
            result[item.name].amount += item.amount;
        } else {
            result[item.name] = {
                date: item.date, 
                name: item.name,
                count: item.count,
                amount: item.amount
            };
        }
    });

    return Object.values(result);
}


  const addLeadingZero = (value) => {
    if (value) {
      let tempValue = value.toLocaleString();
      let splitTempValue = String(tempValue).split(".");
      if (splitTempValue?.length > 1) {
        if (splitTempValue[1]?.length === 1) {
          tempValue += "0";
        }
      }
      return tempValue;
    } else {
      return value;
    }
  };

  // set Array for Revenue/Gift
  const setRevenueGiftArray = (value, revenueArr, giftArr, date) => {
    if (Object.keys(value)?.length) {
      // for Revenue
      for (const property in value) {
        if (property !== "Others") {
          revenueArr[date] = {
            ...revenueArr[date],
            ...{
              [property]: {
                count:
                  (revenueArr[date]?.[property]?.count || 0) +
                  value[property].count,
                amount:
                  (revenueArr[date]?.[property]?.amount || 0) +
                  value[property].totalamount,
              },
            },
          };
        }
      }
      // for Gift
      for (const property in value) {
        if (property !== "Others") {
          giftArr[date] = {
            ...giftArr[date],
            ...{
              [property]: {
                gift:
                  (giftArr[date]?.[property]?.gift || 0) +
                  value[property].count,
              },
            },
          };
        }
      }
    }
  };

  const daysDiffData = (
    revenue,
    sourceArrayData,
    creativeArrayData,
    ipPostalArrayData,
    sourceGiftArrayData,
    creativeGiftArrayData,
    ipPostalGiftArrayData
  ) => {
    let RevenueArrayData = [];
    let GiftArrayData = [];

    const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
    const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

    let dateDifference = moment(endDate).diff(moment(startDate), 'days');
    for (let i = 0; i <= dateDifference; i++) {
      // Get the current date
      let currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
      let convertedDate = moment(currentDate).format("YYYY-MM-DD");

      const revenueCheck = revenue && revenue?.find((e) => e.date === convertedDate);

      const sourceCheck = sourceArrayData && sourceArrayData?.find((e) => e.date === convertedDate);
      const creativeCheck = creativeArrayData && creativeArrayData.find((e) => e.date === convertedDate);
      const ipPostalCheck = ipPostalArrayData && ipPostalArrayData?.find((e) => e.date === convertedDate);

      const gift_sourceCheck = sourceGiftArrayData && sourceGiftArrayData?.find((e) => e.date === convertedDate);
      const gift_creativeCheck = creativeGiftArrayData && creativeGiftArrayData?.find((e) => e.date === convertedDate);
      const gift_ipPostalCheck = ipPostalGiftArrayData && ipPostalGiftArrayData?.find((e) => e.date === convertedDate);

      // add values in array
      RevenueArrayData.push({
        date: moment(currentDate).format('MM-DD-YYYY'),
        Revenue: revenueCheck && revenueCheck.amount || 0,
        Source: sourceCheck && sourceCheck.amount || 0,
        Creative: creativeCheck && creativeCheck.amount || 0,
        ipPostal: ipPostalCheck && ipPostalCheck.amount || 0,
      });
      GiftArrayData.push({
        date: moment(currentDate).format('MM-DD-YYYY'),
        Gifts: revenueCheck && revenueCheck.count || 0,
        Source: gift_sourceCheck && gift_sourceCheck.count || 0,
        Creative: gift_creativeCheck && gift_creativeCheck.count || 0,
        ipPostal: gift_ipPostalCheck && gift_ipPostalCheck.count || 0,
      });
    }
    return { RevenueArrayData, GiftArrayData };
  };

  //Date-Range change function.
  const handleDateChange = async (date, dateString) => {
    setDates({
      startDate: dayjs(dateString[0]),
      endDate: dayjs(dateString[1]),
    });
    if (dateString && dateString[0] && dateString[1]) {
      // getting client dashboard data
      setIsLoading(true);
      const tableData = {
        client: client, //clientAcronym
        startDate: dayjs(dateString[0]).format("YYYY-MM-DD"),
        endDate: dayjs(dateString[1]).format("YYYY-MM-DD"),
      };

      await fetchClientDashboardData(tableData);

    }
  };

  // for reset state value
  const resetStateData = () => {
    setCardsDetails({
      topChannel: { name: "-", totalAmount: 0, totalGift: 0 },
      donors: { totalDonors: 0, avgGift: 0 },
      revenue: {
        totalRevenue: 0,
        avgGift: 0,
        conversionRate: 0,
        totalOneTimeRevenue: 0,
        totalRecurringRevenue: 0,
      },
      viewsEngagedTotal: { views: pageViewsEngaged20Total.pageviews, engaged: pageViewsEngaged20Total.engaged_20 },
    });
    daysDiffData(0, 0, 0, 0, 0, 0, 0);
    setIsArrayEmpty(true);
    setIsGiftArrayEmpty(true);
    setRevenueTab({
      RechartArray: [],
      leadingCreative: "",
      leadingSource: "",
      revenueAverageAmount: 0,
      revenueTotalAmount: 0,
      RevenueLeadingZipcode: "",
    });
    setGiftTab({
      RechartGiftArray: [],
      giftLeadingSource: "",
      giftLeadingCreative: "",
      giftAverageAmount: 0,
      totalGifts: 0,
      GiftLeadingZipcode: "",
    });
    setCounter(counter + 1);
  };

  // Mode change 'live' and 'test' default live.
  const handleChangeMode = (event) => {
    let modeNew = event === true ? "live" : "test";
    setMode(modeNew);
    if (modeNew === "test") {
      resetStateData();
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setAllData();
    }
  };

  return (
    <div className="container-fluid">
      {isAuthenticated && role === "admin" && (
        <ClientChoose alertType="alert alert-success">
          <span>
            Welcome <strong>Team Member</strong>. Please choose a client to
            view/edit their data.
          </span>
        </ClientChoose>
      )}
      {client !== "NONE" && (
        <div className="dashboard">
          {/* Date Range Implimented for Card and Chart */}
          <div className="date-range card">
            <div className="card-content">
              <div className="row">
                <div className="col-md-9 col-sm-9">
                  <RangePicker
                    defaultValue={[dates.startDate, dates.endDate]}
                    onChange={handleDateChange}
                    allowClear={false}
                    size={"middle"}
                    bordered={"false"}
                    format={"MMMM D, YYYY"}
                    separator={
                      <i className="material-icons">arrow_right_alt</i>
                    }
                  />
                </div>
                <div className="col-md-3 col-sm-3 text-right date-range">
                  <p className="transaction-data-mode-switch">
                    <Switch
                      size={"default"}
                      checked={mode === "live" ? true : false}
                      onChange={handleChangeMode}
                    />{" "}
                    Live data
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Card Component */}
          <DashboardCard
            key={`dash-${counter}`}
            topChannel={cardsDetails.topChannel}
            donors={cardsDetails.donors}
            revenue={cardsDetails.revenue}
            pageViewTotal={cardsDetails.viewsEngagedTotal}
            isLoading={isLoading}
          />

          {/* Chart Component */}
          <DashboardChart
            key={`chart-${counter}`}
            revenueTab={revenueTab}
            isArrayEmpty={isArrayEmpty}
            giftTab={giftTab}
            isGiftArrayEmpty={isGiftArrayEmpty}
            endDate={dates.endDate}
            startDate={dates.startDate}
            isLoading={isLoading}
          />

        </div>
      )}
    </div>
  );
};

export default Home;