import React, { useState, useEffect } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { addLeadingZero } from "../../Helper/helper";
import Rechart from "../Rechart/Rechart";
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import dayjs from "dayjs";


const DashboardChart = ({
    revenueTab,
    isArrayEmpty,
    giftTab,
    isGiftArrayEmpty,
    performanceTab,
    isPerformanceArrayEmpty,
    startDate,
    endDate,
    isLoading,
    type = ""
}) => {

    let lineAttributes;
   
    if(type === "AbandonedGiftsLightbox"){
        lineAttributes = {
            "Revenue": [{
                "dataKey": "onetime",
                "stroke": "#006272"
            }, {
                "dataKey": "intialSubscription",
                "stroke": "#ff9e1b"
            }],
            "Gifts": [{
                "dataKey": "onetime",
                "stroke": "#006272"
            }, {
                "dataKey": "intialSubscription",
                "stroke": "#ff9e1b"
            }],
            "Performance": [{
                "dataKey": "onetime",
                "stroke": "#006272"
            }, {
                "dataKey": "intialSubscription",
                "stroke": "#ff9e1b"
            }],
        }
    }else{
        lineAttributes = {
           "Revenue": [{
               "dataKey": "Revenue",
               "stroke": "#006272"
           }, {
               "dataKey": "Source",
               "stroke": "#ff9e1b"
           }, {
               "dataKey": "Creative",
               "stroke": "#d50032"
           }, {
               "dataKey": "ipPostal",
               "stroke": "#0093b2"
           }],
           "Gifts": [{
               "dataKey": "Gifts",
               "stroke": "#006272"
           }, {
               "dataKey": "Source",
               "stroke": "#ff9e1b"
           }, {
               "dataKey": "Creative",
               "stroke": "#d50032"
           }, {
               "dataKey": "ipPostal",
               "stroke": "#0093b2"
           }],
           "Performance": [{
               "dataKey": "Performance",
               "stroke": "#006272"
           }, {
               "dataKey": "Gifts",
               "stroke": "#d50032"
           }, {
               "dataKey": "Source",
               "stroke": "#ff9e1b"
           }, {
               "dataKey": "Creative",
               "stroke": "#512d6d"
           }, {
               "dataKey": "ipPostal",
               "stroke": "#0093b2"
           }],
   
   
       };
    }

    const [activeTab, setActiveTab] = useState("Revenue");
    const [currentChartLines, setCurrentChartLines] = useState(lineAttributes.Revenue);
    const [data, setData] = useState([]);
    const [isDataEmpty, setIsDataEmpty] = useState(true);
    const tabData = {
        Revenue: revenueTab?.RechartArray,
        Gifts: giftTab?.RechartGiftArray,
        Performance: performanceTab?.RechartPerformanceArray
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentChartLines(lineAttributes[tab])
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setData(tabData[activeTab]);
            setIsDataEmpty(activeTab === "Revenue" ? isArrayEmpty : (activeTab === "Gifts" ? isGiftArrayEmpty : isPerformanceArrayEmpty));
        }, 5);

        return () => clearTimeout(timer);
    }, [activeTab, isLoading, revenueTab, giftTab, performanceTab]);


    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                    {payload.value}
                </text>
            </g>
        );
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="date">{`${payload[0].payload.date}`}</p>
                    {activeTab === 'Revenue' && (
                        type === "AbandonedGiftsLightbox" 
                        ?<div>
                        <p className="revenue">{`One time gift Revenue: $${addLeadingZero(payload[0].payload.onetime)}`}</p>
                        <p className="source">{`first time recurring revenue: $${addLeadingZero(payload[0].payload.intialSubscription)}`}</p>
                    </div>
                        :<div>
                            <p className="revenue">{`Overall Revenue: $${addLeadingZero(payload[0].payload.Revenue)}`}</p>
                            <p className="source">{`Leading Source: $${addLeadingZero(payload[0].payload.Source)}`}</p>
                            <p className="creative">{`Leading Creative: $${addLeadingZero(payload[0].payload.Creative)}`}</p>
                            <p className="zipcode">{`Leading ZipCode: $${addLeadingZero(payload[0].payload.ipPostal)}`}</p>
                        </div>
                    )}
                    {activeTab === 'Gifts' && (
                         type === "AbandonedGiftsLightbox" 
                         ?<div>
                         <p className="revenue">{`One time gifts: $${addLeadingZero(payload[0].payload.onetime)}`}</p>
                         <p className="source">{`first time recurring gifts: $${addLeadingZero(payload[0].payload.intialSubscription)}`}</p>
                     </div>
                         :<div>
                            <p className="revenue">{`Gifts: ${addLeadingZero(payload[0].payload.Gifts)}`}</p>
                            <p className="source">{`Leading Source: ${addLeadingZero(payload[0].payload.Source)}`}</p>
                            <p className="creative">{`Leading Creative: ${addLeadingZero(payload[0].payload.Creative)}`}</p>
                            <p className="zipcode">{`Leading ZipCode: ${addLeadingZero(payload[0].payload.ipPostal)}`}</p>
                        </div>
                    )}
                    {activeTab === 'Performance' && (
                        <div>
                            <p className="revenue">{`click through rate: $${addLeadingZero(payload[0].payload.onetime)}`}</p>
                            <p className="source">{`conversion rate: $${addLeadingZero(payload[0].payload.intialSubscription)}`}</p>
                        </div>
                    )}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="dashboard-chart row">
            <div className="col-sm-12 col-md-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color="linear-gradient(60deg,#512D6D,#441b5d)">
                        <i className="material-icons">timeline</i>
                    </div>
                    <div className="card-content">
                        <div className="row">
                            <div className="col-sm-9 col-md-8 col-md-offset-1 col-sm-offset-2 chart-header">
                                <h4 className="card-title">{activeTab} - {dayjs(startDate).format('MMMM Do, YYYY')} - {dayjs(endDate).format('MMMM Do, YYYY')}</h4>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="row">
                                <div className="col-md-12" style={{ marginTop: '50px' }}>
                                    <div className="skeleton skeleton-chart">
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="row">
                                    <div className="line-chart-container col-sm-12 col-md-9">
                                        {!isDataEmpty ?
                                            <ResponsiveContainer width="100%" height={400}>
                                                <LineChart data={data}>
                                                    <XAxis
                                                        allowDataOverflow
                                                        dataKey="date"
                                                        tick={<CustomizedAxisTick />}
                                                        domain={['dataMin', 'dataMax']}
                                                        interval={data.length < 31 ? 0 : Math.round(data.length / 30)}
                                                        height={70}
                                                        padding={{
                                                            right: 6,
                                                            left: 4,
                                                        }}
                                                    />
                                                    <YAxis
                                                        tickFormatter={(value) => activeTab === "Revenue" ? `$${addLeadingZero(value)}` : addLeadingZero(value)}
                                                        allowDataOverflow={true}
                                                        interval="preserveStartEnd"
                                                        width={100}
                                                        padding={{
                                                            top: 10,
                                                            bottom: 4
                                                        }}
                                                    />

                                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                                                    <Tooltip content={<CustomTooltip />} />
                                                    {currentChartLines.map((line, index) => (
                                                        <Line key={index} strokeWidth={2.5} type="monotone" dot={true} dataKey={line.dataKey} stroke={line.stroke} />
                                                    ))}
                                                </LineChart>
                                            </ResponsiveContainer>
                                            : <Rechart
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        }
                                    </div>
                                    <div className="tab-container col-sm-12 col-md-3">
                                        <div className="btn-group btn-group-sm">
                                            <button className={`btn tabs ${activeTab === "Revenue" && 'tabshover'}`} onClick={() => handleTabChange("Revenue")}>
                                                Revenue
                                            </button>
                                            <button className={`btn tabs ${activeTab === "Gifts" && 'tabshover'}`} onClick={() => handleTabChange("Gifts")}>
                                                Gifts
                                            </button>
                                            {performanceTab && 
                                            <button className={`btn tabs ${activeTab === "Performance" && 'tabshover'}`} onClick={() => handleTabChange("Performance")}>
                                                Performance
                                            </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="dashbaord-chart-containt col-sm-12 col-md-3">
                                        {activeTab === "Revenue" && (
                                             type === "AbandonedGiftsLightbox" 
                                             ? <div className="tabs-main-container">
                                             <div className="tabs-containt">
                                                 <div className="revenue" />
                                                 <div className="ml-5"><span className="icons-containt">One time gift revenue</span></div>
                                             </div>
                                             <div className="tabs-containt">
                                                 <div className="source" />
                                                 <div className="ml-5 leading-data"> <span className="icons-containt">First time recurring revenue</span></div>
                                             </div>
                                         </div>
                                             :<div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="revenue" />
                                                    <div className="ml-5"><span className="icons-containt">Overall Revenue </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="source" />
                                                    <div className="ml-5 leading-data"> <span className="icons-containt">Leading Source = <span className="leading-containt"> {(revenueTab.leadingSource && (revenueTab.leadingSource === "Others" ? 'N/A' : revenueTab.leadingSource)) || 'N/A'} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="creative" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading Creative = <span className="leading-containt"> {(revenueTab.leadingCreative && (revenueTab.leadingCreative === "Others" ? 'N/A' : revenueTab.leadingCreative)) || 'N/A'} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="zipcode" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading ZipCode = <span className="leading-containt"> {(revenueTab.RevenueLeadingZipcode && (revenueTab.RevenueLeadingZipcode === "Others" ? 'N/A' : revenueTab.RevenueLeadingZipcode)) || 'N/A'} </span> </span></div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "Gifts" && (
                                             type === "AbandonedGiftsLightbox" 
                                             ? <div className="tabs-main-container">
                                             <div className="tabs-containt">
                                                 <div className="revenue" />
                                                 <div className="ml-5"><span className="icons-containt">One time gifts</span></div>
                                             </div>
                                             <div className="tabs-containt">
                                                 <div className="source" />
                                                 <div className="ml-5 leading-data"> <span className="icons-containt"> First time recurring gifts </span></div>
                                             </div>
                                         </div>
                                             :<div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="revenue" />
                                                    <div className="ml-5"><span className="icons-containt">Gifts</span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="source" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading Source = <span className="leading-containt"> {(giftTab.giftLeadingSource && (giftTab.giftLeadingSource === "Others" ? 'N/A' : giftTab.giftLeadingSource)) || 'N/A'} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="creative" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading Creative = <span className="leading-containt"> {(giftTab.giftLeadingCreative && (giftTab.giftLeadingCreative === "Others" ? 'N/A' : giftTab.giftLeadingCreative)) || 'N/A'} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="zipcode" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading ZipCode = <span className="leading-containt"> {(giftTab.GiftLeadingZipcode && (giftTab.GiftLeadingZipcode === "Others" ? 'N/A' : giftTab.GiftLeadingZipcode)) || 'N/A'} </span> </span></div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "Performance" && (
                                            <div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="revenue" />
                                                    <div className="ml-5"><span className="icons-containt">Click through rate (Clicks/Impressions)</span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="creative" />
                                                    <div className="ml-5"><span className="icons-containt">Conversion rate (Donations/Clicks)</span></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-9 col-sm-12 footer-rechart">
                                        <div className="footer-rechart">
                                            {
                                                type === "AbandonedGiftsLightbox" ?
                                                ""
                                                :<>
                                                 <i className="material-icons">lightbulb_outline</i>
                                                {activeTab === "Revenue" && (<div>Your top earner, <strong>{(revenueTab.leadingCreative && (revenueTab.leadingCreative == "Others" ? 'N/A' : revenueTab.leadingCreative) || 'N/A')}</strong> creative, brought in <strong>{(revenueTab.revenueTotalAmount || 'N/A')}</strong> total with an average of <strong>{(revenueTab.revenueAverageAmount || 'N/A')}</strong> per gift.</div>)}
                                                {activeTab === "Gifts" && (<div>Your top gift magnet, <strong> {(giftTab.giftLeadingCreative && (giftTab.giftLeadingCreative == "Others" ? 'N/A' : giftTab.giftLeadingCreative) || 'N/A')}</strong> creative, saw <strong>{giftTab.giftLeadingCreative ? giftTab.totalGifts : 'N/A'}</strong> gifts (averaging <strong> {(giftTab.giftAverageAmount || 'N/A')} </strong>per gift).</div>)}
                                                {/* {activeTab === "Performance" && (<div>Your top Performance, <strong> 'N/A'</strong> creative, saw <strong>N/A</strong> gifts (averaging <strong>'N/A'</strong>per gift).</div>)} */}
                                                </>
                                            }
                                           
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardChart;
