import React from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useResizeColumns,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import ReactTablePageResizer from "./reactTablePageResizer";
import dayjs from "dayjs";
import { Tooltip } from "antd";

// Custom Global Filter
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <>
      <i
        className="fa fa-search search-icon"
        style={{ position: "relative", left: "20px", top: "-6px" }}
      />
      <input
        style={{
          marginBottom: "10px",
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "5px 5px 5px 25px",
        }}
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder="Search Table..."
      />
    </>
  );
};

const TextFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      style={{ width: "100%" }}
    />
  );
};

const ReactTableUI = ({
  data,
  columns,
  isLoading,
  showFilter = true,
  showPaginationOnBottom = false,
  defaultPageSize = 5,
  hiddenColumns = [],
  pageSizeOptions = true,
  paginationApiCall = false,
  nextPageEvent = "",
  previousPageEvent = "",
  nextArrow = true,
  previousArrow = false,
  urlSegment,
  btnActive,
  isDropDown,
  dates,
  handledownload,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page, // Instead of using 'rows', we now use 'page'
    prepareRow,
    state: { pageCount, pageSize, pageIndex, globalFilter }, // Include globalFilter in the state
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    setGlobalFilter, // Set globalFilter function
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
        hiddenColumns: hiddenColumns,
      }, // Set the initial page index and page size
    },
    useFilters,
    useGlobalFilter, // Use the global filter hook
    useResizeColumns,
    useSortBy,
    usePagination // Use the usePagination hook
  );

  const pageCountAfterFiltering = Math.ceil(rows.length / pageSize);

  const handlePageChange = (e) => {
    console.log("e.target.value: ", e);
    const newPageIndex = Number(e) - 1; // Subtract 1 to convert to zero-based index
    gotoPage(newPageIndex);
  };

  let bodyWidth = 0;

  if (headerGroups.length) {
    bodyWidth = headerGroups.map((headerGroup) => {
      bodyWidth = headerGroup.headers.reduce(
        (total, item) => total + (item.width || 0),
        0
      );
      return bodyWidth;
    });
  }

  return (
    <div id="pages-subPages-table">
      {!urlSegment && (
        <div
          className={`col-sm-offset-7 col-md-offset-8 col-lg-offset-9 row download event-icons ${
            urlSegment ? "event-icons" : "event-icons-download"
          }`}
          style={{ alignItems: "center" }}
        >
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <Tooltip title="Download">
            <span className="icon-title-icon" onClick={() => handledownload()}>
              <i className="material-icons" style={{width:'45px', paddingLeft:'10px'}}>download_2</i>
            </span>
          </Tooltip>
        </div>
      )}
      <div
        className="ReactTable -striped -highlight"
        style={{ boxShadow: "none", borderRadius: "0px" }}
      >
        <table
          {...getTableProps()}
          className="rt-table"
          role="grid"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <thead
            className="rt-thead -header"
            style={{
              minWidth: `${bodyWidth[1]}px`,
              overflow: "hidden",
              boxShadow: "none",
              minWidth: "300px",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="rt-tr"
                role="row"
                {...headerGroup.getHeaderGroupProps()}
                style={{ display: "flex" }}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`rt-th rt-resizable-header -cursor-pointer ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? "-sort-desc"
                          : "-sort-asc"
                        : ""
                    }`}
                    role="columnheader"
                    {...column.cellProps}
                    style={{
                      flexBasis: `${column.width}px`,
                      flexGrow: 1,
                      flexShrink: 0,
                      overflow: "hidden",
                      backgroundColor: "#f9f9f9",
                      color: "#000",
                      padding: "1rem",
                    }}
                  >
                    <div className="rt-resizable-header-content">
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? "isResizing" : ""
                        }`}
                      />
                      <span>{column.render("Header")}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {showFilter === true && (
            <thead
              className="rt-thead -filters"
              style={{ minWidth: `${bodyWidth[1]}px` }}
            >
              {headerGroups.map((headerGroup, index) => (
                <tr
                  key={index}
                  className="rt-tr"
                  role="row"
                  {...headerGroup.getHeaderGroupProps()}
                  style={{
                    display: "flex",
                    overflow: "hidden",
                    padding: !urlSegment && "1rem 0px",
                  }}
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      className={`rt-th`}
                      key={i}
                      style={{
                        flexBasis: `${column.width}px`,
                        flexGrow: 1,
                        flexShrink: 0,
                      }}
                    >
                      {column.canFilter && <TextFilter column={column} />}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody
            {...getTableBodyProps()}
            className="rt-tbody"
            style={{
              minWidth: `${bodyWidth[1]}px`,
              overflow: "hidden",
              minWidth: "300px",
            }}
          >
            {page.length > 0 &&
              page.map((row, index) => {
                prepareRow(row);
                let className = index % 2 === 0 ? "" : "";
                return (
                  <tr
                    key={index}
                    {...row.getRowProps()}
                    className={`rt-tr ${className}`}
                    style={{
                      display: "flex",
                      border: "1px solid rgba(0, 0, 0, 0.05)",
                      padding: !urlSegment && "0.6rem 0px",
                    }}
                  >
                    {row.cells.map((cell, ind) => (
                      <td
                        key={ind}
                        className="rt-td"
                        style={{
                          flexBasis: `${cell.column.width}px`,
                          flexGrow: 1,
                          flexShrink: 0,
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            {page.length <= 0 &&
              [1, 2, 3].map((row, index) => {
                let className = index % 2 === 0 ? "" : "";
                return (
                  <tr
                    key={index}
                    className={`rt-tr ${className}`}
                    style={{ display: "flex" }}
                  >
                    {headerGroups[0].headers.map((column, index) => (
                      <td
                        key={index}
                        className="rt-td"
                        style={{
                          flexBasis: `${column.width}px`,
                          flexGrow: 1,
                          flexShrink: 0,
                        }}
                      >
                        <span className="noColourText">NA</span>
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>

        {page.length <= 0 && (
          <div
            className="rt-noData"
            style={showFilter ? { margin: "3em" } : { margin: "2em" }}
          >
            No rows found
          </div>
        )}

        {isLoading && (
          <div className="-loading -active">
            <div className="-loading-inner">Loading...</div>
          </div>
        )}

        {showPaginationOnBottom && (
          <ReactTablePageResizer
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            handlePageChange={handlePageChange}
            pageSize={pageSize}
            setPageSize={setPageSize}
            nextPage={nextPage}
            defaultPageSize={pageSize}
            gotoPage={gotoPage}
            pageIndex={pageIndex}
            pageCount={pageCount}
            pageCountAfterFiltering={pageCountAfterFiltering}
            canNextPage={canNextPage}
            pageSizeOptions={pageSizeOptions}
            paginationApiCall={paginationApiCall}
            nextPageEvent={nextPageEvent}
            page={page}
            previousPageEvent={previousPageEvent}
            nextArrow={nextArrow}
            previousArrow={previousArrow}
            pageNumberCount={pageIndex + 1}
            tablePages={pageCountAfterFiltering}
            inputOnChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ReactTableUI;
