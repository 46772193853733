import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "antd";
import { useSelector } from "react-redux";
import { debounce } from 'lodash';

// Import NPM Libraries
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// for handling RangePicker Dates
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import GivingCentralReactTable from "../../Components/ReactTablePagination/GivingCentralReactTable";
import { LoadingOutlined } from "@ant-design/icons";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc)
dayjs.extend(timezone)

let source = axios.CancelToken.source();

const GivingCentralDynamicCard = ({
    title,
    formType,
    urlSegment,
    columns,
    dates,
    isDropDown,
    isSelect,
    setIsSelect,
    datePopup,
    btnActive,
}) => {

    const { client, IdentityApi } = useSelector(({ main }) => main);
    const { user: authUser } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);

    // Advance Search States
    const [ga_id, setGa_id] = useState("");
    const [person_id, setPerson_id] = useState("");
    const [session_id, setSession_id] = useState("");
    const [utm_campaign, setUtm_campaign] = useState("");
    const [utm_source, setUtm_source] = useState("");
    const [utm_medium, setUtm_medium] = useState("");
    const [campaign_name, setCampaign_name] = useState("");
    const [supporter_id, setSupporter_id] = useState("");
    const [email, setEmail] = useState("");

    const [filteredData, setFilteredData] = useState([]);
    const [pageNumberCount, setPageNumberCount] = useState(1);
    const [increment, setIncrement] = useState(0);
    const [isGetCount, setIsGetCount] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [active_data, setActive_data] = useState([]);
    const [tablePages, setTablePages] = useState(0);
    const [dataType, setDataType] = useState('search');
    const [isModalOpen, setIsModalOpen] = useState(false);


    // Initial Check
    const [initialCall, setInitialCall] = useState(true);
    const [resetFilter, setResetFilter] = useState(false);

    useEffect(() => {
        search("date");
    }, [client, isDropDown]);

    useEffect(() => {
        if (isSelect && !datePopup && dates.startDate && dates.endDate) {
            search("date");
            setIsSelect(false);
        }
    }, [datePopup]);

    const show_error = (error, message) => {
        Modal.error({ title: "Error: " + error, content: message });
    };

    //Execute search
    const search = (type) => {

        setIsLoading(true);
        setDataType("search");
        setPageNumberCount(1);
        setIncrement(0);
        setResetFilter(true);
        setFilteredData([]);

        setTimeout(() => {
            let url = `${IdentityApi}query/get_event`;

            let params;

            if (type === "date") {

                params = {
                    type: formType,
                    eventData: "search",
                    date_start: dayjs(dates?.startDate).format('YYYY-MM-DD'),
                    date_end: dayjs(dates?.endDate).format('YYYY-MM-DD'),
                    client: client
                };

            } else if (type === "advanced") {

                params = {
                    type: formType,
                    eventData: "search",
                    date_start: dayjs(dates?.startDate).format('YYYY-MM-DD'),
                    date_end: dayjs(dates?.endDate).format('YYYY-MM-DD'),
                    client: client
                };

                if (ga_id !== "") { params.ga_id = ga_id; }
                if (person_id !== "") { params.person_id = person_id; }
                if (session_id !== "") { params.session_id = session_id; }
                if (utm_campaign !== "") { params.utm_campaign = utm_campaign; }
                if (utm_source !== "") { params.utm_source = utm_source; }
                if (utm_medium !== "") { params.utm_medium = utm_medium; }
                if (campaign_name !== "") { params.campaign_name = campaign_name; }
                if (supporter_id !== "") { params.supporter_id = supporter_id; }
                if (email !== "") { params.email = email; }

            }

            setSearchParams(params);
            setInitialCall(false);

            axios.get(url, { params })
                .then((fromResolve) => {
                    if (fromResolve.data.status === "success") {
                        setActive_data(fromResolve.data.data.data);
                        setTablePages(
                            Math.ceil(fromResolve.data.data.counts.event_count / 10)
                        );
                        setIsLoading(false);

                        if (type !== "abandoned_cart") {
                            url = `${IdentityApi}query/search_count`;
                            return axios.get(url, { params });
                        } else {
                            return true;
                        }
                    } else {
                        window.swal({ title: "Error", text: 'No data found.', icon: "error", button: "OK" });
                        setFilteredData([]);
                        // throw fromResolve.data.message;
                    }
                })
                .then((fromResolve) => {
                    if (
                        type !== "abandoned_cart" &&
                        fromResolve?.data.status === "success"
                    ) {
                        setIsLoading(false);
                    } else {
                        setFilteredData([]);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setFilteredData([]);
                    show_error(
                        "Searching Data",
                        error + ", please check logs for further details"
                    );
                });
        }, 500);
    };

    // Fetch data through paggination.
    const fetchData = async (page, filter_data = [], from) => {

        if (filter_data[0]?.value) {
            searchParams.search = filter_data[0]?.value;
        } else {
            filter_data = [];
            from = "filterChange";
            searchParams.search = "";
        }

        let formData = {
            tableType: formType,
            eventData: dataType,
            pageSize: 10,
            pageNumber: page,
            searchParams: searchParams,
            filtered: from === "filterChange" ? filter_data : filteredData,
            sorted: [],
            isGetCount: filter_data[0]?.value ? true : isGetCount
        }

        if (!initialCall) {
            setTimeout(() => {
                setIsLoading(true);
                debouncedFetchData(formData);
            }, 200);
        }

    }

    const fetchDataAjaxCall = async (formData) => {

        if (source) {
            source.cancel("Previous request cancelled");
        }
        source = axios.CancelToken.source();

        setIsLoading(true);

        try {

            let url = `${IdentityApi}query/getDataByPagination?client=${client}`;

            const response = await axios.post(url, formData, { cancelToken: source.token });
            const { status, data } = response;

            if (status === 200) {
                try {
                    if (data.status === 'success') {
                        setActive_data(data.data.data);

                        if (typeof data.data.event_count === "number") {
                            setTablePages(Math.ceil(data.data.event_count / 10));
                        }
                        setIsLoading(false);

                    } else {
                        window.swal({ title: "Error", text: 'We could not find your result.', icon: "error", button: "OK" });
                        setIsLoading(false);
                    }

                } catch (error) {

                    window.swal({ title: "Error", text: 'We could not find your result.', icon: "error", button: "OK", });
                    setIsLoading(false);

                }

            } else {
                window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
                setIsLoading(false);

            }

        } catch (error) {
            setIsLoading(false);
        }


    }

    const debouncedFetchData = debounce(fetchDataAjaxCall, 200);

    const inputOnChange = (count) => {
        if (count > 0 && count <= tablePages) {
            setPageNumberCount(count);
            setIncrement(count - 1);
            fetchData(count - 1, filteredData[0]?.value ? filteredData : [], "inputChange");
        }
    }

    const filterOnChange = (count, data) => {

        if (resetFilter && data.length > 0) {
            setResetFilter(false);
        }
        setFilteredData(data);
        setIsGetCount(true);
        setPageNumberCount(1);
        setIncrement(0);
        fetchData(count - 1, data, "filterChange");
    }

    const nextPageEvent = (page) => {
        if (tablePages > increment) {
            setIncrement(increment + page);
            setPageNumberCount(prev => prev + 1);
            fetchData(increment + page, [], "next");
        }
    }

    const previousPageEvent = (page) => {
        if (increment > 0) {
            setIncrement(increment - page);
            setPageNumberCount(prev => prev - 1);
            fetchData(increment - page, [], "previous");
        }
    }

    const handledownload = () => {
        setIsModalOpen(true);
    }

    const handleOk = async () => {
        setIsLoading(true);
        try {
            const formData = {
                client: client,
                startDate: dayjs(dates?.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(dates?.endDate).format('YYYY-MM-DD'),
                email: authUser.email,
                userName: authUser.name,
                title: title,
                table: formType,
                origin: window.origin
            };

            const results = await axios.post(`${IdentityApi}query/downloadData`, formData);
            console.log("results ::", results);

            if (results.status === 200 && results.data.status === 'success') {
                window.swal({ title: "Success", text: 'Email send successfully.', icon: "success", button: "OK" });
                setIsLoading(false);
                setIsModalOpen(false);
            } else {
                window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
                setIsLoading(false);
                setIsModalOpen(false);
            }
        } catch (error) {
            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
            setIsLoading(false);
            setIsModalOpen(false);
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    return (
        <div className={urlSegment ? "giving-central-card" : "giving-central-card"} style={{ paddingTop: '20px' }}>
            <div className="row">
                {urlSegment ?
                    <div className="col-sm-12 col-md-12">
                        <div className="card-title">{title}</div>
                    </div>
                    : ""
                }
            </div>
            <div className="card" style={{ padding: '0px' }}>
                <div className="card-content" style={{ padding: '0px' }}>
                    <GivingCentralReactTable
                        data={active_data}
                        columns={columns}
                        isLoading={isLoading}
                        urlSegment={urlSegment}
                        setIsLoading={setIsLoading}
                        fetchData={fetchData}
                        inputOnChange={inputOnChange}
                        downloadData={handledownload}
                        filterOnChange={filterOnChange}
                        filteredData={filteredData}
                        nextPageEvent={nextPageEvent}
                        previousPageEvent={previousPageEvent}
                        setPageNumberCount={setPageNumberCount}
                        defaultPageSize={10}
                        tablePages={tablePages}
                        pageNumberCount={pageNumberCount}
                        showPaginationOnBottom={true}
                        showFilter={false}
                        resetFilter={resetFilter}
                        btnActive={btnActive}
                        isDropDown={isDropDown}
                        dates={dates}
                    />
                </div>
            </div>
            <Modal
                open={isModalOpen}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <div className="download-modal">
                    <p>Downloading</p>
                    <strong>{title}</strong>
                    <span>You are about to download the data for {title}. Download link will be sent to the following Email.</span>
                    <Row className="profile">
                        <Col>
                            <img src={authUser.picture} width={50} height={50} />
                        </Col>
                        <Col style={{ padding: "0px 10px" }}>
                            <span>{authUser.name}</span><br />
                            <span>{authUser.email}</span>
                        </Col>
                    </Row>

                    <Button className="send-download-link" key="submit" type="primary" onClick={handleOk}>
                        Send Download Link
                        {isLoading ? <LoadingOutlined /> : <i className="material-icons">send</i>}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default GivingCentralDynamicCard;
