import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Import Sidebar Variations
import SidebarSuper from './sidebar-super.js';

import axios from 'axios';

import { updateClientMetaData, ChangeClientType, UpdateMultiChannelReportingStage, UpdateMultiChannelReportingLive } from "../redux/Main/action";

const Sidebar = ({ type }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const appDomain = useSelector(({ main }) => main.appDomain);
    const imageDomain = useSelector(({ main }) => main.imageDomain);
    const client = useSelector(({ main }) => main.client);
    const api = useSelector(({ main }) => main.api);

    const getClient = useCallback(() => {

        let clientConfig = `${api}getClientConfig?client=${client}`;

        axios.get(clientConfig).then((response) => {
            const { data: config } = response.data;
            let url = window.location.href;

            dispatch(ChangeClientType(config.clientType));
            dispatch(updateClientMetaData(config));

            if (url.indexOf('/home') !== -1 && config.clientType === 'Portal') {
                navigate('/dashboard/donorPortal/v2/transactionUpload');
            }

            //Multi-channel reporting setting for sidebar menu
            //Update the multi-channel reporting for test mode
            if (config.multichannel && config.multichannel.multiChannelReportingStage === "true") {
                config.multichannel.multiChannelReportingStage = true;
            }

            dispatch(UpdateMultiChannelReportingStage(config.multichannel && config.multichannel.multiChannelReportingStage));

            //Update the multi-channel reporting for live mode
            if (config.multichannel &&  config.multichannel.multiChannelReportingLive === "true") {
                config.multichannel.multiChannelReportingLive = true;
            }

            dispatch(UpdateMultiChannelReportingLive(config.multichannel && config.multichannel.multiChannelReportingLive));

        }).catch((error) => {
            console.log("Error >>> ", error);
        })
    }, [api, client, dispatch, navigate]);

    useEffect(() => {
        if (client !== "NONE") {
            getClient();
        }
    }, [client, getClient])

    return (
        <div className="sidebar" data-active-color="red" data-background-color="white" data-image={appDomain + "/dashboard/img/sidebar-1.jpg"}>
            <div className="logo">
                <a href="/admin/identity-dashboard" className="simple-text">
                Identity
                </a>
            </div>
            <div className="logo logo-mini">
                <a href="/admin/identity-dashboard" className="simple-text">
                Identity
                </a>
            </div>
            {/* Sidebar Wrapper */}
            <div className="sidebar-wrapper">
                {/* User */}
                <div className="user">
                    <div className="photo">
                        <img src={imageDomain + "/assets/branding/logo-rkd.svg"} alt="Logo" />
                    </div>
                </div>
                <div>
                    <SidebarSuper />
                </div>
            </div>
        </div>
    )
}

export default Sidebar