import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { useTable, usePagination, useFilters, useResizeColumns, useSortBy, useFlexLayout } from 'react-table';

// JSX Components
import PageResizer from './PageResizer';

let tempObj = [];

const TextFilter = ({ column, filterOnChange,resetFilter }) => {
    const [filterNew, setFilterNew]  = useState('');
    
    useEffect(() => {
        if (resetFilter) {
            tempObj = [];
            setFilterNew('');
        }
    }, [resetFilter]);

    const debounceFilterOnChange = debounce(filterOnChange, 200);
    
    const onChange = (value, id) => {
        setFilterNew(value);

        const currentIndex = tempObj.findIndex((item) => {
            return item.id === id;
        });

        if (currentIndex !== -1) {
            const updatedArray = value !== ""
                ? tempObj.map((item, i) => (currentIndex === i ? { ...item, value } : item))
                : tempObj.filter(item => item.id !== id);

            tempObj.splice(0, tempObj.length, ...updatedArray);
            debounceFilterOnChange(1, tempObj)
        } else {
            tempObj.push({ "id": id, "value": value });
            debounceFilterOnChange(1, tempObj)
        }

    };


    return (
        <input
            value={filterNew}
            onChange={(event) => { onChange(event.target.value, column.id) }}
            style={{ width: '100%' }}
        />
    );
}

const ReactTable = ({
    data,
    columns,
    isLoading,
    setIsLoading,
    inputOnChange,
    filterOnChange,
    filteredData,
    hiddenColumns = [],
    nextPageEvent = "",
    previousPageEvent = "",
    setPageNumberCount,
    defaultPageSize = 5,
    tablePages = 0,
    pageNumberCount = 0,
    showPaginationOnBottom = false,
    showFilter = true,
    resetFilter = false
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, 
        prepareRow,
        state: { pageSize },
        previousPage,
        nextPage,
        setPageSize,
        gotoPage
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: defaultPageSize, hiddenColumns: hiddenColumns }, 
        },
        useFilters,
        useResizeColumns,
        useSortBy,
        usePagination, 
        useFlexLayout
    );

    let bodyWidth = 0;

    if (headerGroups.length) {
        bodyWidth = headerGroups.map((headerGroup) => {
            bodyWidth = headerGroup.headers.reduce((total, item) => total + (item.width || 0), 0);
            return bodyWidth;
        })
    }

    return (
        <div id='pages-subPages-table'>
            <div className='ReactTable -striped -highlight'>
                <table {...getTableProps()} className='rt-table' role='grid' style={{ display: 'flex', flexDirection: 'column' }}>
                    <thead className='rt-thead -header' style={{ minWidth: `${bodyWidth[1]}px`, overflow: "hidden" }}>
                        {headerGroups.map((headerGroup) => (
                            <tr className='rt-tr' role='row' {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex' }}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        key={index}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={`rt-th rt-resizable-header -cursor-pointer ${column.isSorted ? (column.isSortedDesc ? '-sort-desc' : '-sort-asc') : ''}`}
                                        role='columnheader'
                                        {...column.cellProps}
                                    >
                                        <div className='rt-resizable-header-content'>
                                            <div
                                                {...column.getResizerProps()}
                                                className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                            />
                                            <span>{column.render('Header')}</span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {showFilter === true && <thead className='rt-thead -filters' style={{ minWidth: `${bodyWidth[1]}px`, overflow: "hidden" }}>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} className='rt-tr' role='row' {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex', overflow: "hidden" }}>
                                {headerGroup.headers.map((column, i) => (
                                    <th className={`rt-th`} key={i} style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0 }}>
                                        {column.canFilter &&
                                            <TextFilter
                                                column={column}
                                                filterOnChange={filterOnChange}
                                                setIsLoading={setIsLoading}
                                                filtered={filteredData}
                                                resetFilter={resetFilter}
                                            />}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>}
                    <tbody {...getTableBodyProps()} className='rt-tbody' style={{ minWidth: `${bodyWidth[1]}px`, overflow: "hidden" }}>
                        {page.length > 0 && page.map((row, index) => {
                            prepareRow(row);
                            let className = index % 2 === 0 ? '-odd' : '';
                            return (
                                <tr key={index} {...row.getRowProps()} className={`rt-tr ${className}`} style={{ display: 'flex' }}>
                                    {row.cells.map((cell, ind) => (
                                        <td key={ind} className='rt-td' style={{ flexBasis: `${cell.column.width}px`, flexGrow: 1, flexShrink: 0 }}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                        {page.length <= 0 && [1, 2, 3].map((row, index) => {
                            let className = index % 2 === 0 ? '-odd' : '';
                            return (
                                <tr key={index} className={`rt-tr ${className}`} style={{ display: 'flex' }}>
                                    {headerGroups[0].headers.map((column, index) => (
                                        <td key={index} className='rt-td' style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0 }}>
                                            <span className='noColourText'>NA</span>
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {page.length <= 0 && <div className="rt-noData" style={showFilter ? { margin: "3em" } : { margin: "2em" }} >No rows found</div>}

                {isLoading && <div className="-loading -active"><div className="-loading-inner">Loading...</div></div>}

                {showPaginationOnBottom && (
                    <PageResizer
                        previousPage={previousPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        nextPage={nextPage}
                        defaultPageSize={pageSize}
                        gotoPage={gotoPage}
                        pageSizeOptions={false}
                        nextPageEvent={nextPageEvent}
                        previousPageEvent={previousPageEvent}
                        tablePages={tablePages}
                        pageNumberCount={pageNumberCount}
                        setPageNumberCount={setPageNumberCount}
                        inputOnChange={inputOnChange}
                    />
                )
                }
            </div>
        </div>
    )
}

export default ReactTable;