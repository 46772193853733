import React, { useState } from "react";
import { DatePicker } from 'antd';
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import dayjs from 'dayjs';

//Plugins
import {
  Cell,
  Pie,
  PieChart,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const { RangePicker } = DatePicker;

const NewVsRepeatVisits = ({ newVisits, repeatingVisits, isLoading, dates, setDates, disableStartDate }) => {

  const data = [
    {
      color: '#54BBDB',
      name: "New Visitors",
      percentage: Math.floor((newVisits.length * 100) / (newVisits.length + repeatingVisits.length)),
      count: newVisits.length,
    }, {
      color: '#00617F',
      name: "Repeat Visitors",
      percentage: Math.floor((repeatingVisits.length * 100) / (newVisits.length + repeatingVisits.length)),
      count: repeatingVisits.length,
    }
  ]

  const CustomTooltip = ({ active, payload }) => {
    if (!(active && payload && payload.length)) return null;
    return (
      <div className="dark-tooltip">
        <p className="title">{payload[0].payload.name}</p>
        <p className="text">{payload[0].payload.count} ({payload[0].payload.percentage}%)</p>
      </div>
    )
  }

  return (
    <div>
      <div className="card">
        <div className="card-header card-header-icon" data-background-color='red'>
          <i className="material-icons">person</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Unique Visitors</h4>
        </div>
        <div className="card-content">
          <RangePicker
            value={[dates.startDate, dates.endDate]}
            onChange={(date, dateString) => setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) })}
            allowClear={false}
            size={"middle"}
            bordered={"false"}
            format={"YYYY-MM-DD"}
            disabledDate={(current) => current && current < dayjs(disableStartDate)}
            separator={<i className="material-icons">arrow_right_alt</i>}
          />
        </div>
        <div className="card-content">
          {!isLoading ?
            <div className="row">
              <div className={`col-md-9 line-chart-container`}>
                <div className='col-md-12'>
                  <div className='charts'>
                    <ResponsiveContainer width="100%" height={500}>

                      {/* <PieChart width={800} height={800}> */}
                      <PieChart>
                        <Pie
                          data={data}
                          dataKey="count"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={200}
                          innerRadius={150}
                          paddingAngle={2}
                          fill="#0093B2"
                        >
                          {data.map((entry, index) => (<Cell key={`cell-${index}`} fill={entry.color} />))}
                        </Pie>
                        <Legend height={36} />
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>

                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-md-12">
                <div className="skeleton skeleton-chart">
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                  <SkeletonLine style={{ height: '20%', width: '4%' }} />
                  <SkeletonLine style={{ height: '100%', width: '4%' }} />
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                  <SkeletonLine style={{ height: '20%', width: '4%' }} />
                  <SkeletonLine style={{ height: '100%', width: '4%' }} />
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default NewVsRepeatVisits;
