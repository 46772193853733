import React, { useState } from "react";
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import LineCharts from "./LineCharts";
import BarCharts from "./BarCharts";
import PieCharts from "./PieCharts";
import AreaCharts from "./AreaCharts";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Col, Modal, Row, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { useSelector } from "react-redux";
import { replaceArrayKeys } from "../../Helper/helper";

const GiveEventChart = ({
    eventType,
    data = [],
    isValid = false,
    isLoading = false,
    urlSegment,
    btnActive,
    isDropDown,
    dates,
    setisLoading
}) => {
    const { user: authUser } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Data, setData] = useState([]);
    const { client, IdentityApi } = useSelector(({ main }) => main);
    const [title, setTitle] = useState("");

    const handledownload = (data, eventType) => {
        setIsModalOpen(true);
        let finalArr = [];
        if(eventType === "average_donation_amount"){
            finalArr = replaceArrayKeys(data, [{ old: "average1", new: "OneTime Average Donation" }, { old: "average2", new: "Recurring Average Donation" }]);
            setTitle("Average Donation Amount");
        }
        if(eventType === "channels_performance"){
            finalArr = replaceArrayKeys(data, [{ old: "count", new: "Count" }, { old: "name", new: "Channel Name" }, { old: "totalamount", new: "Donation Amount" }]);
            setTitle("Donation Channel Performance");
        }
        if(eventType === "onetime_vs_recurring"){
            finalArr = replaceArrayKeys(data, [{ old: "oneTimeTotalDonors", new: "OneTime Donors" }, { old: "recurringTotalDonors", new: "New Subscriptions" }]);
            setTitle("One Time vs New Subscriptions");
        }
        if(eventType === "donation_source"){
            finalArr = replaceArrayKeys(data, [{ old: "count", new: "Count" }, { old: "name", new: "Source Name" }, { old: "totalamount", new: "Donation Amount" }]);
            setTitle("Donation Distribution by Source");
        }
        if(eventType === "campaign_performance"){
            finalArr = replaceArrayKeys(data, [{ old: "count", new: "Count" }, { old: "name", new: "Campaign Name" }, { old: "totalamount", new: "Donation Amount" }]);
            setTitle("Donation Campaign Performance");
        }
        if(eventType === "frequency_distribution"){
            finalArr = replaceArrayKeys(data, [{ old: "count", new: "Count" }, { old: "percentage", new: "Percentage" }, { old: "totalamount", new: "Total Amount" }]);
            setTitle("Donation Frequency Distribution");
        }
        if(eventType === "total_donation"){
            finalArr = replaceArrayKeys(data, [{ old: "totalDonationAmt", new: "Total Donation Amount" }]);
            setTitle("Total Donations Over Time");
        }
        setData(finalArr);
    }

    const handleOk = async () => {
        setisLoading(true);
        try {
            const formData = {
                client: client,
                startDate: dayjs(dates?.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(dates?.endDate).format('YYYY-MM-DD'),
                email: authUser.email,
                userName: authUser.name,
                title: title,
                data: Data,
                origin: window.origin
            };
            const results = await axios.post(`${IdentityApi}query/downloadData`, formData);

            if (results.status === 200 && results.data.status === 'success') {
                window.swal({ title: "Success", text: 'Email send successfully.', icon: "success", button: "OK" });
                setisLoading(false);
                setIsModalOpen(false);
            } else {
                window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
                setisLoading(false);
                setIsModalOpen(false);
            }
        } catch (error) {
            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
            setisLoading(false);
            setIsModalOpen(false);
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    return (
        <div className="event-chart">
            <div className={urlSegment ? "event" : "eventChart"} style={{ paddingLeft: '0px', paddingTop: '20px' }}>
                <div className="row">
                    {urlSegment ? <div className="col-sm-12 col-md-12">
                        {eventType === "average_donation_amount" &&
                            <div className="card-title">Average Donation Amount</div>
                        }
                        {eventType === "channels_performance" &&
                            <div className="card-title">Donation Channel Performance</div>
                        }
                        {eventType === "onetime_vs_recurring" &&
                            <div className="card-title">One Time vs New Subscriptions</div>
                        }
                        {eventType === "donation_source" &&
                            <div className="card-title">Donation Distribution by Source </div>
                        }
                        {eventType === "campaign_performance" &&
                            <div className="card-title">Donation Campaign Performance</div>
                        }
                        {eventType === "frequency_distribution" &&
                            <div className="card-title">Donation Frequency Distribution</div>
                        }
                        {eventType === "total_donation" &&
                            <div className="card-title">Total Donations Over Time</div>
                        }
                    </div> : ""}
                </div>

                <div className="card" style={{ maxHeight: urlSegment ? '350px' : '500px' }}>
                    <div className="card-content">
                        {!isLoading ?
                            <div className="row">
                                <div className={`col-md-12 line-chart-container`} style={{ padding: '0px' }}>
                                    <div className='col-md-12'>
                                        <div className={`row event-icons ${urlSegment ? 'event-icons' : 'event-icons-download'}`}>
                                            <Tooltip title="Maximize">
                                                {urlSegment && <a href={`/admin/give-central/maximized/${urlSegment}/${btnActive}/${isDropDown}/${dayjs(dates?.startDate).format('YYYY-MM-DD')}_${dayjs(dates?.endDate).format('YYYY-MM-DD')}`} target='_blank' style={{ color: "#666666" }}><span className="icon-title-icon"><i className="material-icons">zoom_out_map</i></span></a>}
                                            </Tooltip>
                                            <Tooltip title="Download">
                                                <span className="icon-title-icon" onClick={() => handledownload(data, eventType)}><i className="material-icons">download_2</i></span>
                                            </Tooltip>
                                        </div>
                                        <div className='charts'>
                                            {eventType === "average_donation_amount" &&
                                                <LineCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />}
                                            {eventType === "channels_performance" &&
                                                <BarCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />}
                                            {eventType === "onetime_vs_recurring" &&
                                                <AreaCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />}
                                            {eventType === "donation_source" &&
                                                <PieCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />}
                                            {eventType === "campaign_performance" &&
                                                <BarCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />}
                                            {eventType === "frequency_distribution" &&
                                                <BarCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />}
                                            {eventType === "total_donation" &&
                                                <LineCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />}
                                        </div>
                                        {!isValid && (
                                            <div className={`${urlSegment && eventType === "donation_source" ? 'rechart-text-chart' : 'rechart-text'} ${!urlSegment && 'rechart-text-chart-full'}`}>No Data Found</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="skeleton skeleton-chart">
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Modal
                    open={isModalOpen}
                    // onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[]}
                >
                    <div className="download-modal">
                        <p>Downloading</p>
                        <strong>{title}</strong>
                        <span>You are about to download the data for {title}. Download link will be sent to the following Email.</span>
                        <Row className="profile">
                            <Col>
                                <img src={authUser.picture} width={50} height={50} />
                            </Col>
                            <Col style={{ padding: "0px 10px" }}>
                                <span>{authUser.name}</span><br />
                                <span>{authUser.email}</span>
                            </Col>
                        </Row>
                        <Button className="send-download-link" key="submit" type="primary" onClick={handleOk}>
                            Send Download Link
                            {isLoading ? <LoadingOutlined /> : <i className="material-icons">send</i>}
                        </Button>
                    </div>
                </Modal>
            </div>
        </div>)
}

export default GiveEventChart;
