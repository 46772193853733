import React from "react";
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import PieCharts from "./PieCharts";
import StateTable from "../../Views/TrafficTrends/StateTable";
import TrendLineCharts from "./TrendLineCharts";

const TrafficCards = ({
    headerIcon,
    title,
    trafficType,
    data = [],
    filter_dates,
    isValid = false,
    isLoading = false,
}) => {
    
    return (
        <div className={`row traffic-trends-container ${trafficType === "device_location" ? "state-chart" : ""}`}>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color='red'>
                        {headerIcon}
                    </div>
                    <div className="card-content">
                        <div className="row">
                            <div className="col-sm-12 col-md-8">
                                <h4 className="card-title">{title}</h4>
                            </div>
                        </div>
                        {!isLoading ?
                            <div className="row">
                                <div className={`col-md-12 line-chart-container`}>
                                    <div className='col-md-12'>
                                        <div className='charts'>
                                            {trafficType === "page_depth" &&
                                                <TrendLineCharts
                                                    data={data}
                                                    type={trafficType}
                                                    ApiData={data}
                                                    isValid={isValid}
                                                />
                                            }
                                            {trafficType === "engagement_rate" &&
                                                <TrendLineCharts
                                                    data={data}
                                                    type={trafficType}
                                                    ApiData={data}
                                                    isValid={isValid}
                                                />
                                            }
                                            {trafficType === "device_category" &&
                                                <PieCharts
                                                    data={data}
                                                    isValid={isValid}
                                                    filter_dates={filter_dates}
                                                />
                                            }
                                            {trafficType === "device_location" &&
                                                <StateTable
                                                    data={data}
                                                    isValid={isValid}
                                                    trafficType={trafficType}
                                                    isLoading={isLoading}
                                                />
                                            }
                                        </div>
                                        {!isValid && (
                                            <div className={`${trafficType === "device_category" ? 'rechart-text-chart' : 'rechart-text'}`}>No Data Found</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <EmptySkeleton />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>)
}

const EmptySkeleton = () => {
    return (
        <div className="col-md-12">
            <div className="skeleton skeleton-chart">
                <SkeletonLine style={{ height: '50%', width: '4%' }} />
                <SkeletonLine style={{ height: '45%', width: '4%' }} />
                <SkeletonLine style={{ height: '20%', width: '4%' }} />
                <SkeletonLine style={{ height: '100%', width: '4%' }} />
                <SkeletonLine style={{ height: '50%', width: '4%' }} />
                <SkeletonLine style={{ height: '45%', width: '4%' }} />
                <SkeletonLine style={{ height: '20%', width: '4%' }} />
                <SkeletonLine style={{ height: '100%', width: '4%' }} />
                <SkeletonLine style={{ height: '50%', width: '4%' }} />
                <SkeletonLine style={{ height: '45%', width: '4%' }} />
                <SkeletonLine style={{ height: '20%', width: '4%' }} />
                <SkeletonLine style={{ height: '100%', width: '4%' }} />
                <SkeletonLine style={{ height: '50%', width: '4%' }} />
                <SkeletonLine style={{ height: '45%', width: '4%' }} />
                <SkeletonLine style={{ height: '20%', width: '4%' }} />
            </div>
        </div>
    )
}

export default TrafficCards;